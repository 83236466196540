import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CodedResponse} from "@app/model/coded.response";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {SendMessage} from "@app/secured/send-message/send-message.model";

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public sendMessage(customerId: number, data: SendMessage): Observable<CodedResponse> {
    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/message/${customerId}`, data, this.security.getHttpOptions());
  }
}
