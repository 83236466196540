import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {AddressResponse} from "@app/shared/model/address.response";
import {CityResponse} from "@app/shared/model/city.response";
import {DataResponse} from "@app/shared/model/data.response";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findAddressess(param: string): Observable<Array<AddressResponse>> {
    return this.httpClient.get<DataResponse>(`${environment.baseUrl}/streets/selection?param=${param}`, this.security.getHttpOptions())
      .pipe(
        map((response: DataResponse) => {
          return response.data;
        }));
  }

  public findCityByPostalCode(param: string, country: string): Observable<Array<CityResponse>> {
    return this.httpClient.get<DataResponse>(`${environment.baseUrl}/city-by-postalcode/selection?param=${param}&country=${country}`, this.security.getHttpOptions())
      .pipe(
        map((response: DataResponse) => {
          return response.data;
        }));
  }

  public findCityByName(param: string, country: string): Observable<Array<CityResponse>> {
    return this.httpClient.get<DataResponse>(`${environment.baseUrl}/city-by-name/selection?param=${param}&country=${country}`, this.security.getHttpOptions())
      .pipe(
        map((response: DataResponse) => {
          return response.data;
        }));
  }
}
