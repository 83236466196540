import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';

export class CustomValidators {


  public static email(control: AbstractControl): ValidationErrors | null {

    if (Validators.required(control)) {
      return null;
    }

    let v: string = control.value;
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ? null : {'email': true};
  }

  public static nonStrictPhone(control: AbstractControl): ValidationErrors | null {

    if (Validators.required(control)) {
      return null;
    }

    let v: string = control.value;
    return /^(?:\+?\d{2,3})[\s\/.-]?\d{3}|\d{2}[\s\/.-]?\d{3}|\d{2}[\s\/.-]?\d{3}|\d{2}[\s\/.-]?\d{3}|\d{2}[\s\/.-]?$/.test(v) ? null : {'phone': true};
  }

  public static phone(control: AbstractControl): ValidationErrors | null {

    if (Validators.required(control)) {
      return null;
    }

    let v: string = control.value;
    if (/^[0]\d{3}\/\d{6}$/.test(v) || /^[0]\d{3}\/\d{2}.\d{2}.\d{2}$/.test(v) ||
      /^[0]\d{2}\/\d{6}$/.test(v) || /^[0]\d{2}\/\d{2}.\d{2}.\d{2}$/.test(v) ||
      /^[0]\d{1}\/\d{7}$/.test(v) || /^[0]\d{1}\/\d{3}.\d{2}.\d{2}$/.test(v)) {
      return null;
    } else {
      return {'phone': true};
    }
  }

  public static mobile(control: AbstractControl): ValidationErrors | null {

    if (Validators.required(control)) {
      return null;
    }

    let v: string = control.value;
    if (/^[0]\d{3}\/\d{6}$/.test(v) || /^[0]\d{3}\/\d{2}.\d{2}.\d{2}$/.test(v)) {
      return null;
    } else {
      return {'mobile': true};
    }
  }

}
