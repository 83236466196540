import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {AddressResponse} from "@app/shared/model/address.response";
import {CityResponse} from "@app/shared/model/city.response";
import {CustomValidators} from "@app/shared/validators/custom.validator";
import {ConfirmPasswordValidator} from "@app/shared/validators/confirm-password.validator";
import {debounceTime, distinctUntilChanged, isEmpty, startWith, switchMap} from "rxjs/operators";
import {AddressService} from "@app/shared/services/address.service";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatSelectChange} from "@angular/material/select";
import {Register} from "@app/widget/register/register.model";
import {RegisterService} from "@app/widget/register/register.service";
import {Router} from "@angular/router";
import {AuthService} from "@app/core/auth/auth.service";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {BreakpointObserver} from "@angular/cdk/layout";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends ResponsiveComponent implements OnInit {
  @Output() onRegisterComplete: EventEmitter<void> = new EventEmitter();

  public registerForm: UntypedFormGroup;
  public charactersInput: UntypedFormControl;
  public cityInput: UntypedFormControl;
  public countryInput: UntypedFormControl;
  public emailInput: UntypedFormControl;
  public firstNameInput: UntypedFormControl;
  public lastNameInput: UntypedFormControl;
  public numberInput: UntypedFormControl;
  public passwordInput: UntypedFormControl;
  public confirmPasswordInput: UntypedFormControl;
  public phoneInput: UntypedFormControl;
  public postalCodeInput: UntypedFormControl;
  public streetInput: UntypedFormControl;

  public showCharacters: boolean;

  public addresses$: Observable<Array<AddressResponse>>;
  public postalCodes$: Observable<Array<CityResponse>>;
  public cities$: Observable<Array<CityResponse>>;

  public hide1: boolean;
  public hide2: boolean;
  public hasData: boolean;

  constructor(breakpointObserver: BreakpointObserver,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private authService: AuthService,
              private storage: StorageService,
              private dialog: DialogService,
              private service: RegisterService,
              private translate: TranslateService,
              private addressService: AddressService) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.showCharacters = false;

    this.hide1 = true;
    this.hide2 = true;

    this.charactersInput = this.formBuilder.control("", [Validators.minLength(2), Validators.maxLength(2)]);
    this.cityInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.countryInput = this.formBuilder.control("be", [Validators.required, Validators.minLength(2)]);
    this.emailInput = this.formBuilder.control("", [Validators.required, CustomValidators.email]);
    this.firstNameInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);
    this.lastNameInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);
    this.numberInput = this.formBuilder.control("", [Validators.required, Validators.minLength(1)]);
    this.passwordInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.confirmPasswordInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.phoneInput = this.formBuilder.control("", [Validators.required, CustomValidators.nonStrictPhone]);
    this.postalCodeInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.streetInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);

    this.registerForm = this.formBuilder.group({
      characters: this.charactersInput,
      city: this.cityInput,
      country: this.countryInput,
      email: this.emailInput,
      firstName: this.firstNameInput,
      lastName: this.lastNameInput,
      number: this.numberInput,
      password: this.passwordInput,
      confirm_password: this.confirmPasswordInput,
      phone: this.phoneInput,
      postalCode: this.postalCodeInput,
      street: this.streetInput
    });

    this.registerForm.setValidators(ConfirmPasswordValidator.MatchPassword);

    this.addresses$ = this.streetInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          this.hasData = false;
          if (typeof value === 'string') {
            if (value.length > 1) {
              return new Observable<Array<AddressResponse>>(subscriber => {
                this.addressService.findAddressess(value).subscribe(data => {
                  this.hasData = data.length != 0;
                  subscriber.next(data);
                  subscriber.complete();
                });
              });
              //return this.addressService.findAddressess(value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.postalCodes$ = this.postalCodeInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByPostalCode(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.cities$ = this.cityInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByName(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

  }

  public handleSubmitRegister(): void {
    if (this.registerForm.valid) {

      let register: Register = new Register();
      register.characters = this.registerForm.value.characters;
      register.city = this.registerForm.value.city;
      register.country = this.translate.instant("common.code.country." + this.registerForm.value.country);
      register.email = this.registerForm.value.email;
      register.first_name = this.registerForm.value.firstName;
      register.last_name = this.registerForm.value.lastName;
      register.number = this.registerForm.value.number;
      register.password = this.registerForm.value.password;
      register.phone = this.registerForm.value.phone;
      register.postal_code = this.registerForm.value.postalCode;
      register.street = this.registerForm.value.street;
      register.language = this.storage.language.toString();

      this.service.register(register).subscribe(data => {
        if (data.code === "ok") {
          this.storage.customerId = data.id;
          this.storage.temporary = true;
          this.storage.customerName = this.firstNameInput.value + " " + this.lastNameInput.value;
          this.dialog.showDialog("message.confirm-registration");
          this.authService.isConnected = true;
          this.authService.temporary = true;
          this.authService.handleLogin();
          this.onRegisterComplete.emit();
        } else {
          this.dialog.showError("error.server");
        }
      }, error => {
        if (error.status === 409) {
          this.dialog.showDialogWithAction("message.account-in-use", "auth.login.action-forgot-password").afterClosed().subscribe(data => {
            if (data) {
              this.handleForgotPassword();
            }
          })
        } else {
          this.dialog.showError("error.server");
        }
      })
    }
  }

  public addressSelected(event: MatAutocompleteSelectedEvent): void {
    this.streetInput.patchValue(event.option.value.street);
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
    if (event.option.value.country === "België") {
      this.countryInput.patchValue("be");
    } else if (event.option.value.country === "Nederland") {
      this.countryInput.patchValue("nl");
    }
    this.setShowCharacters(event.option.value.country);
  }

  public citySelected(event: MatAutocompleteSelectedEvent): void {
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
  }

  public countrySelected($event: MatSelectChange): void {
    this.postalCodeInput.patchValue("");
    this.charactersInput.patchValue("");
    this.cityInput.patchValue("");

    if ($event) {
      this.setShowCharacters($event.value)
    } else {
      this.showCharacters = false;
    }
  }

  private handleForgotPassword(): void {
    this.dialog.showDialogWithAction("message.reset-password-confirm", "auth.login.action-reset-password").afterClosed().subscribe(data => {
      if (data) {
        this.service.resetPassword(this.emailInput.value).subscribe(data => {
          this.dialog.showDialog("message.reset-password");
          this.router.navigate(["home"]);
        }, error => {
          if (error.status === 401) {
            this.dialog.showError("error.unknown-email");
          } else {
            this.dialog.showError("error.server");
          }
        });
      }
    });
  }

  private setShowCharacters(country: string): void {
    this.showCharacters = this.isNL(country);
    if (this.showCharacters) {
      this.charactersInput.setValidators([Validators.required, Validators.minLength(2)]);
    } else {
      this.charactersInput.setValidators([Validators.minLength(2)]);
    }
    this.charactersInput.updateValueAndValidity();
  }

  private isNL(country: string): boolean {
    return country === "Nederland";
  }
}
