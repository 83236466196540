<div class="map">

  <h1 mat-dialog-title>{{'home.map.title' | translate}}</h1>

  <mat-dialog-content>

    <google-map [center]="center" [zoom]="zoom">
      <map-marker [position]="center"></map-marker>
    </google-map>

  </mat-dialog-content>

  <mat-dialog-actions class="button-center">
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>

</div>
