import {Injectable, TemplateRef} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {DialogComponent} from "@app/widget/dialog/dialog.component";
import {ErrorComponent} from "@app/widget/error/error.component";
import {SnackbarComponent} from "@app/widget/snackbar/snackbar.component";
import {ComponentType} from "@angular/cdk/portal";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  public showDialog(messageKey: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 2000,
      verticalPosition: 'top',
      data: {
        key: messageKey
      }
    });
  }

  public showDialogWithAction(messageKey: string, actionKey: string): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: {
        key: messageKey,
        action: actionKey
      }
    });
  }

  public showDialogWithText(text: string): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: {
        text: text
      }
    });
  }

  public showError(messageKey: string): void {
    this.dialog.open(ErrorComponent, {
      data: {
        key: messageKey,
        redirect: false
      }
    });
  }

  public showErrorAndRedirect(messageKey: string): void {
    this.dialog.open(ErrorComponent, {
      data: {
        key: messageKey,
        redirect: true
      }
    });
  }

  public open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, config);
  }
}
