import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {CodedResponse} from "@app/model/coded.response";
import {PetResponse} from "@app/secured/my-pets/model/pet.response";
import {Pet} from "@app/secured/my-pets/model/pet.model";
import {PetPicture} from "@app/secured/my-pets/model/petpicture.model";

@Injectable({
  providedIn: 'root'
})
export class MyPetsService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findPets(customerId: number): Observable<Array<PetResponse>> {
    return this.httpClient.get<PetResponseObject>(`${environment.baseUrl}/customer/${customerId}/patient/all`, this.security.getHttpOptions())
      .pipe(
        map((response: PetResponseObject) => {
          return response.patients;
        }));
  }

  public createPet(customerId: number, data: Pet): Observable<CodedResponse> {
    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/customer/${customerId}/patient`, data, this.security.getHttpOptions());
  }

  public updatePet(patientId: number, data: Pet): Observable<CodedResponse> {
    return this.httpClient.put<CodedResponse>(`${environment.baseUrl}/patient/${patientId}`, data, this.security.getHttpOptions());
  }

  public updatePetPicture(patientId: number, file: File):  Observable<CodedResponse> {
    const fd = new FormData();
    fd.append("file", file, file.name);
    return this.httpClient.put<CodedResponse>(`${environment.baseUrl}/patient/${patientId}/picture/upload?file_name=${encodeURIComponent(file.name)}`, fd, this.security.getHttpDocumentUploadOptions());
  }

  public deletePet(patientId: number, date: string): Observable<CodedResponse> {
    return this.httpClient.delete<CodedResponse>(`${environment.baseUrl}/patient/${patientId}?date=${date}`, this.security.getHttpOptions());
  }

  public deletePetPicture(patientId: number):  Observable<CodedResponse> {
    return this.httpClient.delete<CodedResponse>(`${environment.baseUrl}/patient/${patientId}/picture`, this.security.getHttpOptions());
  }

}

interface PetResponseObject {
  patients: Array<PetResponse>;
}
