import {NgModule} from '@angular/core';
import {MyDataModule} from "@app/secured/my-data/my-data.module";
import {MyPetsModule} from "@app/secured/my-pets/my-pets.module";
import {MyAppointmentsModule} from "@app/secured/my-appointments/my-appointments.module";
import {SendMessageModule} from "@app/secured/send-message/send-message.module";
import {MyOrdersModule} from "@app/secured/my-orders/my-orders.module";

@NgModule({
  imports: [
    MyDataModule,
    MyPetsModule,
    MyAppointmentsModule,
    MyOrdersModule,
    SendMessageModule
  ],
  exports: [
    MyDataModule,
    MyPetsModule,
    MyAppointmentsModule,
    MyOrdersModule,
    SendMessageModule
  ]
})
export class SecuredModule {
}
