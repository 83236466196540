<div class="center">
  <div class="my-appointments">
    <h1>{{'myAppointments.title' | translate}}</h1>

    <mat-list class="list">
      <mat-list-item *ngFor="let appointment of appointments">
        <div mat-line class="list-item">
          <button mat-icon-button [disabled]="!appointment.editable" (click)="handleEditAppointment(appointment)">
            <mat-icon>create</mat-icon>
          </button>
          <button mat-icon-button [disabled]="!appointment.editable" (click)="handleDeleteAppointment(appointment)">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <div class="description">
            {{'myAppointments.appointment' | translate}} {{appointment.date | date}} {{appointment.hour}} {{'myAppointments.for' | translate}} {{appointment.patientName}}
          </div>
        </div>
      </mat-list-item>
    </mat-list>

    <button mat-button color="accent" (click)="handleNewAppointment()">
      <mat-icon>event_available</mat-icon>
      {{'myAppointments.newAppointment' | translate}}
    </button>
  </div>
</div>
