import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyPetsService} from "@app/secured/my-pets/my-pets.service";
import {TranslateService} from "@ngx-translate/core";
import {PetComponent} from "@app/secured/my-pets/pet/pet.component";
import {DeletePetComponent} from "@app/secured/my-pets/delete-pet/delete-pet.component";
import {PetResponse} from "@app/secured/my-pets/model/pet.response";
import {PetPicture} from "@app/secured/my-pets/model/petpicture.model";

export interface PetData {
  pet: PetResponse;
}

@Component({
  selector: 'app-my-pets',
  templateUrl: './my-pets.component.html',
  styleUrls: ['./my-pets.component.scss']
})
export class MyPetsComponent implements OnInit {

  public patients: Array<PetResponse>;

  constructor(private storage: StorageService,
              private dialog: DialogService,
              private translate: TranslateService,
              private service: MyPetsService) {
  }

  ngOnInit() {
    this.load();
  }

  public getPicture(picture: any): string {
    if (picture && picture.substring(0, 4) === "data") {
      var res = picture.split("base64");
      switch (res[0]) {
        case "dataimage/jpeg":
          return "data:image/jpg;base64," + res[1];
        case "dataimage/png":
          return "data:image/png;base64," + res[1] + "mCC";
        case "dataimage/bmp":
          return "data:image/bmp;base64," + res[1];
        case "dataimage/gif":
          return "data:image/gif;base64," + res[1];
        default:
          return "data:image/png;base64," + picture;
      }
    } else {
      return "data:image/png;base64," + picture;
    }
  }

  public getGender(gender: string): string {
    return this.translate.instant("pet.genderType." + gender);
  }

  public handleCreatePet(): void {
    this.dialog.open(PetComponent, {
      width: "95vw",
      maxWidth: "500px",
      disableClose: true,
      autoFocus: true,
      data: {
        pet: null
      }
    }).afterClosed().subscribe(data => {
      this.load();
    });
  }

  public handleUpdatePet(pet: PetResponse): void {
    this.dialog.open(PetComponent, {
      width: "95vw",
      maxWidth: "500px",
      disableClose: true,
      autoFocus: true,
      data: {
        pet: pet
      }
    }).afterClosed().subscribe(data => {
      this.load();
    });
  }

  public handlePicturePet(pet: PetResponse, event: any) {
    const file = event.target.files[0];

    this.service.updatePetPicture(pet.patient_id, file).subscribe(result => {
      this.dialog.showDialog("message.create-picture-success");
      this.load()
      }, error => {
        this.dialog.showError("error.server");
      });

    // let reader: FileReader = new FileReader();
    //
    // reader.onload = () => {
    //   let picture: PetPicture = new PetPicture();
    //   picture.picture_file_name = file.name;
    //   picture.picture = reader.result;
    //
    //   this.service.updatePetPicture(pet.patient_id, picture).subscribe(data => {
    //     this.dialog.showDialog("message.create-picture-success");
    //     this.load()
    //   }, error => {
    //     this.dialog.showError("error.server");
    //   });
    // };
    //
    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  }

  public handleDeletePet(pet: PetResponse): void {
    this.dialog.open(DeletePetComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        pet: pet
      }
    }).afterClosed().subscribe(data => {
      this.load();
    });
  }

  public handleDeletePetPicture(pet: PetResponse): void {
    this.service.deletePetPicture(pet.patient_id).subscribe(data => {
      this.load();
    });
  }

  private load(): void {
    this.service.findPets(this.storage.customerId).subscribe(data => {
      this.patients = data;
    }, error => {
      this.dialog.showError("error.server");
    });
  }
}
