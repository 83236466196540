import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "@app/core/auth/auth.service";
import {DialogService} from "@app/core/dialog/dialog.service";

@Injectable({
  providedIn: 'root'
})
export class RegisteredGuardService implements CanActivate {

  constructor(private authService: AuthService,
              private dialog: DialogService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.temporary) {
      this.dialog.showDialog("message.must-be-registered");
      return false;
    } else {
      return true;
    }
  }
}
