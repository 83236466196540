import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {CodedResponse} from "@app/model/coded.response";
import {CustomerResponse} from "@app/model/customer.response";
import {Customer} from "@app/secured/my-data/model/customer.model";
import {map} from "rxjs/operators";
import {CustomerAccountResponse} from "@app/secured/my-data/model/customeraccount.response";
import {Account} from "@app/secured/my-data/model/account.model";

@Injectable({
  providedIn: 'root'
})
export class MyDataService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findCustomer(customerId: number): Observable<CustomerResponse> {
    return this.httpClient.get<CustomerResponse>(`${environment.baseUrl}/customer/${customerId}`, this.security.getHttpOptions());
  }

  public updateCustomer(customerId: number, data: Customer): Observable<CodedResponse> {
    return this.httpClient.put<CodedResponse>(`${environment.baseUrl}/customer/${customerId}`, data, this.security.getHttpOptions());
  }

  public updatePassword(customerId: number, email: string, password: string): Observable<CodedResponse> {
    return this.httpClient.put<CodedResponse>(`${environment.baseUrl}/customer/${customerId}/password?email=${email}&password=${password}`, null, this.security.getHttpOptions());
  }

  public findAccounts(customerId: number): Observable<Array<CustomerAccountResponse>> {
    return this.httpClient.get<CustomerAccountResponseObject>(`${environment.baseUrl}/customer/${customerId}/account/list`, this.security.getHttpOptions())
      .pipe(
        map((response: CustomerAccountResponseObject) => {
          return response.list;
        }));
  }

  public createAccount(id: number, request: Account): Observable<CodedResponse> {
    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/customer/${id}/account`, request, this.security.getHttpOptions());
  }

  public deleteAccount(id: number): Observable<CodedResponse> {
    return this.httpClient.delete<CodedResponse>(`${environment.baseUrl}/customer/account/${id}`, this.security.getHttpOptions());
  }
}

interface CustomerAccountResponseObject {
  list: Array<CustomerAccountResponse>;
}
