import {NgModule} from '@angular/core';
import {NavigationComponent} from "@app/widget/navigation/navigation.component";
import {TitlebarComponent} from "@app/widget/titlebar/titlebar.component";
import {SharedModule} from "@app/shared/shared.module";
import {RouterModule} from "@angular/router";
import {ErrorComponent} from "@app/widget/error/error.component";
import {DialogComponent} from './dialog/dialog.component';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {RegisterComponent} from './register/register.component';
import { LoginComponent } from './login/login.component';

@NgModule({
    declarations: [
        NavigationComponent,
        TitlebarComponent,
        ErrorComponent,
        DialogComponent,
        SnackbarComponent,
        RegisterComponent,
        LoginComponent
    ],
    imports: [
        SharedModule,
        RouterModule
    ],
    exports: [
        TitlebarComponent,
        NavigationComponent,
        ErrorComponent,
        DialogComponent,
        SnackbarComponent,
        RegisterComponent,
        LoginComponent
    ]
})
export class WidgetModule {
}
