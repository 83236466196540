import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "@app/core/storage/storage.service";

export interface ErrorData {
  key: string;
  redirect: boolean;
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public title: string;
  public message: string;

  constructor(private dialogRef: MatDialogRef<ErrorComponent>,
              private translate: TranslateService,
              private storage: StorageService,
              @Inject(MAT_DIALOG_DATA) private data: ErrorData) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.title = this.translate.instant("error.title");
    this.message = this.translate.instant(this.data.key);
  }

  public onClick(): void {
    this.dialogRef.close();
    if (this.data.redirect) {
      if (this.storage.vetInfo && this.storage.vetInfo.company_website) {
        window.open(this.storage.vetInfo.company_website, "_self");
      } else {
        window.open("https://www.vet-files.com", "_self");
      }
    }
  }
}
