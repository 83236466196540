<form class="login-form" [formGroup]="loginForm">
  <mat-form-field>
    <input matInput type="text" id="username" formControlName="username"
           placeholder="{{'auth.login.username' | translate}}" required/>
  </mat-form-field>
  <mat-form-field>
    <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password" placeholder="{{'auth.login.password' | translate}}" required/>
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>

  <button mat-raised-button color="primary" type="button" (click)="handleSubmitLogin()" [disabled]="!loginForm.valid">{{'auth.login.action-submit' | translate}}</button>
  <button mat-button class="bottom" type="button" (click)="handleForgotPassword()">{{'auth.login.action-forgot-password' | translate}}</button>
</form>
