import {Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {AppointmentTypeResponse} from "@app/public/appointment/model/appointmenttype.response";
import {AppointmentService} from "@app/public/appointment/appointment.service";
import {MatStepper} from "@angular/material/stepper";
import {ButtonTextCompoundInput, CustomButtonInput, ToolbarInput} from "@fullcalendar/core/types/input-types";
import {Calendar} from "@fullcalendar/core";
import listPlugin from "@fullcalendar/list";
import {formatDate} from "@angular/common";
import {EventInput, EventRenderingChoice} from "@fullcalendar/core/structs/event";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "@app/core/storage/storage.service";
import {ReservationResponse} from "@app/public/appointment/model/reservation.response";
import {AppointmentWizard} from "@app/public/appointment/appointment.wizard";
import {UntypedFormBuilder} from "@angular/forms";
import {AuthService} from "@app/core/auth/auth.service";
import {AddressService} from "@app/shared/services/address.service";
import {PetService} from "@app/shared/services/pet.service";
import {ErrorService} from "@app/core/error/error.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {BreedService} from "@app/shared/services/breed.service";
import {AppointmentRequest} from "@app/public/appointment/model/appointment.request";
import {MultipleAppointmentsRequest} from "@app/public/appointment/model/multipleappointments.request";
import {AppointmentTypePracticeResponse} from "@app/public/appointment/model/appointmenttypepractice.response";
import {AppointmentTypeGroupResponse} from "@app/public/appointment/model/appointmenttypegroup.response";
import {Language} from "@app/model/language";
import {ActivatedRoute, Router} from "@angular/router";
import {AppointmentEdit} from "@app/public/appointment/model/appointmentedit";
import {MatDatepicker, MatDatepickerInputEvent} from "@angular/material/datepicker";
import * as moment from "moment";

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent extends AppointmentWizard implements OnInit {
  @ViewChild("stepper", {static: true}) public stepper: MatStepper;
  @ViewChild("picker", {static: true}) public picker: MatDatepicker<any>;

  public step1completed: boolean;
  public step2completed: boolean;

  public practices: Array<AppointmentTypePracticeResponse>;
  public groups: Array<AppointmentTypeGroupResponse>;
  public appointmentTypes: Array<AppointmentTypeResponse>;

  public practice: AppointmentTypePracticeResponse;
  public group: AppointmentTypeGroupResponse;
  public type: AppointmentTypeResponse;

  public typeSelectText: string = "";

  private edit: AppointmentEdit;

  private calendar: Calendar;

  constructor(breakpointObserver: BreakpointObserver,
              formBuilder: UntypedFormBuilder,
              storage: StorageService,
              auth: AuthService,
              translate: TranslateService,
              service: AppointmentService,
              addressService: AddressService,
              petService: PetService,
              breedService: BreedService,
              dialog: DialogService,
              err: ErrorService,
              private route: ActivatedRoute,
              private router: Router) {
    super(breakpointObserver, formBuilder, storage, auth, service, addressService, petService, breedService, translate, dialog, err);
  }

  ngOnInit() {
    /*
    FIXME als het nodig is
    this.isHandset$.subscribe(mobile => {
      if (!mobile && this.isLockedRegistration) {
        this.dialog.showDialog("message.registration-locked");
      }
    });
    */

    this.selectedPractice = null;
    this.practices = null;
    this.groups = null;
    this.appointmentTypes = null;
    this.edit = null;

    this.isEditable = true;

    this.step1completed = false;
    this.step2completed = false;

    this.route.queryParams.subscribe(data => {
      if (data && data["edit"]) {
        this.edit = data["edit"];
      }
    });

    this.loadAppointmentTypes();

    super.onInit();

    this.translate.onLangChange.subscribe(data => {
      this.translateAppointmentGroups();
      this.translateAppointmentTypes();
    });
  }

  private loadAppointmentTypes(): void {
    this.service.getAppointmentTypesGrouped().subscribe(data => {
      this.practices = data;
      this.groups = null;
      this.appointmentTypes = null;

      if (this.practices.length === 1) {
        this.practice = this.practices[0];
        this.handleSelectPractice()
      }

      if (this.edit) {
        this.practices.forEach(value => {
          if (value.name == this.edit.location) {
            this.practice = value;
            this.handleSelectPractice()
          }
        });
      }
    });
  }

  public handleSelectPractice(): void {
    if (this.practice) {
      this.selectedPractice = this.practice.id;
      this.appointmentTypes = null;

      if (this.practice.data.length === 1) {
        this.group = this.practice.data[0];
        this.handleSelectGroup();
      } else {
        this.groups = this.practice.data;
        this.translateAppointmentGroups();

        if (this.edit) {
          this.groups.forEach(value => {
            if (value.name == this.edit.appointmentTypeGroup) {
              this.group = value;
              this.handleSelectGroup();
            }
          });
        }
      }
    }
  }

  public handleSelectGroup(): void {
    if (this.group) {
      this.appointmentTypes = this.group.data;
      this.translateAppointmentTypes();

      if (this.edit) {
        this.appointmentTypes.forEach(value => {
          if (value.description_nl == this.edit.appointmentType) {
            this.type = value;
            this.handleSelectAppointmentType();
          }
        });
      }
    }
  }

  private translateAppointmentGroups(): void {
    if (this.groups) {
      for (let value of this.groups) {
        switch (this.storage.language) {
          case Language.NL:
            value.translate = value.name_nl;
            break;
          case Language.FR:
            value.translate = value.name_fr;
            break;
          case Language.EN:
            value.translate = value.name_en;
            break;
          case Language.DE:
            value.translate = value.name_de;
            break;
          default:
            value.translate = value.name;
            break;
        }
      }
    }
  }

  private translateAppointmentTypes(): void {
    if (this.appointmentTypes) {
      for (let value of this.appointmentTypes) {
        switch (this.storage.language) {
          case Language.NL:
            value.translate = value.description_nl;
            break;
          case Language.FR:
            value.translate = value.description_fr;
            break;
          case Language.EN:
            value.translate = value.description_en;
            break;
          case Language.DE:
            value.translate = value.description_de;
            break;
          default:
            value.translate = value.description_nl;
            break;
        }
      }
    }
  }

  public handleSelectAppointmentType(): void {
    if (this.type) {
      let defaultDate: Date = new Date();
      if (this.edit) {
        defaultDate = this.edit.date;
      }

      switch (this.storage.language) {
        case Language.NL:
          if (this.type.popup_nl) {
            this.dialog.showDialogWithText(this.type.popup_nl);
          }
          break;
        case Language.FR:
          if (this.type.popup_fr) {
            this.dialog.showDialogWithText(this.type.popup_fr);
          }
          break;
        case Language.EN:
          if (this.type.popup_en) {
            this.dialog.showDialogWithText(this.type.popup_en);
          }
          break;
        case Language.DE:
          if (this.type.popup_de) {
            this.dialog.showDialogWithText(this.type.popup_de);
          }
          break;
        default:
          if (this.type.popup_nl) {
            this.dialog.showDialogWithText(this.type.popup_nl);
          }
          break;
      }

      this.appointmentType = this.type.description_nl;
      this.duration = null;
      this.step1completed = true;

      if (this.calendar) {
        this.calendar.destroy();
      }
      this.initCalendar(defaultDate);

      setTimeout(() => {
        this.stepper.next();
        if (this.edit) {
          this.isEditable = false;
        }
      });
    }
  }

  public openDatePicker(): void {
    this.picker.select(moment(this.calendar.getDate()));
    this.picker.open();
  }

  public selectDate($event: MatDatepickerInputEvent<any>): void {
    let shownDay = moment($event.value);
    if (this.calendar) {
      this.calendar.destroy();
    }
    this.initCalendar(shownDay.toDate());
  }

  private initCalendar(defaultDate: Date): void {
    let calendarEl = document.getElementById('calendar');

    let customButton: CustomButtonInput;
    let header: ToolbarInput;
    let buttonText: ButtonTextCompoundInput;

    customButton = {
      text: this.translate.instant("calendar.pick-week"),
      click: () => {
        this.openDatePicker();
      }
    };

    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      header = {
        left: '',
        center: 'prev,next',
        right: ''
      };
      buttonText = {
        prev: this.translate.instant("calendar.previous-week"),
        next: this.translate.instant("calendar.next-week"),
      };

    } else {
      header = {
        left: 'title',
        center: '',
        right: 'prev,pick,next'
      };
      buttonText = {
        prev: this.translate.instant("calendar.previous-week"),
        next: this.translate.instant("calendar.next-week"),
      };
    }

    let validStart: string = this.getPlannableFrom();
    let validEnd: string = this.getPlannableUntil();

    this.calendar = new Calendar(calendarEl, {
      plugins: [listPlugin],
      defaultView: "listWeek",
      defaultDate: defaultDate,
      firstDay: 1,
      locale: this.storage.locale,
      customButtons: {pick: customButton},
      buttonText: buttonText,
      header: header,
      titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
      listDayFormat: {
        year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
      },
      listDayAltFormat: false,
      height: "parent",
      noEventsMessage: this.translate.instant("calendar.no-events"),
      validRange: {
        start: validStart,
        end: validEnd
      },
      events: (info, successCallback, failureCallback) => {
        this.service.getAvailabilitiesForWeek(this.selectedPractice, this.appointmentType, info.start, info.end).subscribe(data => {
          let events = this.renderEvents(data);

          if (this.calendar) {
            this.calendar.getEvents().forEach((event) => {
              event.remove();
            });
          }

          successCallback(events);
        });
      },
      eventClick: (info) => {
        if (info.event.id === "available_hours") {
          //remove other 'new' event
          this.calendar.getEvents().forEach((event) => {
            if (event.id === 'new') {
              event.remove();
              this.createEvent(event.extendedProps);
            }
          });
          if (this.edit) {
            //remove edit event
            this.removeEditEvent();
            //save event
            let request: AppointmentRequest = new AppointmentRequest();
            request.vet_id = info.event.extendedProps.vet_id;
            request.practice = this.selectedPractice;
            request.date = info.event.start;
            request.start = formatDate(info.event.start, "HH:mm", "nl-BE");
            request.end = formatDate(info.event.end, "HH:mm", "nl-BE");
            this.service.updateAppointment(this.edit.id, request).subscribe(data => {
              this.dialog.showDialog("message.update-appointment-success");
              this.router.navigate(["myappointments"]);
            });
            //remove edit id
            this.edit = null;
          } else {
            //remove this event
            info.event.remove();
            this.createNewEvent(info.event.extendedProps);

            this.vetId = info.event.extendedProps.vet_id;
            if (this.storage.vetInfo.must_select_vet) {
              this.vet = info.event.extendedProps.vet_name;
            } else {
              this.vet = this.storage.vetInfo.company_name;
            }
            this.start = info.event.start;
            this.end = info.event.end;
            this.location = info.event.extendedProps.location;
            this.day = formatDate(this.start, "EEEE dd MMMM", this.storage.locale);
            this.time = this.formatTime(this.start, this.end);
            this.step2completed = true;
            setTimeout(() => {
              this.stepper.next();
            });
          }
        }
      },
    });

    if (this.calendar) {
      this.calendar.render();
    }
  }

  private getPlannableFrom(): string {
    return formatDate(this.getFirstDayOfWeek(), "yyyy-MM-dd", "nl-BE");
  }

  private getPlannableUntil(): string {
    let weeks: number;
    if (this.storage.vetInfo.plannable_weeks) {
      weeks = this.storage.vetInfo.plannable_weeks + 1;
    } else {
      weeks = 20;
    }

    let d: Date = this.getFirstDayOfWeek();
    d.setDate(d.getDate() + (weeks * 7));
    return formatDate(d, "yyyy-MM-dd", "nl-BE");
  }

  private getFirstDayOfWeek(): Date {
    let d: Date = new Date();
    let dayOfWeek = d.getDay();
    let firstDayOfWeek = new Date(d);
    let diff = dayOfWeek >= 1 ? dayOfWeek - 1 : 6 - dayOfWeek;
    firstDayOfWeek.setDate(d.getDate() - diff);
    firstDayOfWeek.setHours(0, 0, 0, 0);

    return firstDayOfWeek;
  }

  private renderEvents(data: Array<ReservationResponse>): Array<any> {
    let events = [];

    data.forEach(item => {
      let eventId: string;
      let eventTitle: string;
      let eventRendering: EventRenderingChoice;
      let eventColor: string;
      let className: string;

      switch (item.type) {
        case "AVAILABLE":
          eventId = "available_hours";
          if (this.storage.vetInfo.must_select_vet) {
            eventTitle = this.translate.instant("calendar.type.available") + " - " + item.vet_name;
          } else {
            eventTitle = this.translate.instant("calendar.type.available");
          }
          eventRendering = "";
          eventColor = "#8fdf82";
          className = "fc-color-black";
          break;
      }

      let event: EventInput = {
        id: eventId,
        title: eventTitle,
        start: item.start,
        end: item.end,
        editable: item.editable,
        backgroundColor: eventColor,
        className: className,
        rendering: eventRendering,
        extendedProps: item
      };
      events.push(event);
    });

    if (this.edit) {
      let event: EventInput = {
        id: "edit",
        title: this.translate.instant("calendar.appointment.title-with-name") + " " + this.edit.vetName,
        start: formatDate(this.edit.date, "yyyy-MM-dd", "nl-BE") + "T" + this.edit.hour + ":00",
        end: formatDate(this.edit.date, "yyyy-MM-dd", "nl-BE") + "T" + this.edit.endHour + ":00",
        editable: false,
        backgroundColor: "",
        className: "",
        rendering: ""
      };
      events.push(event);
    }

    return events;
  }

  public handleSubmit(): void {
    if (this.isTempLoggedIn) {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.appointment_type = this.appointmentType;
      request.remark = this.reasonInput.value;
      request.temp_customer_id = this.storage.customerId;
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    } else if (this.isLoggedIn) {
      let request: MultipleAppointmentsRequest = new MultipleAppointmentsRequest();
      request.number_of_appointments = this.selectedPatientIds.length;
      request.practice = this.selectedPractice;
      request.start_date = this.start;
      request.vet_id = this.vetId;
      request.appointment_type = this.appointmentType;

      this.service.checkMultipleAppointments(request).subscribe(data => {
        let itemsProcessed: number = 0;
        for (let i: number = 0; i < this.selectedPatientIds.length; i++) {
          let patientId: number = this.selectedPatientIds[i];
          let start: Date = data[i].start;
          let end: Date = data[i].end;

          let request: AppointmentRequest = new AppointmentRequest();
          request.vet_id = this.vetId;
          request.practice = this.selectedPractice;
          request.date = start;
          request.start = formatDate(start, "HH:mm", "nl-BE");
          request.end = formatDate(end, "HH:mm", "nl-BE");
          request.appointment_type = this.appointmentType;
          request.remark = this.reasonInput.value;
          request.customer_id = this.storage.customerId;
          request.patient_id = patientId;

          this.service.createAppointment(request).subscribe(data => {
            itemsProcessed++;
            if (itemsProcessed === this.selectedPatientIds.length) {
              this.stepper.next();
              this.isEditable = false;
            }
          }, error => {
            if (error.status === 409) {
              this.dialog.showError("error.account-in-use");
            } else if (error.status === 406) {
              this.dialog.showError("error.same-day-appointment");
            } else if (error.status === 412) {
              this.dialog.showError("error.no-user-information");
            } else {
              this.err.handleError(error);
            }
          });
        }
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.multiple-appointments");
        } else if (error.status === 412) {
          this.dialog.showError("error.number-of-patients-appointments");
        } else {
          this.err.handleError(error);
        }
      });
    } else {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.appointment_type = this.appointmentType;
      request.remark = this.reasonInput.value;
      request.last_name = this.guestAccountForm.value.lastName;
      request.first_name = this.guestAccountForm.value.firstName;
      request.phone = this.guestAccountForm.value.phone;
      request.email = this.guestAccountForm.value.email;
      request.street = this.guestAccountForm.value.street;
      request.number = this.guestAccountForm.value.number;
      request.city = this.guestAccountForm.value.city;
      request.postal_code = this.guestAccountForm.value.postalCode;
      request.characters = this.guestAccountForm.value.characters;
      request.country = this.guestAccountForm.value.country;
      request.language = this.storage.language.toString();
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    }
  }

  private createEvent(data: ReservationResponse): void {
    let eventTitle: string;
    if (this.storage.vetInfo.must_select_vet) {
      eventTitle = this.translate.instant("calendar.type.available") + " - " + data.vet_name;
    } else {
      eventTitle = this.translate.instant("calendar.type.available");
    }

    let event: EventInput = {
      id: "available_hours",
      title: eventTitle,
      start: data.start,
      end: data.end,
      editable: false,
      backgroundColor: "#8fdf82",
      rendering: "",
      className: "fc-color-black",
      extendedProps: data
    };
    this.calendar.addEvent(event);
  }

  private removeEditEvent(): void {
    this.calendar.getEvents().forEach((event) => {
      if (event.id === 'edit') {
        event.remove();
      }
    });
  }

  private createNewEvent(data: ReservationResponse) {
    let eventTitle: string;
    if (this.storage.vetInfo.must_select_vet) {
      eventTitle = this.translate.instant("calendar.wizard.step5") + " - " + data.vet_name;
    } else {
      eventTitle = this.translate.instant("calendar.wizard.step5");
    }
    //add the event
    let event: EventInput = {
      id: "new",
      title: eventTitle,
      start: data.start,
      end: data.end,
      editable: false,
      rendering: "",
      className: "fc-color-black",
      extendedProps: data
    };
    this.calendar.addEvent(event);
  }
}
