<div class="center">
  <div class="my-pets">

    <div class="header">
      <h1 class="title">{{'myPets.title' | translate}}</h1>
      <button mat-raised-button (click)="handleCreatePet()">
        <div class="add-button">{{'myPets.action-add' | translate}}</div>
      </button>
    </div>

    <div class="card-list">
      <!--<mat-card class="card">
        <mat-card-content class="card-content-new">
          <button mat-button (click)="handleCreatePet()">
            <div class="add-button">{{'myPets.action-add' | translate}}</div>
          </button>
        </mat-card-content>
      </mat-card>-->

      <mat-card class="card" *ngFor="let patient of patients">
        <mat-card-header>
          <img mat-card-avatar class="header-image" src="{{getPicture(patient.picture)}}" (click)="pictureInput.click()"/>
          <mat-card-title>{{patient.name}}</mat-card-title>
          <mat-card-subtitle>{{patient.breed}}</mat-card-subtitle>
          <mat-card-subtitle>{{getGender(patient.gender)}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-content">
          <div class="row">
            <span class="content-label">{{'pet.dateOfBirth' | translate}}</span>
            <span class="content-text">{{patient.date_of_birth | date}}</span>
          </div>
          <div class="row">
            <span class="content-label">{{'pet.hairColour' | translate}}</span>
            <span class="content-text">{{patient.hair_colour}}</span>
          </div>
          <div class="row">
            <span class="content-label">{{'pet.hair' | translate}}</span>
            <span class="content-text">{{patient.hair}}</span>
          </div>
          <div class="row">
            <span class="content-label">{{'pet.identification' | translate}}</span>
            <span class="content-text">{{patient.identification}}</span>
          </div>
          <div class="row">
            <span class="content-label">{{'pet.passport' | translate}}</span>
            <span class="content-text">{{patient.passport}}</span>
          </div>
        </mat-card-content>
        <mat-card-actions class="buttons">
          <button mat-button (click)="handleUpdatePet(patient)">{{'myPets.action-update' | translate}}</button>
          <input #pictureInput hidden type="file" onclick="this.value=null" (change)="handlePicturePet(patient, $event)"
                 accept=".jpg,.png,.bmp,.gif"/>
          <button mat-button *ngIf="!patient.has_picture"
                  (click)="pictureInput.click()">{{'myPets.action-picture' | translate}}</button>
          <button mat-button *ngIf="patient.has_picture"
                  (click)="handleDeletePetPicture(patient)">{{'myPets.action-picture-delete' | translate}}</button>
          <button mat-button (click)="handleDeletePet(patient)">{{'myPets.action-delete' | translate}}</button>
        </mat-card-actions>
      </mat-card>

    </div>

  </div>
</div>
