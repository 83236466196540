import {Injectable} from '@angular/core';
import {Vet} from "@app/public/appointment/model/vet";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public selectedPractice: number;
  public selectedPracticeAddress: string;
  public selectedView: string;

  private vets: Array<Vet> = [];

  constructor() {
  }

  public addVet(id: number, name: string) {
    if (this.vets.findIndex(p => p.id === id) == -1) {
      this.vets.push(new Vet(id, name));
    }
  }

  public getVetName(id: number): string {
    let vetName: string = "";
    for (let vet of this.vets) {
      if (vet.id === id) {
        vetName = vet.name;
      }
    }
    if (vetName === "" && this.vets.length > 0) {
      vetName = this.vets[0].name;
    }
    return vetName;
  }
}
