export class AppointmentRequest {

  vet_id: number;
  practice: number;
  date: Date;
  start: string;
  end: string;
  remark: string;

  customer_id: number;
  temp_customer_id: number;
  patient_id: number;

  last_name: string;
  first_name: string;
  phone: string;
  email: string;
  street: string;
  number: string;
  city: string;
  postal_code: string;
  characters: string;
  country: string;
  language: string;

  patient_name: string;
  gender: string;
  date_of_birth: string;
  breed_id: number;

  appointment_type: string;
}
