import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AuthService} from "@app/core/auth/auth.service";
import {Component, ViewChild} from "@angular/core";
import {MatStepper} from "@angular/material/stepper";
import {AppointmentService} from "@app/public/appointment/appointment.service";
import {StorageService} from "@app/core/storage/storage.service";
import {PetService} from "@app/shared/services/pet.service";
import {PetResponse} from "@app/shared/model/pet.response";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "@app/shared/validators/custom.validator";
import {debounceTime, distinctUntilChanged, startWith, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {AddressResponse} from "@app/shared/model/address.response";
import {CityResponse} from "@app/shared/model/city.response";
import {AddressService} from "@app/shared/services/address.service";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatSelectChange} from "@angular/material/select";
import {ErrorService} from "@app/core/error/error.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MatRadioChange} from "@angular/material/radio";
import {BreedResponse} from "@app/shared/model/breed.response";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MultipleAppointmentsRequest} from "@app/public/appointment/model/multipleappointments.request";
import {PetComponent} from "@app/secured/my-pets/pet/pet.component";
import {BreedService} from "@app/shared/services/breed.service";
import {TranslateService} from "@ngx-translate/core";
import {formatDate} from "@angular/common";

@Component({
  template: ''
})
export class AppointmentWizard extends ResponsiveComponent {
  @ViewChild("stepper", {static: true}) public stepper: MatStepper;

  public isEditable: boolean;
  public isLoggedIn: boolean;
  public isTempLoggedIn: boolean;
  public isLockedRegistration: boolean;

  protected selectedPractice;
  protected start: Date;
  protected end: Date;
  protected vetId: number;
  protected duration: number;

  //account
  public stepAccountcompleted: boolean;
  public accountForm: UntypedFormGroup;
  public guestAccountForm: UntypedFormGroup;

  public showCharacters: boolean;
  private charactersInput: UntypedFormControl;
  private cityInput: UntypedFormControl;
  private countryInput: UntypedFormControl;
  private numberInput: UntypedFormControl;
  private postalCodeInput: UntypedFormControl;
  private streetInput: UntypedFormControl;

  public addresses$: Observable<Array<AddressResponse>>;
  public postalCodes$: Observable<Array<CityResponse>>;
  public cities$: Observable<Array<CityResponse>>;

  //patient
  public stepPatientcompleted: boolean;
  public guestPatientForm: UntypedFormGroup;

  public showBreedInput: boolean;
  public showOtherBreed: boolean;
  private patientNameInput: UntypedFormControl;
  private patientBreedInput: UntypedFormControl;
  public patients: Array<PetResponse>;

  public breeds$: Observable<Array<BreedResponse>>;
  private defaultBreeds: Array<BreedResponse>;
  protected selectedBreedId: number;
  protected selectedPatientIds: Array<number>;

  //confirm
  public confirmForm: UntypedFormGroup;

  public vet: string;
  public location: string;
  public day: string;
  public time: string;
  public appointmentType: string;
  public pets: string;

  public reasonInput: UntypedFormControl;

  //confirmation
  public companyWebsite: string;
  public hasConfirmationMessage: boolean;
  public confirmationMessage: string;

  constructor(breakpointObserver: BreakpointObserver,
              protected formBuilder: UntypedFormBuilder,
              protected storage: StorageService,
              protected auth: AuthService,
              protected service: AppointmentService,
              protected addressService: AddressService,
              protected petService: PetService,
              protected breedService: BreedService,
              protected translate: TranslateService,
              protected dialog: DialogService,
              protected err: ErrorService) {
    super(breakpointObserver);

    this.isLoggedIn = auth.isConnected && !auth.temporary;
    this.isTempLoggedIn = auth.isConnected && auth.temporary;
    this.isLockedRegistration = this.storage.vetInfo.lock_registration;
  }

  protected onInit() {
    this.isEditable = true;

    this.showCharacters = false;
    this.showOtherBreed = false;
    this.showBreedInput = false;
    this.stepAccountcompleted = false;
    this.stepPatientcompleted = false;
    this.selectedPatientIds = [];
    this.companyWebsite = this.storage.vetInfo.company_website;

    this.loadDefaultBreeds();

    if (this.isLoggedIn) {
      this.stepAccountcompleted = true;
      this.loadCustomer();
    }

    if (this.isTempLoggedIn) {
      this.stepAccountcompleted = true;
      this.loadTempCustomer();
    }

    this.accountForm = this.formBuilder.group({
      characters: new UntypedFormControl({value: '', disabled: true}),
      city: new UntypedFormControl({value: '', disabled: true}),
      country: new UntypedFormControl({value: '', disabled: true}),
      email: new UntypedFormControl({value: '', disabled: true}),
      first_name: new UntypedFormControl({value: '', disabled: true}),
      last_name: new UntypedFormControl({value: '', disabled: true}),
      number: new UntypedFormControl({value: '', disabled: true}),
      phone: new UntypedFormControl({value: '', disabled: true}),
      postal_code: new UntypedFormControl({value: '', disabled: true}),
      street: new UntypedFormControl({value: '', disabled: true})
    });

    this.charactersInput = this.formBuilder.control("", []);
    this.cityInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.countryInput = this.formBuilder.control("België", [Validators.required, Validators.minLength(3)]);
    this.numberInput = this.formBuilder.control("", [Validators.required, Validators.minLength(1)]);
    this.postalCodeInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.streetInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);

    this.guestAccountForm = this.formBuilder.group({
      characters: this.charactersInput,
      city: this.cityInput,
      country: this.countryInput,
      email: this.formBuilder.control("", [Validators.required, CustomValidators.email]),
      firstName: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      lastName: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      number: this.numberInput,
      phone: this.formBuilder.control("", [Validators.required, CustomValidators.nonStrictPhone]),
      postalCode: this.postalCodeInput,
      street: this.streetInput
    });

    this.patientNameInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);
    this.patientBreedInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);

    this.guestPatientForm = this.formBuilder.group({
      patientName: this.patientNameInput,
      breed_type: this.formBuilder.control(""),
      breed: this.patientBreedInput,
      dateOfBirth: this.formBuilder.control(""),
      gender: this.formBuilder.control("", [Validators.required, Validators.minLength(1)])
    });

    this.reasonInput = this.formBuilder.control("", [Validators.required, Validators.minLength(5)]);

    this.confirmForm = this.formBuilder.group({
      reason: this.reasonInput
    });

    if (this.storage.confirmation_text && this.storage.confirmation_text.length > 0) {
      this.hasConfirmationMessage = true;
      this.confirmationMessage = this.storage.confirmation_text;
    } else {
      this.hasConfirmationMessage = false;
      this.confirmationMessage = "";
    }

    this.addresses$ = this.streetInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findAddressess(value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.postalCodes$ = this.postalCodeInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByPostalCode(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.cities$ = this.cityInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByName(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.breeds$ = this.patientBreedInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.breedService.findBreeds(value, this.getBreedType());
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );
  }

  public handleLoginComplete() {
    if (this.auth.isConnected) {
      this.stepAccountcompleted = true;
      this.isLoggedIn = this.auth.isConnected && !this.auth.temporary;
      this.isTempLoggedIn = this.auth.isConnected && this.auth.temporary;
      if (this.isLoggedIn) {
        this.loadCustomer();
      } else {
        this.loadTempCustomer();
      }
      setTimeout(() => {
        this.stepper.next();
      });
    }
  }

  public handleRegisterComplete(): void {
    this.stepAccountcompleted = true;
    this.isLoggedIn = false;
    this.isTempLoggedIn = true;
    this.loadTempCustomer();
    setTimeout(() => {
      this.stepper.next();
    });
  }

  public handleSubmitGuestAccount(): void {
    this.service.checkExistingAccount(this.guestAccountForm.value.email).subscribe(data => {
      if (data.code === "ok") {
        this.stepAccountcompleted = true;
        setTimeout(() => {
          this.stepper.next();
        });
      } else {
        this.dialog.showError("error.account-in-use");
      }
    }, error => {
      this.err.handleError(error);
    });
  }

  public addressSelected(event: MatAutocompleteSelectedEvent): void {
    this.streetInput.patchValue(event.option.value.street);
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
    this.countryInput.patchValue(event.option.value.country);

    this.showCharacters = this.isNL(event.option.value.country);
  }

  public citySelected(event: MatAutocompleteSelectedEvent): void {
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
  }

  public countrySelected($event: MatSelectChange): void {
    this.showCharacters = $event && this.isNL($event.value);

    this.postalCodeInput.patchValue("");
    this.charactersInput.patchValue("");
    this.cityInput.patchValue("");
  }

  private loadCustomer(): void {
    this.service.findCustomer(this.storage.customerId).subscribe(data => {
      this.accountForm.patchValue(data);
      this.showCharacters = this.isNL(data.country);
    });

    this.loadPatients();
  }

  private loadTempCustomer(): void {
    this.service.findTempCustomer(this.storage.customerId).subscribe(data => {
      this.accountForm.patchValue(data);
      this.showCharacters = this.isNL(data.country);
    });
  }

  private isNL(country: string): boolean {
    return country === "Nederland";
  }

  public handleSubmitGuestPatient(): void {
    this.stepPatientcompleted = true;
    this.pets = this.patientNameInput.value;
    setTimeout(() => {
      this.stepper.next();
    });
  }

  public breedOptionSelected($event: MatRadioChange): void {
    if ($event) {
      this.showBreedInput = true;
      if ($event.value === "Dog") {
        this.showOtherBreed = false;
        this.selectedBreedId = this.defaultBreeds.find(element => element.name === "Cv").breed_id;
        this.patientBreedInput.patchValue("");
        this.patientBreedInput.setValidators([Validators.minLength(2)]);
      } else if ($event.value === "Cat") {
        this.showOtherBreed = false;
        this.selectedBreedId = this.defaultBreeds.find(element => element.name === "Fv").breed_id;
        this.patientBreedInput.patchValue("");
        this.patientBreedInput.setValidators([Validators.minLength(2)]);
      } else {
        this.showOtherBreed = true;
        this.selectedBreedId = null;
        this.patientBreedInput.patchValue("");
        this.patientBreedInput.setValidators([Validators.required, Validators.minLength(2)]);
      }
      this.patientBreedInput.updateValueAndValidity();
    }
  }

  public displayFnBreed(breed?: BreedResponse): string | undefined {
    if (breed) {
      return breed.name;
    } else {
      return undefined;
    }
  }

  public breedSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedBreedId = event.option.value.breed_id;
  }

  public getPicture(picture: any): string {
    if (picture && picture.substring(0, 4) === "data") {
      var res = picture.split("base64");
      switch (res[0]) {
        case "dataimage/jpeg":
          return "data:image/jpg;base64," + res[1];
        case "dataimage/png":
          return "data:image/png;base64," + res[1] + "mCC";
        case "dataimage/bmp":
          return "data:image/bmp;base64," + res[1];
        case "dataimage/gif":
          return "data:image/gif;base64," + res[1];
        default:
          return "data:image/png;base64," + picture;
      }
    } else {
      return "data:image/png;base64," + picture;
    }
  }

  public getGender(gender: string): string {
    return this.translate.instant("pet.genderType." + gender);
  }

  public handleSelectPet($event: MatCheckboxChange, id: number, name: string): void {
    if ($event.checked) {
      if (this.selectedPatientIds.length > 0) {
        let request: MultipleAppointmentsRequest = new MultipleAppointmentsRequest();
        request.number_of_appointments = this.selectedPatientIds.length + 1;
        request.practice = this.selectedPractice;
        request.start_date = this.start;
        request.vet_id = this.vetId;
        request.appointment_type = this.appointmentType;

        this.service.checkMultipleAppointments(request).subscribe(data => {
          this.selectedPatientIds.push(id);
          this.pets = this.pets + ", " + name;
        }, error => {
          if (error.status === 409) {
            this.dialog.showError("error.multiple-appointments");
          } else if (error.status === 412) {
            this.dialog.showError("error.number-of-patients-appointments");
          } else {
            this.err.handleError(error);
          }
        });
      } else {
        this.selectedPatientIds.push(id);
        this.pets = name;
      }
    } else {
      this.selectedPatientIds = this.selectedPatientIds.filter(value => {
        return value !== id
      });
      this.removeNameFromPets(name);
    }
    this.stepPatientcompleted = this.selectedPatientIds.length > 0;
  }

  public handleCreatePet(): void {
    this.dialog.open(PetComponent, {
      width: "95vw",
      maxWidth: "500px",
      disableClose: true,
      autoFocus: true,
      data: {
        pet: null
      }
    }).afterClosed().subscribe(data => {
      this.loadPatients();
    });
  }

  private loadPatients(): void {
    this.petService.findPets(this.storage.customerId).subscribe(data => {
      this.patients = data;
    });
  }

  private loadDefaultBreeds(): void {
    this.breedService.findDefaultBreeds().subscribe(data => {
      this.defaultBreeds = data;
    });
  }

  private getBreedType(): string {
    return this.guestPatientForm.value.breed_type;
  }

  private removeNameFromPets(nameToRemove: string): void {
    let names: Array<string> = this.pets.split(", ");
    let removedNames: Array<string> = names.filter(name => {
      return name !== nameToRemove
    });
    this.pets = removedNames.join(", ");
  }

  protected formatTime(start: Date, end: Date): string {
    let startTime: string = formatDate(start, "HH:mm", "nl-BE");
    let endTime: string = formatDate(end, "HH:mm", "nl-BE");

    return startTime + " - " + endTime;
  }
}
