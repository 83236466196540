import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Language} from "@app/model/language";
import {AuthService} from "@app/core/auth/auth.service";
import {StorageService} from "@app/core/storage/storage.service";
import {TranslateService} from "@ngx-translate/core";
import {DialogService} from "@app/core/dialog/dialog.service";
import {LoginService} from "@app/widget/login/login.service";
import {CustomValidators} from "@app/shared/validators/custom.validator";
import {ErrorService} from "@app/core/error/error.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() onLoginComplete: EventEmitter<void> = new EventEmitter();

  public loginForm: UntypedFormGroup;
  public userNameLoginInput: UntypedFormControl;
  public passwordLoginInput: UntypedFormControl;

  public hide:boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private auth: AuthService,
              private storage: StorageService,
              private translate: TranslateService,
              private dialog: DialogService,
              private err: ErrorService,
              private service: LoginService) {
  }

  ngOnInit() {
    this.userNameLoginInput = this.formBuilder.control("", [Validators.required, CustomValidators.email]);
    this.passwordLoginInput = this.formBuilder.control("", [Validators.required]);
    this.loginForm = this.formBuilder.group({
      username: this.userNameLoginInput,
      password: this.passwordLoginInput
    });

    this.hide = true;
  }

  public handleSubmitLogin(): void {
    if (this.loginForm.valid) {
      this.service.login(this.userNameLoginInput.value, this.passwordLoginInput.value).subscribe(data => {
        if (data.code === "ok") {
          let lang: string = this.getLanguage(data.language);
          this.storage.language = Language[lang.toUpperCase()];
          this.translate.use(lang);

          this.storage.customerId = data.customer_id;
          this.storage.customerName = data.display_name;
          this.storage.temporary = data.temporary;
          this.dialog.showDialog("message.login-success");
          this.auth.isConnected = true;
          this.auth.temporary = data.temporary;
          this.auth.handleLogin();
          this.onLoginComplete.emit();
        } else if(data.code === "blocked") {
          this.dialog.showError("error.user-blocked");
        } else if(data.code === "wrong_pwd") {
          this.dialog.showError("error.wrong-pwd");
        } else {
          this.dialog.showError("error.unknown-email");
        }
      }, error => {
        this.err.handleError(error);
      });
    }
  }

  public handleForgotPassword(): void {
    this.dialog.showDialogWithAction("message.reset-password-confirm", "auth.login.action-reset-password").afterClosed().subscribe(data => {
      if (data) {
        this.service.resetPassword(this.userNameLoginInput.value).subscribe(data => {
          this.dialog.showDialog("message.reset-password");
          this.onLoginComplete.emit();
        }, error => {
          if (error.status === 401) {
            this.dialog.showError("error.unknown-email");
          } else {
            this.err.handleError(error);
          }
        });
      }
    });
  }

  private getLanguage(lang: string): string {
    if (lang) {
      switch (lang.toLowerCase()) {
        case "nederlands":
          return "nl";
        case "engels":
          return "en";
        case "frans":
          return "fr";
        case "duits":
          return "de";
        default:
          return "nl";
      }
    }
    return "nl";
  }
}
