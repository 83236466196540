import {Component, Inject, OnInit} from '@angular/core';
import {CustomerAccountResponse} from "@app/secured/my-data/model/customeraccount.response";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyDataService} from "@app/secured/my-data/my-data.service";
import {ChangePasswordComponent} from "@app/secured/my-data/change-password/change-password.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {AccountComponent} from "@app/secured/my-data/account/account.component";

export interface MasterAccountData {
  email: string;
}

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  public accounts: Array<CustomerAccountResponse>;
  public masterEmail: string;

  constructor(private dialogRef: MatDialogRef<AccountsComponent>,
              private storage: StorageService,
              private dialog: DialogService,
              private service: MyDataService,
              @Inject(MAT_DIALOG_DATA) private data: MasterAccountData) {
  }

  ngOnInit() {
    this.masterEmail = this.data.email;
    this.load();
  }

  public handleChangePassword(email: string): void {
    this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      autoFocus: true,
      minWidth: 300,
      data: {
        email: email
      }
    });
  }

  public handleAddAccount(): void {
    this.dialog.open(AccountComponent, {
      disableClose: true,
      autoFocus: true,
      minWidth: 300
    }).afterClosed().subscribe(data => {
      this.load();
    });
  }

  public handleDelete(id: number): void {
    this.service.deleteAccount(id).subscribe(data => {
      this.dialog.showDialog("message.delete-account-success");
      this.load();
    });
  }

  private load(): void {
    this.service.findAccounts(this.storage.customerId).subscribe(data => {
      this.accounts = data;
    }, error => {
      this.dialog.showError("error.server");
    });
  }

}
