<form [formGroup]="form" (ngSubmit)="handleSubmit()">

  <h1 mat-dialog-title>{{'mydata.account.title' | translate}}</h1>

  <mat-dialog-content class="account">
    <mat-form-field>
      <input matInput type="email" placeholder="{{'common.email' | translate}}" formControlName="email" required>
      <mat-error *ngIf="form.get('email').errors">
        <span *ngIf="form.get('email').errors.required">{{'error.required.email' | translate}}</span>
        <span *ngIf="form.get('email').errors.email">{{'error.format.email' | translate}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" placeholder="{{'mydata.change-password.password' | translate}}" formControlName="password" required>
      <mat-error *ngIf="form.get('password').errors && form.get('password').errors.required">
        <span>{{'error.required.password' | translate}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" placeholder="{{'mydata.change-password.confirmPassword' | translate}}" formControlName="confirm_password" required>
      <mat-error *ngIf="form.get('confirm_password').errors">
        <span *ngIf="form.get('confirm_password').errors.required">{{'error.required.confirmPassword' | translate}}</span>
        <span *ngIf="form.get('confirm_password').errors.ConfirmPassword">{{'error.passwordMatch' | translate}}</span>
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">{{'mydata.account.action-submit' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>

</form>
