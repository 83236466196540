import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {environment} from "@env/environment";
import {AppointmentRequest} from "@app/public/appointment/model/appointment.request";
import {Observable} from "rxjs";
import {MultipleAppointmentsRequest} from "@app/public/appointment/model/multipleappointments.request";
import {MultipleAppointmentResponse} from "@app/public/appointment/model/multipleappointment.response";
import {map} from "rxjs/operators";
import {PracticeResponse} from "@app/public/appointment/model/practice.response";
import {AgendaConfigurationResponse} from "@app/public/appointment/model/agendaconfiguration.response";
import {ReservationResponse} from "@app/public/appointment/model/reservation.response";
import {formatDate} from "@angular/common";
import {CodedResponse} from "@app/model/coded.response";
import {CustomerResponse} from "@app/model/customer.response";
import {AppointmentTypeResponse} from "@app/public/appointment/model/appointmenttype.response";
import {AppointmentTypeRequest} from "@app/public/appointment/model/appointmenttype.request";
import {AppointmentTypePracticeResponse} from "@app/public/appointment/model/appointmenttypepractice.response";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public getAgendaPractices(): Observable<Array<PracticeResponse>> {
    return this.httpClient.get<PracticeResponseObject>(`${environment.baseUrl}/practices`, this.security.getHttpOptions())
      .pipe(
        map((response: PracticeResponseObject) => {
          return response.practices;
        }));
  }

  public getAgendaConfiguration(practice: number, vetId: number, start: Date): Observable<AgendaConfigurationResponse> {
    if (start) {
      let startTime: string = formatDate(start, "yyyy-MM-dd", "nl-BE");
      return this.httpClient.get<AgendaConfigurationResponse>(`${environment.baseUrl}/configuration?practice=${practice}&vet_id=${vetId}&date=${startTime}`, this.security.getHttpOptions());
    } else {
      return this.httpClient.get<AgendaConfigurationResponse>(`${environment.baseUrl}/configuration?practice=${practice}&vet_id=${vetId}`, this.security.getHttpOptions());
    }
  }

  public getDefaultAgendaConfiguration(practice: number): Observable<AgendaConfigurationResponse> {
    return this.httpClient.get<AgendaConfigurationResponse>(`${environment.baseUrl}/configuration-default?practice=${practice}`, this.security.getHttpOptions());
  }

  public getAppointmentTypes(practice: number, vetId: number): Observable<Array<AppointmentTypeResponse>> {
    return this.httpClient.get<AppointmentTypeResponseObject>(`${environment.baseUrl}/appointmenttypes?practice=${practice}&vet_id=${vetId}`, this.security.getHttpOptions())
      .pipe(
        map((response: AppointmentTypeResponseObject) => {
          return response.data;
        }));
  }

  public getAppointmentTypesGrouped(): Observable<Array<AppointmentTypePracticeResponse>> {
    return this.httpClient.get<AppointmentTypePracticeResponseObject>(`${environment.baseUrl}/appointmenttypes-grouped`, this.security.getHttpOptions())
      .pipe(
        map((response: AppointmentTypePracticeResponseObject) => {
          return response.data;
        }));
  }

  public getReservationsForWeek(practice: number, vetId: number, view: string, start: Date, end: Date, editId: number): Observable<Array<ReservationResponse>> {
    let startTime: string = formatDate(start, "yyyy-MM-dd", "nl-BE");
    let endTime: string = formatDate(end, "yyyy-MM-dd", "nl-BE");

    return this.httpClient.get<ReservationResponseObject>(
      `${environment.baseUrl}/reservations?practice=${practice}&vet_id=${vetId}&view=${view}&start=${startTime}&end=${endTime}&edit_id=${editId}`,
      this.security.getHttpOptions())
      .pipe(
        map((response: ReservationResponseObject) => {
          return response.data;
        }));
  }

  public getAvailabilitiesForWeek(practice: number, type: string, start: Date, end: Date): Observable<Array<ReservationResponse>> {
    let startTime: string = formatDate(start, "yyyy-MM-dd", "nl-BE");
    let endTime: string = formatDate(end, "yyyy-MM-dd", "nl-BE");

    return this.httpClient.get<ReservationResponseObject>(
      `${environment.baseUrl}/availabilities?appointment_type=${type}&practice=${practice}&start=${startTime}&end=${endTime}`,
      this.security.getHttpOptions())
      .pipe(
        map((response: ReservationResponseObject) => {
          return response.data;
        }));
  }

  public checkAppointmentTypeAvailable(request: AppointmentTypeRequest): Observable<MultipleAppointmentResponse> {
    return this.httpClient.post<MultipleAppointmentResponse>(`${environment.baseUrl}/appointment/type`, request, this.security.getHttpOptions());
  }

  public findCustomer(customerId: number): Observable<CustomerResponse> {
    return this.httpClient.get<CustomerResponse>(`${environment.baseUrl}/customer/${customerId}`, this.security.getHttpOptions());
  }

  public findTempCustomer(customerId: number): Observable<CustomerResponse> {
    return this.httpClient.get<CustomerResponse>(`${environment.baseUrl}/customer/temp/${customerId}`, this.security.getHttpOptions());
  }

  public checkExistingAccount(email: string): Observable<CodedResponse> {
    return this.httpClient.get<CodedResponse>(`${environment.baseUrl}/customer/temp/existing?email=${email}`, this.security.getHttpOptions());
  }

  public createAppointment(request: AppointmentRequest): Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUrl}/appointment`, request, this.security.getHttpOptions());
  }

  public updateAppointment(id: number, request: AppointmentRequest): Observable<CodedResponse> {
    return this.httpClient.put<CodedResponse>(`${environment.baseUrl}/appointment/${id}`, request, this.security.getHttpOptions());
  }

  public checkMultipleAppointments(request: MultipleAppointmentsRequest): Observable<Array<MultipleAppointmentResponse>> {
    return this.httpClient.post<MultipleAppointmentResponseObject>(`${environment.baseUrl}/appointment/multiple`, request, this.security.getHttpOptions())
      .pipe(
        map((response: MultipleAppointmentResponseObject) => {
          return response.list;
        }));
  }
}

export interface PracticeResponseObject {
  practices: Array<PracticeResponse>;
}

export interface AppointmentTypeResponseObject {
  data: Array<AppointmentTypeResponse>;
}

export interface AppointmentTypePracticeResponseObject {
  data: Array<AppointmentTypePracticeResponse>;
}

export interface ReservationResponseObject {
  data: Array<ReservationResponse>;
}

export interface MultipleAppointmentResponseObject {
  list: Array<MultipleAppointmentResponse>;
}
