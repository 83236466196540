import {Component, OnInit, ViewChild} from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {StorageService} from "@app/core/storage/storage.service";
import {NavigationService} from "@app/core/navigation/navigation.service";
import {NavigationEnd, Router} from "@angular/router";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends ResponsiveComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

  public nav: Array<NavBarItem> = [];
  public isVisibleTitlebar: boolean = true;
  public version: string;

  public hasMessage: boolean;
  public messageTitle: string;
  public messageContent: string;

  constructor(breakpointObserver: BreakpointObserver,
              private service: NavigationService,
              private storage: StorageService,
              private translate: TranslateService,
              private router: Router) {
    super(breakpointObserver);

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && this.breakpointObserver.isMatched(Breakpoints.Handset)) {
        service.close();
      }
    })
  }

  ngOnInit() {
    this.service.setSidenav(this.sidenav);
    this.version = this.storage.version;

    if (this.storage.info_title || this.storage.info_text) {
      this.hasMessage = true;
      this.messageTitle = this.storage.info_title;
      this.messageContent = this.storage.info_text;
    } else {
      this.hasMessage = false;
    }

    this.constructNavBar();

    this.service.titleBarVisibility$.subscribe(
      visibility => {
        this.isVisibleTitlebar = visibility;
      });

    this.translate.onLangChange.subscribe(data => {
      if (this.storage.info_title || this.storage.info_text) {
        this.hasMessage = true;
        this.messageTitle = this.storage.info_title;
        this.messageContent = this.storage.info_text;
      } else {
        this.hasMessage = false;
      }

      this.constructNavBar();
    });
  }

  public handleClick(): void {
    this.service.handleClicked();
  }

  private constructNavBar() {
    let items: Array<NavBarItem> = [];
    items.push(new NavBarItem(this.translate.instant("navigation.menu.mypractice"), "/home"));
    items.push(new NavBarItem(this.translate.instant("navigation.menu.mydata"), "/mydata"));
    items.push(new NavBarItem(this.translate.instant("navigation.menu.mypets"), "/mypets"));
    if(!this.storage.vetInfo.hide_online_agenda) {
      items.push(new NavBarItem(this.translate.instant("navigation.menu.myappointments"), "/myappointments"));
      items.push(new NavBarItem(this.translate.instant("navigation.menu.calendar"), "/calendar"));
      if (this.storage.vetInfo.show_orders) {
        items.push(new NavBarItem(this.translate.instant("navigation.menu.myorders"), "/myorders"));
      }
    }
    if(this.storage.vetInfo.use_alternate_agenda) {
      items.push(new NavBarItem(this.translate.instant("navigation.menu.myappointments"), "/myappointments"));
      items.push(new NavBarItem(this.translate.instant("navigation.menu.calendar"), "/wizard"));
      if (this.storage.vetInfo.show_orders) {
        items.push(new NavBarItem(this.translate.instant("navigation.menu.myorders"), "/myorders"));
      }
    }
    if (this.storage.vetInfo.show_send_message) {
      items.push(new NavBarItem(this.translate.instant("navigation.menu.sendmessage"), "/message"));
    }
    this.nav = items;
  }
}

export class NavBarItem {
  public title: string;
  public path: string;

  constructor(title: string, path: string) {
    this.title = title;
    this.path = path;
  }
}
