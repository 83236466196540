import {NgModule} from '@angular/core';
import {MyAppointmentsComponent} from './my-appointments/my-appointments.component';
import {SharedModule} from "@app/shared/shared.module";

@NgModule({
  declarations: [
    MyAppointmentsComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    MyAppointmentsComponent
  ]
})
export class MyAppointmentsModule {
}
