import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StorageService} from "@app/core/storage/storage.service";
import {CalendarService} from "@app/public/appointment/calendar/calendar.service";
import {formatDate} from "@angular/common";
import {AuthService} from "@app/core/auth/auth.service";
import {AddressService} from "@app/shared/services/address.service";
import {BreedService} from "@app/shared/services/breed.service";
import {AppointmentService} from "@app/public/appointment/appointment.service";
import {AppointmentRequest} from "@app/public/appointment/model/appointment.request";
import {DialogService} from "@app/core/dialog/dialog.service";
import {PetService} from "@app/shared/services/pet.service";
import {TranslateService} from "@ngx-translate/core";
import {MultipleAppointmentsRequest} from "@app/public/appointment/model/multipleappointments.request";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ErrorService} from "@app/core/error/error.service";
import {AppointmentWizard} from "@app/public/appointment/appointment.wizard";

export interface AppointmentData {
  start: Date;
  end: Date;
  vetId: number;
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent extends AppointmentWizard implements OnInit {

  public title: string;

  constructor(breakpointObserver: BreakpointObserver,
              private dialogRef: MatDialogRef<AppointmentComponent>,
              formBuilder: UntypedFormBuilder,
              storage: StorageService,
              auth: AuthService,
              dialog: DialogService,
              translate: TranslateService,
              service: AppointmentService,
              addressService: AddressService,
              breedService: BreedService,
              petService: PetService,
              private calendarService: CalendarService,
              err: ErrorService,
              @Inject(MAT_DIALOG_DATA) private data: AppointmentData) {
    super(breakpointObserver, formBuilder, storage, auth, service, addressService, petService, breedService, translate, dialog, err);

    this.vetId = data.vetId;
    this.selectedPractice = calendarService.selectedPractice;
    this.vet = calendarService.getVetName(data.vetId);
    this.location = calendarService.selectedPracticeAddress;
    this.day = formatDate(data.start, "EEEE dd MMMM", this.storage.locale);
    this.time = this.formatTime(data.start, data.end);
    this.duration = null;
    this.start = data.start;
    this.end = data.end;

    this.title = translate.instant("calendar.appointment.title-with-name") + this.vet;
  }

  ngOnInit() {
    this.isHandset$.subscribe(mobile => {
      if (mobile && this.isLockedRegistration) {
        this.dialog.showDialog("message.registration-locked");
      }
    });
    super.onInit();
  }

  public handleClose() {
    if (this.isEditable) {
      this.dialog.showDialogWithAction("message.close-appointment", "calendar.appointment.action-continue").afterClosed().subscribe(data => {
        if (!data) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  public handleSubmit(): void {
    if (this.isTempLoggedIn) {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.remark = this.reasonInput.value;
      request.temp_customer_id = this.storage.customerId;
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    } else if (this.isLoggedIn) {
      let request: MultipleAppointmentsRequest = new MultipleAppointmentsRequest();
      request.number_of_appointments = this.selectedPatientIds.length;
      request.practice = this.selectedPractice;
      request.start_date = this.start;
      request.vet_id = this.vetId;
      request.appointment_type = null;

      this.service.checkMultipleAppointments(request).subscribe(data => {
        let itemsProcessed: number = 0;
        for (let i: number = 0; i < this.selectedPatientIds.length; i++) {
          let patientId: number = this.selectedPatientIds[i];
          let start: Date = data[i].start;
          let end: Date = data[i].end;

          let request: AppointmentRequest = new AppointmentRequest();
          request.vet_id = this.vetId;
          request.practice = this.selectedPractice;
          request.date = start;
          request.start = formatDate(start, "HH:mm", "nl-BE");
          request.end = formatDate(end, "HH:mm", "nl-BE");
          request.remark = this.reasonInput.value;
          request.customer_id = this.storage.customerId;
          request.patient_id = patientId;

          this.service.createAppointment(request).subscribe(data => {
            itemsProcessed++;
            if (itemsProcessed === this.selectedPatientIds.length) {
              this.stepper.next();
              this.isEditable = false;
            }
          }, error => {
            if (error.status === 409) {
              this.dialog.showError("error.account-in-use");
            } else if (error.status === 406) {
              this.dialog.showError("error.same-day-appointment");
            } else if (error.status === 412) {
              this.dialog.showError("error.no-user-information");
            } else {
              this.err.handleError(error);
            }
          });
        }
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.multiple-appointments");
        } else if (error.status === 412) {
          this.dialog.showError("error.number-of-patients-appointments");
        } else {
          this.err.handleError(error);
        }
      });
    } else {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.remark = this.reasonInput.value;
      request.last_name = this.guestAccountForm.value.lastName;
      request.first_name = this.guestAccountForm.value.firstName;
      request.phone = this.guestAccountForm.value.phone;
      request.email = this.guestAccountForm.value.email;
      request.street = this.guestAccountForm.value.street;
      request.number = this.guestAccountForm.value.number;
      request.city = this.guestAccountForm.value.city;
      request.postal_code = this.guestAccountForm.value.postalCode;
      request.characters = this.guestAccountForm.value.characters;
      request.country = this.guestAccountForm.value.country;
      request.language = this.storage.language.toString();
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    }
  }
}
