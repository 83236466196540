import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import {StorageService} from "@app/core/storage/storage.service";
import {NavigationService} from "@app/core/navigation/navigation.service";
import {Router} from "@angular/router";
import {AuthService} from "@app/core/auth/auth.service";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {TranslateService} from "@ngx-translate/core";
import {Language} from "@app/model/language";

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent extends ResponsiveComponent implements OnInit {
  @Output() onToggle: EventEmitter<void> = new EventEmitter();

  public logo: string;
  public companyName: string;
  public companyTitle: string;

  public isLoggedIn: boolean;
  public customerName: string;
  public selectedLanguage: string;

  constructor(breakpointObserver: BreakpointObserver,
              private service: NavigationService,
              private storage: StorageService,
              private auth: AuthService,
              private translate: TranslateService,
              private router: Router) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.logo = "data:image/png;base64," + this.storage.vetInfo.logo;
    this.companyName = this.storage.vetInfo.company_name;
    this.companyTitle = this.storage.vetInfo.company_title;

    this.isLoggedIn = false;

    this.selectedLanguage = this.storage.language;

    this.auth.login$.subscribe(
      login => {
        this.isLoggedIn = login;
        this.customerName = this.storage.customerName;
      });
  }

  public toggle(): void {
    this.onToggle.emit();
    this.service.toggle();
  }

  public handleLogin(): void {
    this.router.navigate(["login"]);
  }

  public handleLogout(): void {
    this.isLoggedIn = false;
    this.auth.isConnected = false;
    this.storage.customerId = null;
    this.storage.customerName = null;
    this.router.navigate(["login"]);
  }

  public handleSelectLanguage(language: string): void {
    //this.selectedLanguage = language.toUpperCase();
    this.storage.language = Language[this.selectedLanguage];
    this.translate.use(this.selectedLanguage.toLowerCase());
  }
}
