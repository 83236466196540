import {NgModule} from '@angular/core';
import {MyPetsComponent} from './my-pets/my-pets.component';
import {SharedModule} from "@app/shared/shared.module";
import {PetComponent} from './pet/pet.component';
import {DeletePetComponent} from './delete-pet/delete-pet.component';

@NgModule({
    declarations: [
        MyPetsComponent,
        PetComponent,
        DeletePetComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        MyPetsComponent
    ]
})
export class MyPetsModule {
}
