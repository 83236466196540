import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {MyDataService} from "@app/secured/my-data/my-data.service";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ConfirmPasswordValidator} from "@app/shared/validators/confirm-password.validator";

export interface ChangePasswordData {
  email: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public form: UntypedFormGroup;

  public hide1:boolean;
  public hide2:boolean;

  private passwordInput: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<ChangePasswordComponent>,
              private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private service: MyDataService,
              @Inject(MAT_DIALOG_DATA) private data: ChangePasswordData) {
  }

  ngOnInit() {
    this.hide1 = true;
    this.hide2 = true;

    this.passwordInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);

    this.form = this.formBuilder.group({
      password: this.passwordInput,
      confirm_password: this.formBuilder.control("", [Validators.required, Validators.minLength(3)])
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  public handleSubmit(): void {
    if (this.form.valid) {
      this.service.updatePassword(this.storage.customerId, this.data.email, this.passwordInput.value).subscribe(data => {
        this.dialogRef.close();
        this.dialog.showDialog("message.change-password-success");
      }, error => {
        this.dialogRef.close();
        this.dialog.showError("error.server");
      });
    }
  }

}
