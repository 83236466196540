import {Observable} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, startWith} from "rxjs/operators";
import {Component} from "@angular/core";

@Component({
  template: ''
})
export class ResponsiveComponent {
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: true}),
      map(result => result.matches)
    );

  public isNotHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: true}),
      map(result => !result.matches)
    );

  public cols: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: true}),
      map(result => result.matches ? 6 : 12)
    );

  public cs_9_12$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 12 : 9)
    );

  public cs_6_12$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 12 : 6)
    );

  public cs_6_8$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 8 : 6)
    );

  public cs_4_12$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 12 : 4)
    );

  public cs_3_12$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 12 : 3)
    );

  public cs_3_10$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 10 : 3)
    );

  public cs_1_2$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 2 : 1)
    );

  public cs_2_10$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 10 : 2)
    );

  public cs_2_4$: Observable<number> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      startWith({matches: false}),
      map(result => result.matches ? 4 : 2)
    );

  constructor(protected breakpointObserver: BreakpointObserver) {
  }
}
