import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {CodedResponse} from "@app/model/coded.response";
import {Order} from "@app/secured/my-orders/model/order.model";
import {OrderHistory} from "@app/secured/my-orders/model/order-history";
import {Product} from "@app/secured/my-orders/model/product";

@Injectable({
  providedIn: 'root'
})
export class MyOrdersService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findProducts(): Observable<Array<Product>> {
    return this.httpClient.get<ProductListResponse>(`${environment.baseUrl}/product/list`, this.security.getHttpOptions())
      .pipe(
        map((response: ProductListResponse) => {
          return response.data.map(value => this.assembleFromProduct(value));
        }));
  }

  public findOrders(customerId: number): Observable<Array<OrderHistory>> {
    return this.httpClient.get<CustomerOrderResponseObject>(`${environment.baseUrl}/customer/${customerId}/order/list`, this.security.getHttpOptions())
      .pipe(
        map((response: CustomerOrderResponseObject) => {
          let sales: Array<OrderHistory> = response.sales.map(value => {
            return this.assembleFromSale(value);
          });
          let orders: Array<OrderHistory> = response.orders.map(value => {
            return this.assembleFromOrder(value);
          });
          let history: Array<OrderHistory> = sales.concat(orders);
          history.sort((a, b) => {
            if (a.date < b.date) {
              return -1;
            } else if (a.date > b.date) {
              return 1;
            } else {
              return 0;
            }
          });
          return history;
        }));
  }

  public createOrder(customerId: number, data: Order): Observable<CodedResponse> {
    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/customer/${customerId}/order`, data, this.security.getHttpOptions());
  }

  public deleteOrder(orderId: number): Observable<CodedResponse> {
    return this.httpClient.delete<CodedResponse>(`${environment.baseUrl}/customer/order/${orderId}`, this.security.getHttpOptions());
  }

  private assembleFromSale(value: CustomerSaleResponse): OrderHistory {
    let order: OrderHistory = new OrderHistory();

    order.date = value.date
    order.patientName = value.patient_name;
    order.productId = value.product_id
    order.productName = value.product_name;
    order.quantity = value.quantity;
    order.unit = value.unit;

    return order;
  }

  private assembleFromOrder(value: CustomerOrderResponse): OrderHistory {
    let order: OrderHistory = new OrderHistory();

    order.id = value.id;
    order.date = value.date
    order.patientName = value.patient_name;
    order.productId = value.product_id
    order.productName = value.product_name;
    order.quantity = value.quantity;
    order.unit = value.unit;

    return order;
  }

  private assembleFromProduct(value: ProductResponse): Product {
    let product: Product = new Product();

    product.product_id = value.product_id;
    product.product_name = value.product_name;
    product.unit = value.unit;

    return product;
  }
}

interface CustomerOrderResponseObject {
  sales: Array<CustomerSaleResponse>;
  orders: Array<CustomerOrderResponse>;
}

interface CustomerSaleResponse {
  date: Date;
  patient_name: string;
  product_id: string;
  product_name: string;
  quantity: number;
  unit: string;
}

interface CustomerOrderResponse {
  id: number;
  date: Date;
  patient_name: string;
  product_id: string;
  product_name: string;
  quantity: number;
  unit: string;
}

interface ProductListResponse {
  data: Array<ProductResponse>;
}

interface ProductResponse {
  product_id: string;
  product_name: string;
  unit: string;
}
