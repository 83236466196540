import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {animate, group, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "@app/core/auth/auth.service";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({height: '*', opacity: 0})),
      transition(':leave', [
        style({height: '*', opacity: 1}),

        group([
          animate(300, style({height: 0})),
          animate('200ms ease-in-out', style({'opacity': '0'}))
        ])

      ]),
      transition(':enter', [
        style({height: '0', opacity: 0}),

        group([
          animate(300, style({height: '*'})),
          animate('400ms ease-in-out', style({'opacity': '1'}))
        ])

      ])
    ])
  ]
})

export class LoginFormComponent implements OnInit, AfterViewInit {

  public disableAnimations: boolean = true;

  public loginFormVisible: boolean;
  public registerFormVisible: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private auth: AuthService,
              private storage: StorageService,
              private dialog: DialogService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loginFormVisible = true;
    this.registerFormVisible = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.disableAnimations = false;
    });
  }

  public handleLogin(): void {
    this.loginFormVisible = true;
    this.registerFormVisible = false;
  }

  public handleRegister(): void {
    if (this.storage.vetInfo.lock_registration) {
      this.dialog.showDialog("message.registration-locked");
    } else {
      this.loginFormVisible = false;
      this.registerFormVisible = true;
    }
  }

  public handleLoginComplete(): void {
    if (this.auth.path && this.auth.path !== "login") {
      this.router.navigate([this.auth.path]);
    } else {
      this.router.navigate(["home"]);
    }
  }

  public handleRegisterComplete(): void {
    this.router.navigate(["home"]);
  }
}
