import {NgModule} from '@angular/core';
import {SendMessageComponent} from './send-message/send-message.component';
import {SharedModule} from "@app/shared/shared.module";

@NgModule({
  declarations: [
    SendMessageComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    SendMessageComponent
  ]
})
export class SendMessageModule {
}
