import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {HomeService} from "@app/public/home/home.service";
import {PracticeInfoBusinessHourResponse, PracticeInfoResponse} from "@app/public/home/model/practiceinfo.response";
import {MapComponent} from "@app/public/home/map/map.component";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Language} from "@app/model/language";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ResponsiveComponent implements OnInit {
  @ViewChild('pic1') public pict1: ElementRef;
  @ViewChild('pic2') public pict2: ElementRef;

  public logo: string;

  public picture1: string;
  public isPicture1Hidden: boolean;
  public picture2: string;
  public isPicture2Hidden: boolean;

  public showAddress: boolean;
  public addressLine1: string;
  public addressLine2: string;
  public informationTitle: string;
  public informationBody: string;

  public infoMessage1Title: string;
  public infoMessage1Body: string;
  public infoMessage2Title: string;
  public infoMessage2Body: string;

  public showBusinessHours: boolean;
  public businessHours: Array<BusinessHourType>;

  private lat: number;
  private lng: number;

  public hasMessage: boolean;
  public messageTitle: string;
  public messageContent: string;
  public pictureStyle1: string;
  public pictureStyle2: string;

  private info: PracticeInfoResponse;

  constructor(breakpointObserver: BreakpointObserver,
              private service: HomeService,
              private storage: StorageService,
              private dialog: DialogService,
              private translate: TranslateService) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.logo = "data:image/png;base64," + this.storage.vetInfo.logo;

    if (this.storage.info_title || this.storage.info_text) {
      this.hasMessage = true;
      this.messageTitle = this.storage.info_title;
      this.messageContent = this.storage.info_text;
    } else {
      this.hasMessage = false;
    }

    this.service.getPracticeInfo().subscribe(data => {
      this.info = data;

      this.setPicture1(data.picture1);
      this.setPicture2(data.picture2);

      if (data.main_address) {
        this.showAddress = true;
        this.addressLine1 = data.main_address.split(',')[0];
        this.addressLine2 = data.main_address.split(',')[1];
      } else {
        this.showAddress = false;
      }

      if (data.business_hours) {
        this.showBusinessHours = true;
        this.setBusinessHours(data.business_hours);
      } else {
        this.showBusinessHours = false;
      }

      this.lat = data.lat;
      this.lng = data.lng;

      this.setInfo();
    }, (error) => {
      this.dialog.showError("error.server");
    });

    this.translate.onLangChange.subscribe(data => {
      if (this.storage.info_title || this.storage.info_text) {
        this.hasMessage = true;
        this.messageTitle = this.storage.info_title;
        this.messageContent = this.storage.info_text;
      } else {
        this.hasMessage = false;
      }

      this.setInfo();
    });
  }

  private setInfo(): void {
    this.informationTitle = this.getForLanguage(this.info.title_nl, this.info.title_en, this.info.title_fr, this.info.title_de);
    this.informationBody = this.getForLanguage(this.info.info_nl, this.info.info_en, this.info.info_fr, this.info.info_de);

    if (this.info.messages.length > 0) {
      let message = this.info.messages[0];
      this.infoMessage1Title = this.getForLanguage(message.title_nl, message.title_en, message.title_fr, message.title_de);
      this.infoMessage1Body = this.getForLanguage(message.message_nl, message.message_en, message.message_fr, message.message_de);
    }
    if (this.info.messages.length > 1) {
      let message = this.info.messages[1];
      this.infoMessage2Title = this.getForLanguage(message.title_nl, message.title_en, message.title_fr, message.title_de);
      this.infoMessage2Body = this.getForLanguage(message.message_nl, message.message_en, message.message_fr, message.message_de);
    }
  }

  private getForLanguage(nl: string, en: string, fr: string, de: string): string {
    switch (this.storage.language) {
      case Language.FR:
        return fr ? fr : nl;
      case Language.EN:
        return en ? en : nl;
      case Language.DE:
        return de ? de : nl;
      default:
        return nl;
    }
  }

  public openMap(): void {
    this.dialog.open(MapComponent, {
      //minWidth: 800,
      minWidth: "95vw",
      data: {
        lat: this.lat,
        lng: this.lng
      }
    });
  }

  private setPicture1(picture: any) {
    if (picture) {
      this.picture1 = "data:image/png;base64," + picture;
      this.isPicture1Hidden = false;

      setTimeout(() => {
        if (this.pict1.nativeElement.height > this.pict1.nativeElement.width) {
          this.pictureStyle1 = "picture-portrait";
        } else {
          this.pictureStyle1 = "picture-landscape";
        }
      });

    } else {
      this.isPicture1Hidden = true;
    }
  }

  private setPicture2(picture: any) {
    if (picture) {
      this.picture2 = "data:image/png;base64," + picture;
      this.isPicture2Hidden = false;

      setTimeout(() => {
        if (this.pict2.nativeElement.height > this.pict2.nativeElement.width) {
          this.pictureStyle2 = "picture-portrait";
        } else {
          this.pictureStyle2 = "picture-landscape";
        }
      });

    } else {
      this.isPicture2Hidden = true;
    }
  }

  private setBusinessHours(business_hours: Array<PracticeInfoBusinessHourResponse>) {
    let arr: Array<BusinessHourType> = [];

    moment.locale('nl-be');

    let type: BusinessHourType = null;
    let day: BusinessHourDay = null;

    for (let bs of business_hours) {
      if (type === null || type.type !== bs.type) {
        type = new BusinessHourType();
        type.type = bs.type;
        type.days = [];
        arr.push(type);
      }
      if (day == null || day.dayCode !== bs.day) {
        day = new BusinessHourDay();
        day.dayCode = bs.day;
        day.day = moment().isoWeekday(bs.day).format("dddd");
        day.hours = [];
        type.days.push(day);
      }
      let hour: BusinessHour = new BusinessHour();
      hour.start = bs.start;
      hour.end = bs.end;
      day.hours.push(hour);
    }

    this.businessHours = arr;
  }
}

class BusinessHour {
  public start: string;
  public end: string;
}

class BusinessHourDay {
  public dayCode: number;
  public day: string;
  public hours: Array<BusinessHour>;
}

class BusinessHourType {
  public type: string;
  public days: Array<BusinessHourDay>;
}
