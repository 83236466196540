<div class="home">
  <div *ngIf="isHandset$ | async" class="mobile">
    <!--<div class="center">
      <img src="{{logo}}" alt="" class="logo"/>
    </div>-->

    <div class="message" *ngIf="hasMessage">
      <div class="title">{{messageTitle}}</div>
      <div class="content">{{messageContent}}</div>
    </div>

    <div class="item">
      <h4>{{'home.address' | translate}}</h4>
      <p>{{addressLine1}}<br>{{addressLine2}}</p>
    </div>

    <div class="center">
      <button mat-button type="button" (click)="openMap()">{{'home.action-open-map' | translate}}</button>
    </div>

    <div class="item">
      <h4>{{infoMessage1Title}}</h4>
      <p>{{infoMessage1Body}}</p>
      <h4>{{infoMessage2Title}}</h4>
      <p>{{infoMessage2Body}}</p>
    </div>

    <div class="item">
      <h4>{{'home.businesshours' | translate}}</h4>
      <ul class="businesshours-list">
        <li *ngFor="let bh of businessHours">
          <u><b>{{'home.' + bh.type.toLowerCase()  | translate}}</b></u>
          <ul class="days-list">
            <li *ngFor="let day of bh.days">
              {{'home.' + day.day | translate}}
              <ul>
                <li *ngFor="let hour of day.hours">
                  {{hour.start}} - {{hour.end}}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="item">
      <h4>{{informationTitle}}</h4>
      <p>{{informationBody}}</p>
    </div>
  </div>

  <mat-grid-list cols="12" gutterSize="0" rowHeight="fit" class="list" *ngIf="isNotHandset$ | async">

    <mat-grid-tile colspan="4" rowspan="2">
      <div class="item-picture">
        <img #pic1 [class]="pictureStyle1" src="{{picture1}}" alt=""/>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="4" rowspan="2">
      <div class="item"  *ngIf="showAddress">
        <h4>{{'home.address' | translate}}</h4>
        <p>{{addressLine1}}<br>{{addressLine2}}</p>
        <button mat-button type="button" (click)="openMap()">{{'home.action-open-map' | translate}}</button>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="4" rowspan="2">
      <div class="item">
        <h4>{{informationTitle}}</h4>
        <p class="info-text">{{informationBody}}</p>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="4" rowspan="3">
      <div class="item">
        <h4>{{infoMessage1Title}}</h4>
        <p class="info-text">{{infoMessage1Body}}</p>
        <h4>{{infoMessage2Title}}</h4>
        <p class="info-text">{{infoMessage2Body}}</p>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="4" rowspan="3">
      <div class="item" *ngIf="showBusinessHours">
        <h4>{{'home.businesshours' | translate}}</h4>
        <ul class="businesshours-list">
          <li *ngFor="let bh of businessHours">
            <u><b>{{'home.' + bh.type.toLowerCase()  | translate}}</b></u>
            <ul class="days-list">
              <li *ngFor="let day of bh.days">
                {{'home.' + day.day | translate}}
                <ul>
                  <li *ngFor="let hour of day.hours">
                    {{hour.start}} - {{hour.end}}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="4" rowspan="3">
      <div class="item-picture">
        <img #pic2 [class]="pictureStyle2" src="{{picture2}}" alt=""/>
      </div>
    </mat-grid-tile>

  </mat-grid-list>

</div>
