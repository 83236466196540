import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "@app/public/home/home/home.component";
import {MyDataComponent} from "@app/secured/my-data/my-data/my-data.component";
import {MyPetsComponent} from "@app/secured/my-pets/my-pets/my-pets.component";
import {MyAppointmentsComponent} from "@app/secured/my-appointments/my-appointments/my-appointments.component";
import {AuthGuardService} from "@app/core/auth/auth-guard.service";
import {LoginFormComponent} from "@app/public/auth/login-form/login-form.component";
import {CalendarComponent} from "@app/public/appointment/calendar/calendar.component";
import {SendMessageComponent} from "@app/secured/send-message/send-message/send-message.component";
import {RegisteredGuardService} from "@app/core/auth/registered-guard.service";
import {NoCalendarService} from "@app/core/security/no-calendar.service";
import {WizardComponent} from "@app/public/appointment/wizard/wizard.component";
import {NoWizardService} from "@app/core/security/no-wizard.service";
import {MyOrdersComponent} from "@app/secured/my-orders/my-orders/my-orders.component";

const routes: Routes = [
  {path: '', redirectTo: 'calendar', pathMatch: 'full'},
  {path: 'login', component: LoginFormComponent},
  {path: 'home', component: HomeComponent},
  {path: 'calendar', component: CalendarComponent, canActivate: [NoCalendarService]},
  {path: 'wizard', component: WizardComponent, canActivate: [NoWizardService]},
  {path: 'mydata', component: MyDataComponent, canActivate: [AuthGuardService, RegisteredGuardService]},
  {path: 'mypets', component: MyPetsComponent, canActivate: [AuthGuardService, RegisteredGuardService]},
  {path: 'myorders', component: MyOrdersComponent, canActivate: [AuthGuardService, RegisteredGuardService]},
  {path: 'myappointments', component: MyAppointmentsComponent, canActivate: [AuthGuardService]},
  {path: 'message', component: SendMessageComponent, canActivate: [AuthGuardService, RegisteredGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
