import {AbstractControl} from '@angular/forms';

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirm_password').value;

    if (password != confirmPassword) {
      control.get('confirm_password').setErrors({ConfirmPassword: true});
    } else {
      if (control.get('confirm_password').hasError("ConfirmPassword")) {
        if (confirmPassword) {
          control.get('confirm_password').setErrors(null);
        } else {
          control.get('confirm_password').setErrors({required: true});
        }
      } else {
        return null
      }
    }
  }
}
