import {Component, OnInit} from '@angular/core';
import {MyOrdersService} from "@app/secured/my-orders/my-orders.service";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {OrderHistory} from "@app/secured/my-orders/model/order-history";
import {PetService} from "@app/shared/services/pet.service";
import {PetResponse} from "@app/shared/model/pet.response";
import {Order} from "@app/secured/my-orders/model/order.model";
import {OrderLine} from "@app/secured/my-orders/model/order-line.model";
import {Product} from "@app/secured/my-orders/model/product";
import {Observable, of} from "rxjs";
import {debounceTime, distinctUntilChanged, startWith, switchMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent extends ResponsiveComponent implements OnInit {

  public myOrderForm: UntypedFormGroup;
  public pets: Array<PetResponse>;
  public products: Array<Product> = [];
  public products$: Observable<Array<Product>>;

  public history: Array<OrderHistory>;

  constructor(breakpointObserver: BreakpointObserver,
              private formBuilder: UntypedFormBuilder,
              private service: MyOrdersService,
              private petService: PetService,
              private translate: TranslateService,
              private storage: StorageService,
              private dialog: DialogService) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.myOrderForm = this.formBuilder.group({
      orders: this.formBuilder.array([this.buildOrderLine()], {updateOn: 'blur'})
    });

    this.myOrderForm.valueChanges.subscribe(result => {
      let allValid: boolean = true;
      result.orders.map(order => {
        if (!order.patient_id || (!order.product_name && !order.product_id) || !order.quantity || isNaN(order.quantity) || order.quantity < 0) {
          allValid = false;
        }
      });
      if (allValid) {
        this.addNewOrderLine();
      }
    });

    this.petService.findPets(this.storage.customerId).subscribe(data => {
      this.pets = data;
    });

    this.service.findProducts().subscribe(data => {
      data.sort((a, b) => {
        if (a.product_name < b.product_name) {
          return -1;
        } else if (a.product_name > b.product_name) {
          return 1;
        } else {
          return 0;
        }
      });
      this.products = data;
    });

    this.refresh();
  }

  private buildOrderLine(): UntypedFormGroup {
    let product: UntypedFormControl = this.formBuilder.control("", {updateOn: 'change'});

    let line: UntypedFormGroup = this.formBuilder.group({
      patient_id: this.formBuilder.control(""),
      product_id: this.formBuilder.control(""),
      product_name: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      quantity: this.formBuilder.control(""),
      unit: this.formBuilder.control({value: "", disabled: true}),
      product: product
    });

    line.valueChanges.subscribe(result => {
      if (result.product) {
        line.patchValue({
          product_id: result.product.product_id,
          unit: result.product.unit
        }, {emitEvent: false});
      }
    });

    this.products$ = product.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 0) {
              return of(this._filter(value));
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    return line;
  }

  displayFn(product: Product): string {
    return product && product.product_name ? product.product_name : '';
  }

  private _filter(value: string): Product[] {
    const filterValue = value.toLowerCase();
    return this.products.filter(option => option.product_name.toLowerCase().includes(filterValue));
  }

  public get formArr(): UntypedFormArray {
    return this.myOrderForm.get('orders') as UntypedFormArray;
  }

  public addNewOrderLine(): void {
    this.formArr.push(this.buildOrderLine());
  }

  public deleteOrderLine(index: number): void {
    this.formArr.removeAt(index);
  }

  public handleSubmit(): void {
    if (this.isFormValid()) {
      let request: Order = new Order();
      request.practice = 1; //FIXME
      request.orders = new Array<OrderLine>();

      let orders: Array<any> = this.formArr.getRawValue();
      orders.map(order => {
        if (!order.patient_id && !order.product_name && !order.product_id && !order.quantity) {
          //do nothing
        } else {
          let line: OrderLine = new OrderLine();
          line.patient_id = order.patient_id;
          line.product_id = order.product_id;
          line.product_name = order.product_name;
          line.quantity = order.quantity;
          request.orders.push(line);
        }
      });

      this.service.createOrder(this.storage.customerId, request).subscribe(data => {
        this.dialog.showDialogWithText(this.translate.instant("message.order-create-success"));
        this.myOrderForm.reset();
        // this.myOrderForm.clearValidators();
        this.formArr.clear();
        //this.myOrderForm.updateValueAndValidity();
        this.refresh();
      }, error => {
        this.dialog.showError("error.server");
      });
    }
  }

  public useForNewOrder(order: OrderHistory): void {
    //check last has empty product
    let group: UntypedFormGroup = this.formArr.at(this.formArr.length - 1) as UntypedFormGroup;

    let product: Product = this.products.filter(value => value.product_id === order.productId)[0];

    if (!group.getRawValue().product_name) {
      group.patchValue({
        patient_id: null,
        product_id: order.productId,
        product_name: order.productName,
        unit: order.unit,
        product: product,
        quantity: order.quantity
      });
    } else {
      group = this.buildOrderLine();
      group.patchValue({
        patient_id: null,
        product_id: order.productId,
        product_name: order.productName,
        unit: order.unit,
        product: product,
        quantity: order.quantity
      });
      this.formArr.push(group);
    }

    // let ctrl = group.get("product_name");
    // ctrl.disable();
  }

  public delete(id: number): void {
    this.service.deleteOrder(id).subscribe(response => {
      if (response.code === "ok") {
        this.dialog.showDialog("message.order-delete-success");
      } else {
        this.dialog.showDialog("message.order-delete-failed");
      }
      this.refresh();
    }, error => {
      this.dialog.showError("error.server");
    });
  }

  public isFormValid(): boolean {
    let orders: Array<any> = this.formArr.value;

    let valid: boolean = true;

    if (orders.length == 1) {
      valid = false;
    } else {
      let emptyLine: boolean = false;
      orders.map(order => {
        if (!order.patient_id && !order.product_name && !order.product_id && !order.quantity) {
          if (!emptyLine) {
            emptyLine = true;
          } else {
            //two empty rows
            valid = false;
          }
        } else if (!order.patient_id || (!order.product_name && !order.product_id) || !order.quantity) {
          valid = false;
        }
      });
    }
    return valid;
  }

  private refresh(): void {
    this.service.findOrders(this.storage.customerId).subscribe(response => {
      this.history = response;
    }, error => {
      this.dialog.showError("error.server");
    });
  }

  public handleEnter($event: KeyboardEvent): void {
    $event.preventDefault();
    ($event.target as HTMLInputElement).blur();
  }
}
