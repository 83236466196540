import {Injectable} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {ErrorComponent} from "@app/widget/error/error.component";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialog: MatDialog) {
  }

  public handleError(error): void {
    if (error.status === 403) {
      this.showError("error-permission");
    } else {
      this.showError("error.server");
    }
  }

  public showError(messageKey: string): void {
    this.dialog.open(ErrorComponent, {
      data: {
        key: messageKey,
        redirect: false
      }
    });
  }

  public showErrorAndRedirect(messageKey: string): void {
    this.dialog.open(ErrorComponent, {
      data: {
        key: messageKey,
        redirect: true
      }
    });
  }

}
