import {CustomerResponse} from "@app/model/customer.response";

export class Customer {

  public last_name:string;
  public first_name:string;
  public street:string;
  public number:string;
  public postal_code:string;
  public characters:string;
  public city:string;
  public country:string;
  public nat_reg:string;
  public phone:string;
  public mobile1:string;
  public mobile2:string;
  public email:string;
  public language:string;
  public newsletter:boolean;
  public mail:boolean;
  public sms:boolean;

  constructor(data:CustomerResponse) {
    this.characters = data.characters != null ? data.characters : "";
    this.city = data.city;
    this.country = data.country;
    this.email = data.email;
    this.first_name = data.first_name;
    this.language = data.language;
    this.last_name = data.last_name;
    this.mail = data.mail != null ? data.mail : false;
    this.mobile1 = data.mobile1;
    this.mobile2 = data.mobile2;
    this.nat_reg = data.nat_reg;
    this.newsletter = data.newsletter != null ? data.newsletter : false;
    this.number = data.number;
    this.phone = data.phone;
    this.postal_code = data.postal_code;
    this.sms = data.sms != null ? data.sms : false;
    this.street = data.street;
  }

}
