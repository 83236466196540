<div class="center" [@.disabled]="disableAnimations" >
  <div class="login">
    <h3>{{'auth.login.title' | translate}}</h3>
    <div class="button-center">
      <button mat-stroked-button (click)="handleLogin()"
              class="login-button" *ngIf="registerFormVisible">{{'auth.action-login' | translate}}</button>
    </div>

    <div *ngIf="loginFormVisible" [@slideInOut]>
      <app-login (onLoginComplete)="handleLoginComplete()"></app-login>
    </div>

    <h3>{{'auth.register.title' | translate}}</h3>
    <div class="button-center">
      <button mat-stroked-button (click)="handleRegister()"
              class="login-button" *ngIf="loginFormVisible">{{'auth.action-register' | translate}}</button>
    </div>

    <div *ngIf="registerFormVisible" [@slideInOut]>
      <app-register (onRegisterComplete)="handleRegisterComplete()"></app-register>
    </div>

  </div>
</div>
