<form [formGroup]="petForm" (ngSubmit)="handleSubmit()" class="pet">

  <h1 mat-dialog-title>{{'pet.title-delete' | translate}}</h1>

  <mat-radio-group class="radio-group" (change)="optionSelected($event)">
    <mat-radio-button value="away" class="radio" checked>{{'pet.deleteType.away' | translate}}</mat-radio-button>
    <mat-radio-button value="death" class="radio">{{'pet.deleteType.death' | translate}}</mat-radio-button>
  </mat-radio-group>

  <mat-form-field class="form-field" *ngIf="isPetDead">
    <input matInput placeholder="{{'pet.dateOfDeath' | translate}}" formControlName="date_of_death" required [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="petForm.get('date_of_death').errors && petForm.get('date_of_death').errors.required">
      <span>{{'error.required.dateOfDeath' | translate}}</span>
    </mat-error>
  </mat-form-field>

  <mat-dialog-actions class="button-center">
    <button mat-raised-button color="primary" type="submit" [disabled]="!petForm.valid"
            class="appointment-button">{{'pet.action-submit' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>

</form>
