<div class="main-container">
  <app-titlebar></app-titlebar>
  <app-navigation class="main-content">
    <div class="content" app-body>
      <router-outlet></router-outlet>
    </div>
  </app-navigation>
</div>
<ng-http-loader></ng-http-loader>

<!--<app-main-nav></app-main-nav>-->
