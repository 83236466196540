import {NgModule} from '@angular/core';
import {HomeModule} from "@app/public/home/home.module";
import {AppointmentModule} from "@app/public/appointment/appointment.module";
import {AuthModule} from "@app/public/auth/auth.module";

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    HomeModule,
    AppointmentModule,
    AuthModule
  ]
})
export class PublicModule {
}
