<div class="accounts">
  <h1 mat-dialog-title>{{'mydata.linked-accounts.title' | translate}}</h1>

  <mat-list mat-dialog-content *ngFor="let account of accounts">
    <mat-list-item>

      <div class="email">{{account.email}}</div>


      <!--verified_user  vpn_key  security  enhanced_encryption
      aria-label="{{'mydata.action-change-password' | translate}}"
      aria-label="{{'mydata.action-delete-account' | translate}}"
      -->

      <button mat-raised-button *ngIf="account.email !== masterEmail" (click)="handleDelete(account.id)">
        <mat-icon>delete_forever</mat-icon>
      </button>

      <button mat-raised-button (click)="handleChangePassword(account.email)">
        <mat-icon>vpn_key</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>

  <mat-dialog-actions>
    <button mat-raised-button type="button" (click)="handleAddAccount()">{{'mydata.action-add-account' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>
</div>



