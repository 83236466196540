import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {CustomerInfo} from "@app/model/customerinfo";
import {CodedResponse} from "@app/model/coded.response";
import {LoginRequest} from "@app/widget/login/login.request";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public login(username: string, password: string): Observable<CustomerInfo> {
    let body: LoginRequest = new LoginRequest();
    body.username = username;
    body.password = password;

    return this.httpClient.post<CustomerInfo>(`${environment.baseUrl}/login`, body, this.security.getHttpOptions());
  }

  public resetPassword(username: string): Observable<CodedResponse> {
    let body: LoginRequest = new LoginRequest();
    body.username = username;

    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/resetpassword`, body, this.security.getHttpOptions());
  }
}

