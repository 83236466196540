import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "@app/shared/material/material.module";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {TabDirective} from "@app/shared/directives/tab.directive";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";

@NgModule({
    declarations: [
        ResponsiveComponent,
        TabDirective
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ResponsiveComponent,
        TabDirective
    ]
})
export class SharedModule {
}
