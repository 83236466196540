import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {CodedidResponse} from "@app/model/codedid.response";
import {Register} from "@app/widget/register/register.model";
import {CodedResponse} from "@app/model/coded.response";
import {LoginRequest} from "@app/widget/login/login.request";

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public register(body: Register): Observable<CodedidResponse> {
    return this.httpClient.post<CodedidResponse>(`${environment.baseUrl}/register`, body, this.security.getHttpOptions());
  }

  public resetPassword(username: string): Observable<CodedResponse> {
    let body: LoginRequest = new LoginRequest();
    body.username = username;

    return this.httpClient.post<CodedResponse>(`${environment.baseUrl}/resetpassword`, body, this.security.getHttpOptions());
  }
}
