<form class="register-form" [formGroup]="registerForm" (ngSubmit)="handleSubmitRegister()">
  <mat-grid-list cols="12" rowHeight="63" gutterSize="10" class="list register-grid">
    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'common.firstName' | translate}}" formControlName="firstName" required>
        <mat-error *ngIf="registerForm.get('firstName').errors && registerForm.get('firstName').errors.required">
          <span>{{'error.required.firstName' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'common.lastName' | translate}}" formControlName="lastName" required>
        <mat-error *ngIf="registerForm.get('lastName').errors && registerForm.get('lastName').errors.required">
          <span>{{'error.required.lastName' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput type="email" placeholder="{{'common.email' | translate}}" formControlName="email" required>
        <mat-error *ngIf="registerForm.get('email').errors">
          <span *ngIf="registerForm.get('email').errors.required">{{'error.required.email' | translate}}</span>
          <span *ngIf="registerForm.get('email').errors.email">{{'error.format.email' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'common.phone' | translate}}" formControlName="phone" required>
        <mat-error *ngIf="registerForm.get('phone').errors">
          <span *ngIf="registerForm.get('phone').errors.required">{{'error.required.phone' | translate}}</span>
          <span *ngIf="registerForm.get('phone').errors.phone">{{'error.format.nonStrictPhone' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="{{'register.password' | translate}}" formControlName="password" required>
        <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
          <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="registerForm.get('password').errors && registerForm.get('password').errors.required">
          <span>{{'error.required.password' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput [type]="hide2 ? 'password' : 'text'" placeholder="{{'register.confirmPassword' | translate}}" formControlName="confirm_password" required>
        <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
          <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="registerForm.get('confirm_password').errors">
          <span *ngIf="registerForm.get('confirm_password').errors.required">{{'error.required.confirmPassword' | translate}}</span>
          <span *ngIf="registerForm.get('confirm_password').errors.ConfirmPassword">{{'error.passwordMatch' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="cs_9_12$ | async">
      <mat-form-field class="form-field">
        <input matInput tab-directive type="text" placeholder="{{'common.address' | translate}}" formControlName="street" [matAutocomplete]="autoAddress" required>
        <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="addressSelected($event)" autoActiveFirstOption="{{hasData}}">
          <mat-option *ngFor="let address of addresses$ | async" [value]="address">
            {{address.street}} - {{address.postal_code}} {{address.city}} - {{address.country}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="registerForm.get('street').errors && registerForm.get('street').errors.required">
          <span>{{'error.required.street' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_3_12$ | async">
      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'common.number' | translate}}" formControlName="number" required>
        <mat-error *ngIf="registerForm.get('number').errors && registerForm.get('number').errors.required">
          <span>{{'error.required.number' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="showCharacters ? (cs_2_10$ | async) : (cs_3_12$ | async)">
      <mat-form-field class="form-field">
        <input matInput tab-directive type="text" placeholder="{{'common.postalcode' | translate}}" formControlName="postalCode" [matAutocomplete]="autoPostalCode" required>
        <mat-autocomplete #autoPostalCode="matAutocomplete" (optionSelected)="citySelected($event)" panelWidth="300px" autoActiveFirstOption="true">
          <mat-option *ngFor="let city of postalCodes$ | async" [value]="city">
            {{city.postal_code}} {{city.city}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="registerForm.get('postalCode').errors && registerForm.get('postalCode').errors.required">
          <span>{{'error.required.base' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="showCharacters" [colspan]="cs_1_2$ | async">
      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'common.characters' | translate}}" formControlName="characters" required maxlength="2">
        <mat-error *ngIf="registerForm.get('characters').errors && registerForm.get('characters').errors.required">
          <span>{{'error.required.base' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_6_12$ | async">
      <mat-form-field class="form-field">
        <input matInput tab-directive type="text" placeholder="{{'common.city' | translate}}" formControlName="city" [matAutocomplete]="autoCity" required>
        <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="citySelected($event)" autoActiveFirstOption="true">
          <mat-option *ngFor="let city of cities$ | async" [value]="city">
            {{city.city}} {{city.postal_code}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="registerForm.get('city').errors && registerForm.get('city').errors.required">
          <span>{{'error.required.city' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="cs_3_12$ | async">
      <mat-form-field class="form-field">
        <mat-label>{{'common.country' | translate}}</mat-label>
        <mat-select formControlName="country" required (selectionChange)="countrySelected($event)">
          <mat-option value="be">{{'common.code.country.be' | translate}}</mat-option>
          <mat-option value="nl">{{'common.code.country.nl' | translate}}</mat-option>
          <mat-option value="de">{{'common.code.country.de' | translate}}</mat-option>
        </mat-select>
        <mat-error *ngIf="registerForm.get('country').errors && registerForm.get('country').errors.required">
          <span>{{'error.required.country' | translate}}</span>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="button-center">
    <button mat-raised-button color="primary" type="submit" [disabled]="!registerForm.valid"
            class="register-button">{{'register.action-submit' | translate}}</button>
  </div>
</form>
