import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StorageService} from "@app/core/storage/storage.service";
import {CalendarService} from "@app/public/appointment/calendar/calendar.service";
import {formatDate} from "@angular/common";
import {AuthService} from "@app/core/auth/auth.service";
import {AddressService} from "@app/shared/services/address.service";
import {BreedService} from "@app/shared/services/breed.service";
import {AppointmentService} from "@app/public/appointment/appointment.service";
import {AppointmentRequest} from "@app/public/appointment/model/appointment.request";
import {DialogService} from "@app/core/dialog/dialog.service";
import {PetService} from "@app/shared/services/pet.service";
import {TranslateService} from "@ngx-translate/core";
import {MultipleAppointmentsRequest} from "@app/public/appointment/model/multipleappointments.request";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ErrorService} from "@app/core/error/error.service";
import {AppointmentTypeResponse} from "@app/public/appointment/model/appointmenttype.response";
import {AppointmentTypeRequest} from "@app/public/appointment/model/appointmenttype.request";
import {AppointmentWizard} from "@app/public/appointment/appointment.wizard";
import {Language} from "@app/model/language";

export interface AppointmentData {
  start: Date;
  end: Date;
  vetId: number;
}

@Component({
  selector: 'app-appointmentwithtype',
  templateUrl: './appointmentwithtype.component.html',
  styleUrls: ['./appointmentwithtype.component.scss']
})
export class AppointmentWithTypeComponent extends AppointmentWizard implements OnInit {

  public step0completed: boolean;
  public appointmentTypes: Array<AppointmentTypeResponse>;

  constructor(breakpointObserver: BreakpointObserver,
              private dialogRef: MatDialogRef<AppointmentWithTypeComponent>,
              formBuilder: UntypedFormBuilder,
              storage: StorageService,
              auth: AuthService,
              dialog: DialogService,
              translate: TranslateService,
              service: AppointmentService,
              addressService: AddressService,
              breedService: BreedService,
              petService: PetService,
              private calendarService: CalendarService,
              err: ErrorService,
              @Inject(MAT_DIALOG_DATA) private data: AppointmentData) {
    super(breakpointObserver, formBuilder, storage, auth, service, addressService, petService, breedService, translate, dialog, err);

    this.vetId = data.vetId;
    this.selectedPractice = calendarService.selectedPractice;
    this.vet = calendarService.getVetName(data.vetId);
    this.location = calendarService.selectedPracticeAddress;
    this.day = formatDate(data.start, "EEEE dd MMMM", this.storage.locale);
    this.duration = null;
  }

  ngOnInit() {
    this.step0completed = false;
    this.loadAppointmentTypes();

    super.onInit();

    this.reasonInput = this.formBuilder.control("");
    //this.reasonInput = this.formBuilder.control("", [Validators.required, Validators.minLength(5)]);

    this.confirmForm = this.formBuilder.group({
      reason: this.reasonInput
    });

    this.translate.onLangChange.subscribe(data => {
      this.translateAppointmentTypes();
    });
  }

  public handleClose() {
    if (this.isEditable) {
      this.dialog.showDialogWithAction("message.close-appointment", "calendar.appointment.action-continue").afterClosed().subscribe(data => {
        if (!data) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private loadAppointmentTypes(): void {
    this.service.getAppointmentTypes(this.selectedPractice, this.vetId).subscribe(data => {
      this.appointmentTypes = data;
      this.translateAppointmentTypes();
    });
  }

  private translateAppointmentTypes(): void {
    for (let value of this.appointmentTypes) {
      switch (this.storage.language) {
        case Language.NL:
          value.translate = value.description_nl;
          break;
        case Language.FR:
          value.translate = value.description_fr;
          break;
        case Language.EN:
          value.translate = value.description_en;
          break;
        case Language.DE:
          value.translate = value.description_de;
          break;
        default:
          value.translate = value.description_nl;
          break;
      }
    }
  }

  public handleSelectAppointmentType(type: AppointmentTypeResponse): void {
    let request: AppointmentTypeRequest = new AppointmentTypeRequest();
    request.duration = type.duration;
    request.practice = this.selectedPractice;
    request.start_date = this.data.start;
    request.vet_id = this.vetId;
    this.service.checkAppointmentTypeAvailable(request).subscribe(data => {
      this.step0completed = true;
      this.start = data.start;
      this.end = data.end;
      this.time = this.formatTime(data.start, data.end);
      this.appointmentType = type.description;
      this.duration = type.duration;

      //set validation
      if (type.extra_info_required) {
        this.reasonInput.setValidators([Validators.required, Validators.minLength(5)]);
        this.reasonInput.markAsDirty();
      } else {
        this.reasonInput.setValidators(null);
      }
      this.reasonInput.updateValueAndValidity();

      let popupText: string = null;
      switch (this.storage.language) {
        case Language.NL:
          if (type.popup_nl) {
            popupText = type.popup_nl;
          }
          break;
        case Language.FR:
          if (type.popup_fr) {
            popupText = type.popup_fr;
          }
          break;
        case Language.EN:
          if (type.popup_en) {
            popupText = type.popup_en;
          }
          break;
        case Language.DE:
          if (type.popup_de) {
            popupText = type.popup_de;
          }
          break;
        default:
          if (type.popup_nl) {
            popupText = type.popup_nl;
          }
          break;
      }

      if (popupText) {
        const dialogRef = this.dialog.showDialogWithText(popupText);
        dialogRef.afterClosed().subscribe(result => {
          setTimeout(() => {
            this.stepper.next();
            this.isHandset$.subscribe(mobile => {
              if (mobile && this.isLockedRegistration) {
                this.dialog.showDialog("message.registration-locked");
              }
            });
          });
        });
      } else {
        setTimeout(() => {
          this.stepper.next();
          this.isHandset$.subscribe(mobile => {
            if (mobile && this.isLockedRegistration) {
              this.dialog.showDialog("message.registration-locked");
            }
          });
        });
      }


    }, error => {
      if (error.status === 409) {
        let typeDuration: string = this.formatDuration(type.duration, error.error.time_code);
        let start: string = formatDate(error.error.start, "HH:mm", "nl-BE");
        //let end: string = formatDate(error.error.end, "HH:mm", "nl-BE");
        let diff: string = this.calculateDiff(Date.parse(error.error.start), Date.parse(error.error.end));
        let message: string = this.translate.instant("error.appointment-type", {type: type.description, duration: typeDuration, start: start, diff: diff});
        this.dialog.showDialogWithText(message);
      } else {
        this.err.handleError(error);
      }
    });
  }

  private calculateDiff(start: number, end: number): string {
    let diff: number = (end - start) / 1000;
    diff /= 60;
    diff = Math.abs(Math.round(diff));
    return diff + " " + this.translate.instant("calendar.minute");
  }

  public handleSubmit(): void {
    if (this.isTempLoggedIn) {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.appointment_type = this.appointmentType;
      request.remark = this.reasonInput.value;
      request.temp_customer_id = this.storage.customerId;
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    } else if (this.isLoggedIn) {
      let request: MultipleAppointmentsRequest = new MultipleAppointmentsRequest();
      request.number_of_appointments = this.selectedPatientIds.length;
      request.practice = this.selectedPractice;
      request.start_date = this.start;
      request.vet_id = this.vetId;
      request.appointment_type = this.appointmentType;

      this.service.checkMultipleAppointments(request).subscribe(data => {
        let itemsProcessed: number = 0;
        for (let i: number = 0; i < this.selectedPatientIds.length; i++) {
          let patientId: number = this.selectedPatientIds[i];
          let start: Date = data[i].start;
          let end: Date = data[i].end;

          let request: AppointmentRequest = new AppointmentRequest();
          request.vet_id = this.vetId;
          request.practice = this.selectedPractice;
          request.date = start;
          request.start = formatDate(start, "HH:mm", "nl-BE");
          request.end = formatDate(end, "HH:mm", "nl-BE");
          request.appointment_type = this.appointmentType;
          request.remark = this.reasonInput.value;
          request.customer_id = this.storage.customerId;
          request.patient_id = patientId;

          this.service.createAppointment(request).subscribe(data => {
            itemsProcessed++;
            if (itemsProcessed === this.selectedPatientIds.length) {
              this.stepper.next();
              this.isEditable = false;
            }
          }, error => {
            if (error.status === 409) {
              this.dialog.showError("error.account-in-use");
            } else if (error.status === 406) {
              this.dialog.showError("error.same-day-appointment");
            } else if (error.status === 412) {
              this.dialog.showError("error.no-user-information");
            } else {
              this.err.handleError(error);
            }
          });
        }
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.multiple-appointments");
        } else if (error.status === 412) {
          this.dialog.showError("error.number-of-patients-appointments");
        } else {
          this.err.handleError(error);
        }
      });
    } else {
      let request: AppointmentRequest = new AppointmentRequest();
      request.vet_id = this.vetId;
      request.practice = this.selectedPractice;
      request.date = this.start;
      request.start = formatDate(this.start, "HH:mm", "nl-BE");
      request.end = formatDate(this.end, "HH:mm", "nl-BE");
      request.appointment_type = this.appointmentType;
      request.remark = this.reasonInput.value;
      request.last_name = this.guestAccountForm.value.lastName;
      request.first_name = this.guestAccountForm.value.firstName;
      request.phone = this.guestAccountForm.value.phone;
      request.email = this.guestAccountForm.value.email;
      request.street = this.guestAccountForm.value.street;
      request.number = this.guestAccountForm.value.number;
      request.city = this.guestAccountForm.value.city;
      request.postal_code = this.guestAccountForm.value.postalCode;
      request.characters = this.guestAccountForm.value.characters;
      request.country = this.guestAccountForm.value.country;
      request.language = this.storage.language.toString();
      request.patient_name = this.guestPatientForm.value.patientName;
      request.gender = this.guestPatientForm.value.gender;
      if (this.guestPatientForm.value.dateOfBirth) {
        request.date_of_birth = formatDate(this.guestPatientForm.value.dateOfBirth, "dd/MM/yyyy", "nl-BE");
      }
      request.breed_id = this.selectedBreedId;

      this.service.createAppointment(request).subscribe(data => {
        this.stepper.next();
        this.isEditable = false;
      }, error => {
        if (error.status === 409) {
          this.dialog.showError("error.account-in-use");
        } else if (error.status === 406) {
          this.dialog.showError("error.same-day-appointment");
        } else if (error.status === 412) {
          this.dialog.showError("error.no-user-information");
        } else {
          this.err.handleError(error);
        }
      });
    }
  }

  private formatDuration(duration: number, timeCode: string): string {
    switch (timeCode) {
      case "MIN_10":
        switch (duration) {
          case 1:
            return "10 " + this.translate.instant("calendar.minute");
          case 2:
            return "20 " + this.translate.instant("calendar.minute");
          case 3:
            return "30 " + this.translate.instant("calendar.minute");
          case 4:
            return "40 " + this.translate.instant("calendar.minute");
          case 5:
            return "50 " + this.translate.instant("calendar.minute");
          case 6:
            return "1 " + this.translate.instant("calendar.hour");
          case 7:
            return "1 " + this.translate.instant("calendar.hour") + " 10 " + this.translate.instant("calendar.minute");
          case 8:
            return "1 " + this.translate.instant("calendar.hour") + " 20 " + this.translate.instant("calendar.minute");
          case 9:
            return "1 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 10:
            return "1 " + this.translate.instant("calendar.hour") + " 40 " + this.translate.instant("calendar.minute");
          case 11:
            return "1 " + this.translate.instant("calendar.hour") + " 50 " + this.translate.instant("calendar.minute");
          case 12:
            return "2 " + this.translate.instant("calendar.hour");
          case 13:
            return "2 " + this.translate.instant("calendar.hour") + " 10 " + this.translate.instant("calendar.minute");
          case 14:
            return "2 " + this.translate.instant("calendar.hour") + " 20 " + this.translate.instant("calendar.minute");
          case 15:
            return "2 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 16:
            return "2 " + this.translate.instant("calendar.hour") + " 40 " + this.translate.instant("calendar.minute");
          case 17:
            return "2 " + this.translate.instant("calendar.hour") + " 50 " + this.translate.instant("calendar.minute");
          case 18:
            return "3 " + this.translate.instant("calendar.hour");
          default:
            return "";
        }
      case "MIN_15":
        switch (duration) {
          case 1:
            return "15 " + this.translate.instant("calendar.minute");
          case 2:
            return "30 " + this.translate.instant("calendar.minute");
          case 3:
            return "45 " + this.translate.instant("calendar.minute");
          case 4:
            return "1 " + this.translate.instant("calendar.hour");
          case 5:
            return "1 " + this.translate.instant("calendar.hour") + " 15 " + this.translate.instant("calendar.minute");
          case 6:
            return "1 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 7:
            return "1 " + this.translate.instant("calendar.hour") + " 45 " + this.translate.instant("calendar.minute");
          case 8:
            return "2 " + this.translate.instant("calendar.hour");
          case 9:
            return "2 " + this.translate.instant("calendar.hour") + " 15 " + this.translate.instant("calendar.minute");
          case 10:
            return "2 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 11:
            return "2 " + this.translate.instant("calendar.hour") + " 45 " + this.translate.instant("calendar.minute");
          case 12:
            return "3 " + this.translate.instant("calendar.hour");
          default:
            return "";
        }
      case "MIN_20":
        switch (duration) {
          case 1:
            return "20 " + this.translate.instant("calendar.minute");
          case 2:
            return "40 " + this.translate.instant("calendar.minute");
          case 3:
            return "1 " + this.translate.instant("calendar.hour");
          case 4:
            return "1 " + this.translate.instant("calendar.hour") + " 20 " + this.translate.instant("calendar.minute");
          case 5:
            return "1 " + this.translate.instant("calendar.hour") + " 40 " + this.translate.instant("calendar.minute");
          case 6:
            return "2 " + this.translate.instant("calendar.hour");
          case 7:
            return "2 " + this.translate.instant("calendar.hour") + " 20 " + this.translate.instant("calendar.minute");
          case 8:
            return "2 " + this.translate.instant("calendar.hour") + " 40 " + this.translate.instant("calendar.minute");
          case 9:
            return "3 " + this.translate.instant("calendar.hour");
          default:
            return "";
        }
      case "MIN_30":
        switch (duration) {
          case 1:
            return "30 " + this.translate.instant("calendar.minute");
          case 2:
            return "1 " + this.translate.instant("calendar.hour");
          case 3:
            return "1 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 4:
            return "2 " + this.translate.instant("calendar.hour");
          case 5:
            return "2 " + this.translate.instant("calendar.hour") + " 30 " + this.translate.instant("calendar.minute");
          case 6:
            return "3 " + this.translate.instant("calendar.hour");
          default:
            return "";
        }
    }
    return "";
  }
}
