import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from "@app/model/language";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {VetInfo} from "@app/model/vetinfo";
import {environment} from "@env/environment";
import {SecurityService} from "@app/core/security/security.service";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private httpClient: HttpClient,
              private storage: StorageService,
              private security: SecurityService,
              private translate: TranslateService,
              private dialog: DialogService,
              private location: Location) {
  }

  public initializeApp(): Promise<any> {
    let key: string = this.getUrlParams("vet_id");
    if (key === null || key === undefined) {
      key = localStorage.getItem("vet_id");
    }
    localStorage.setItem("vet_id", key);
    let lang: string = this.getUrlParams("language");
    if (lang) {
      this.storage.language = Language[lang.toUpperCase()];
      this.translate.use(lang);
    } else {
      this.storage.language = Language.NL;
      this.translate.use('nl');
    }
    let startup: string = this.getUrlParams("startup");
    if (startup) {
      this.location.replaceState(this.location.path().split('?')[0] + "/" + startup, '');
    } else {
      this.location.replaceState(this.location.path().split('?')[0], '');
    }

    return new Promise<void>((resolve, reject) => {
      if (key) {
        this.getVetInfo(key).subscribe(data => {
          this.storage.vetInfo = data;
          this.security.authKey = data.auth_code;

          if (this.storage.popup_text) {
            //if must_select_vet = true --> popup is handled in vet selection to avoid popup overlap
            if (data.must_select_vet && !data.use_alternate_agenda) {
              this.storage.showOnceInPopup = this.storage.popup_text;
            } else {
              this.dialog.showDialogWithText(this.storage.popup_text);
            }
          }
          resolve();
        }, (error) => {
          if (error.status === 403) {
            this.dialog.showErrorAndRedirect('error.no-client');
          } else {
            this.dialog.showErrorAndRedirect('error.server');
          }
          reject(error);
        })
      } else {
        this.dialog.showErrorAndRedirect('error.no-key');
        reject();
      }
    });
  }

  private getVetInfo(key: string): Observable<VetInfo> {
    return this.httpClient.get<VetInfo>(`${environment.baseUrl}/vetinfo?key=${key}`);
  }

  private getUrlParams(prop): string {
    let params = {};
    let search: string = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
    let definitions: string[] = search.split('&');

    definitions.forEach((val, key) => {
      let parts = val.split('=', 2);
      params[parts[0]] = parts[1];
    });

    return (prop && prop in params) ? params[prop] : null;
  }
}
