import {Component, Inject, OnInit} from '@angular/core';
import {Practice} from "@app/public/appointment/model/practice";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-selectpractice',
  templateUrl: './selectpractice.component.html',
  styleUrls: ['./selectpractice.component.scss']
})
export class SelectpracticeComponent implements OnInit {

  public practices: Array<Practice>

  constructor(
    public dialogRef: MatDialogRef<SelectpracticeComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SelectPracticeData) {

    this.practices = data.practices;
  }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.dialogRef.close(true);
  }

  public selectPractice(practice: Practice) {
    this.dialogRef.close(practice);
  }
}

export interface SelectPracticeData {
  practices: Array<Practice>;
}
