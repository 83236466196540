<div class="center">
  <div class="my-orders">
    <h1>{{'myOrders.title' | translate}}</h1>
    <form [formGroup]="myOrderForm" (ngSubmit)="handleSubmit()">
      <mat-grid-list cols="12" rowHeight="63" gutterSize="10" class="list">
        <div formArrayName="orders">
          <div *ngFor="let order of formArr.controls; let i=index" [formGroupName]="i">
            <mat-grid-tile [colspan]="cs_1_2$ | async">
              <button mat-icon-button (click)="deleteOrderLine(i)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="cs_3_10$ | async">
              <mat-form-field class="form-field">
                <mat-label>{{'myOrders.patientName' | translate}}</mat-label>
                <mat-select formControlName="patient_id">
                  <mat-option *ngFor="let pet of pets" [value]="pet.id">{{pet.name}}</mat-option>
                </mat-select>
<!--                <mat-error *ngIf="myOrderForm.get('patient_id').errors && myOrderForm.get('patient_id').errors.required">-->
<!--                  <span>{{'error.required.patientName' | translate}}</span>-->
<!--                </mat-error>-->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="cs_6_8$ | async">
              <mat-form-field class="form-field">
                <mat-label>{{'myOrders.productName' | translate}}</mat-label>
<!--                <mat-select formControlName="product" required>-->
<!--                  <mat-option *ngFor="let product of products" [value]="product">{{product.product_name}}</mat-option>-->
<!--                </mat-select>-->
                <input matInput type="text" formControlName="product" [matAutocomplete]="autoProduct">
                <mat-autocomplete #autoProduct="matAutocomplete" autoActiveFirstOption="true" [displayWith]="displayFn">
                  <mat-option *ngFor="let product of products$ | async" [value]="product">
                    {{product.product_name}}
                  </mat-option>
                </mat-autocomplete>
<!--                <mat-error *ngIf="myOrderForm.get('product_name').errors && myOrderForm.get('product_name').errors.required">-->
<!--                  <span>{{'error.required.productName' | translate}}</span>-->
<!--                </mat-error>-->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="cs_2_4$ | async">
              <mat-form-field class="form-field form-left">
                <mat-label>{{'myOrders.quantity' | translate}}</mat-label>
                <input matInput type="number" min="0" formControlName="quantity" (keydown.enter)="handleEnter($event)">
<!--                <mat-error *ngIf="myOrderForm.get('quantity').errors && myOrderForm.get('quantity').errors.required">-->
<!--                  <span>{{'error.required.quantity' | translate}}</span>-->
<!--                </mat-error>-->
              </mat-form-field>
              <mat-form-field class="form-field form-right">
                <input matInput type="text" formControlName="unit">
              </mat-form-field>
            </mat-grid-tile>
          </div>

        </div>

      </mat-grid-list>

      <div class="button-center">
        <button mat-raised-button color="primary" type="submit" [disabled]="!isFormValid()">{{'myOrders.action-submit' | translate}}</button>
      </div>
    </form>

    <div>
      <h2>{{'myOrders.title-prev' | translate}}</h2>
      <mat-list class="list">
        <mat-list-item *ngFor="let order of history">
          <div mat-line class="list-item">
            <button mat-icon-button *ngIf="order.id == null" (click)="useForNewOrder(order)">
              <mat-icon>add_shopping_cart</mat-icon>
            </button>
            <button mat-icon-button *ngIf="order.id != null" (click)="delete(order.id)">
              <mat-icon>delete_forever</mat-icon>
            </button>
            <div class="list-date">
              {{order.date | date}}
            </div>
            <div class="list-patient">
              {{order.patientName}}
            </div>
            <div class="list-quantity">
              {{order.quantity}}&nbsp;&nbsp;{{order.unit}}
            </div>
            <div class="list-product">
              {{order.productName}}
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  </div>
</div>
