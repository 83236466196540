import {Component, OnInit} from '@angular/core';
import {Calendar} from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {CalendarService} from "@app/public/appointment/calendar/calendar.service";
import {AgendaConfigurationResponse} from "@app/public/appointment/model/agendaconfiguration.response";
import {StorageService} from "@app/core/storage/storage.service";
import {PracticeResponse} from "@app/public/appointment/model/practice.response";
import {EventInput, EventRenderingChoice} from "@fullcalendar/core/structs/event";
import {formatDate} from "@angular/common";
import * as $ from 'jquery';
import {TranslateService} from "@ngx-translate/core";
import {AppointmentComponent} from "@app/public/appointment/appointment/appointment.component";
import {VetResponse} from "@app/public/appointment/model/vet.response";
import {ActivatedRoute} from "@angular/router";
import {DialogService} from "@app/core/dialog/dialog.service";
import {AppointmentService} from "@app/public/appointment/appointment.service";
import {AppointmentRequest} from "@app/public/appointment/model/appointment.request";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {ButtonTextCompoundInput, ToolbarInput} from "@fullcalendar/core/types/input-types";
import {ReservationResponse} from "@app/public/appointment/model/reservation.response";
import {Vet} from "@app/public/appointment/model/vet";
import {SelectvetComponent} from "@app/public/appointment/selectvet/selectvet.component";
import {AppointmentWithTypeComponent} from "@app/public/appointment/appointmentwithtype/appointmentwithtype.component";
import {SelectpracticeComponent} from "@app/public/appointment/selectpractice/selectpractice.component";
import {Practice} from "@app/public/appointment/model/practice";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public showPracticeSelection: boolean;
  public practices: Array<Practice>;
  public showVetSelection: boolean;
  private vets: Array<Vet> = [];
  private calendar: Calendar;
  private editId: number;
  public selectedPracticeName: string;
  public selectedVetName: string;
  private selectedVetId: number;

  constructor(private breakpointObserver: BreakpointObserver,
              private calendarService: CalendarService,
              private service: AppointmentService,
              private storage: StorageService,
              private dialog: DialogService,
              private translate: TranslateService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    let defaultDate: Date = new Date();
    this.editId = 0;
    this.showPracticeSelection = false;
    this.showVetSelection = false;

    this.route.queryParams.subscribe(data => {
      if (data && data["edit_id"] && data["edit_date"]) {
        defaultDate = data["edit_date"];
        this.editId = data["edit_id"];
      }
    });

    this.calendarService.selectedView = "agendaWeek";

    this.service.getAgendaPractices().subscribe(data => {
      this.practices = data;
      //selecteer automatisch eerste praktijk
      let practice: Practice = this.practices[0];
      this.selectedPracticeName = practice.name;
      this.calendarService.selectedPractice = practice.id;
      this.calendarService.selectedPracticeAddress = practice.address;

      if (this.storage.vetInfo.must_select_vet && practice.vets.length > 1) {
        this.showPracticeSelection = this.practices.length > 1;
        this.showVetSelection = true;
        for (let vet of practice.vets) {
          this.vets.push(new Vet(vet.id, vet.name));
          this.calendarService.addVet(vet.id, vet.name);
          if (this.selectedVetId && this.selectedVetId == vet.id) {
            this.selectedVetName = vet.name;
          }
        }
        if (!this.selectedVetId) {
          this.selectedVetName = this.translate.instant("calendar.action-select-vet");
          if (this.storage.showOnceInPopup) {
            const dialogRef = this.dialog.showDialogWithText(this.storage.showOnceInPopup);
            this.storage.showOnceInPopup = null;
            dialogRef.afterClosed().subscribe(result => {
              this.openSelectVetMenu();
            });
          } else {
            this.openSelectVetMenu();
          }
          this.service.getDefaultAgendaConfiguration(this.calendarService.selectedPractice).subscribe(data => {
            if (this.calendar) {
              this.calendar.destroy();
            }
            this.initCalendar(data, defaultDate);
          });
        } else {
          if (this.storage.showOnceInPopup) {
            this.dialog.showDialogWithText(this.storage.showOnceInPopup);
            this.storage.showOnceInPopup = null;
          }
          this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
            if (this.calendar) {
              this.calendar.destroy();
            }
            this.initCalendar(data, defaultDate);
          });
        }
      } else {
        if (practice.vets.length > 1) {
          this.selectedVetId = 0;
          for (let vet of practice.vets) {
            this.calendarService.addVet(vet.id, vet.name);
          }
        } else {
          let vet: VetResponse = practice.vets[0];
          this.selectedVetId = vet.id;
          this.calendarService.addVet(vet.id, vet.name);
        }

        this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
          if (this.calendar) {
            this.calendar.destroy();
          }
          this.initCalendar(data, defaultDate);
        });
      }
    });

    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(data => {
      if (this.calendarService.selectedPractice !== null && this.selectedVetId !== null) {
        this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
          if (this.calendar) {
            this.calendar.destroy();
          }
          this.initCalendar(data, defaultDate);
        });
      }
    });

    this.translate.onLangChange.subscribe(data => {
      if (this.calendarService.selectedPractice !== null && this.selectedVetId !== null) {
        this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
          if (this.calendar) {
            this.calendar.destroy();
          }
          this.initCalendar(data, defaultDate);
        });
      }
    });
  }

  private loadEvents(): void {
    this.service.getReservationsForWeek(this.calendarService.selectedPractice, this.selectedVetId, this.calendarService.selectedView, this.calendar.view.activeStart, this.calendar.view.activeEnd, this.editId).subscribe(data => {
      let events = this.renderEvents(data);

      this.calendar.getEvents().forEach((event) => {
        event.remove();
      });

      events.forEach(event => {
        this.calendar.addEvent(event);
      });
    });
  }

  private removeNewEvent(): void {
    this.calendar.getEvents().forEach((event) => {
      if (event.id === 'new') {
        event.remove();
      }
    });
  }

  private removeEditEvent(): void {
    this.calendar.getEvents().forEach((event) => {
      if (event.id === 'edit') {
        event.remove();
      }
    });
  }

  private openSelectPracticeMenu(): void {
    const dialogRef = this.dialog.open(SelectpracticeComponent, {
      width: '250px',
      data: {practices: this.practices}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.calendarService.selectedPractice = result.id;
        this.selectedPracticeName = result.name;
        this.calendarService.selectedPracticeAddress = result.address;

        this.vets = [];
        this.selectedVetId = null;

        for (let vet of result.vets) {
          this.vets.push(new Vet(vet.id, vet.name));
          this.calendarService.addVet(vet.id, vet.name);
          if (this.selectedVetId == null) {
            this.selectedVetId = vet.id
            this.selectedVetName = vet.name;
          }
        }

        let defaultDate: Date = this.calendar.getDate();

        this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
          if (this.calendar) {
            this.calendar.destroy();
          }
          this.initCalendar(data, defaultDate);
        });
      }
    });
  }

  private openSelectVetMenu(): void {
    const dialogRef = this.dialog.open(SelectvetComponent, {
      width: '250px',
      data: {vets: this.vets}
    });
    //console.log(element);
    //it is possible to reposition the dialog using the mousevent.target
    //dialogRef.updatePosition({ top: '50px', left: '50px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedVetId = result.id;
        this.selectedVetName = result.name;

        let defaultDate: Date = this.calendar.getDate();

        this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, null).subscribe(data => {
          if (this.calendar) {
            this.calendar.destroy();
          }
          this.initCalendar(data, defaultDate);
        });
      }
    });
  }

  private initCalendar(data: AgendaConfigurationResponse, defaultDate: Date): void {
    let calendarEl = document.getElementById('calendar');

    let view: string;
    let header: ToolbarInput;
    let buttonText: ButtonTextCompoundInput;

    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.calendarService.selectedView = "listWeek";
      view = "listWeek";
      if (this.showVetSelection) {
        header = {
          left: '',
          center: 'prev,next selectVet',
          right: ''
        };
        buttonText = {
          prev: this.translate.instant("calendar.previous-week.short"),
          next: this.translate.instant("calendar.next-week.short"),
        };
      } else {
        header = {
          left: '',
          center: 'prev,next',
          right: ''
        };
        buttonText = {
          prev: this.translate.instant("calendar.previous-week"),
          next: this.translate.instant("calendar.next-week"),
        };
      }
      $("#overlay").css("display", "none");
    } else {
      this.calendarService.selectedView = "agendaWeek";
      view = "timeGridWeek";
      if (this.showPracticeSelection) {
        header = {
          left: 'title',
          center: 'selectPractice selectVet',
          right: 'prev,next'
        };
      } else if (this.showVetSelection) {
        header = {
          left: 'title',
          center: 'selectVet',
          right: 'prev,next'
        };
      } else {
        header = {
          left: 'title',
          center: '',
          right: 'prev,next'
        };
      }
      buttonText = {
        prev: this.translate.instant("calendar.previous-week"),
        next: this.translate.instant("calendar.next-week"),
      };
    }

    let customButtons = {
      selectPractice: {
        text: this.selectedPracticeName + ' \u25BC',
        click: (element) => {
          this.openSelectPracticeMenu();
        }
      },
      selectVet: {
        text: this.selectedVetName + ' \u25BC',
        click: (element) => {
          this.openSelectVetMenu();
        }
      }
    };

    let validStart: string = this.getPlannableFrom();
    let validEnd: string = this.getPlannableUntil();

    this.calendar = new Calendar(calendarEl, {
      plugins: [timeGridPlugin, listPlugin],
      defaultView: view,
      defaultDate: defaultDate,
      height: "parent",
      locale: this.storage.locale,
      buttonText: buttonText,
      customButtons: customButtons,
      header: header,
      titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
      allDaySlot: false,
      businessHours: data.business_hours,
      minTime: "07:00:00",
      maxTime: "22:00:00",
      firstDay: 1,
      slotLabelInterval: "01:00",
      slotDuration: data.slot_duration,
      columnHeaderText: (date) => {
        let d: Date = date as Date;
        if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
          return formatDate(d, "EEEE", this.storage.locale) + "\n" + formatDate(d, "dd-MM-yyyy", this.storage.locale)
        } else {
          return formatDate(d, "EE", this.storage.locale) + "\n" + formatDate(d, "dd", this.storage.locale)
        }
      },
      displayEventTime: true,
      displayEventEnd: true,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit'
      },
      noEventsMessage: this.translate.instant("calendar.no-events"),
      validRange: {
        start: validStart,
        end: validEnd
      },
      events: (info, successCallback, failureCallback) => {
        this.service.getReservationsForWeek(this.calendarService.selectedPractice, this.selectedVetId, this.calendarService.selectedView, info.start, info.end, this.editId).subscribe(data => {
          let events = this.renderEvents(data);

          if (this.calendar) {
            this.calendar.getEvents().forEach((event) => {
              event.remove();
            });
          }

          this.service.getAgendaConfiguration(this.calendarService.selectedPractice, this.selectedVetId, info.start).subscribe(data => {
            this.calendar.setOption("businessHours", data.business_hours);
          });

          successCallback(events);
        });
      },
      eventRender: (info) => {
        if (info.event && info.event.rendering === "background") {
          info.el.appendChild(document.createTextNode(info.event.title));
        }
      },
      eventClick: (info) => {
        if (info.event.id !== "") {
          if (info.event.id === "available_hours") {
            let vetId: number = info.event.extendedProps.vet_id;
            if (!vetId) {
              vetId = 1;
            }
            if (this.editId > 0) {
              //remove edit event
              this.removeEditEvent();
              //save event
              let request: AppointmentRequest = new AppointmentRequest();
              //request.vet_id = this.calendarService.selectedVetId;
              request.vet_id = vetId;
              request.practice = this.calendarService.selectedPractice;
              request.date = info.event.start;
              request.start = formatDate(info.event.start, "HH:mm", "nl-BE");
              request.end = formatDate(info.event.end, "HH:mm", "nl-BE");
              this.service.updateAppointment(this.editId, request).subscribe(data => {
                this.dialog.showDialog("message.update-appointment-success");
                this.loadEvents();
              });
              //remove edit id
              this.editId = 0;
            } else {
              //remove other 'new' event
              this.removeNewEvent();
              //add the event
              let event: EventInput = {
                id: "new",
                title: "",
                start: info.event.start,
                end: info.event.end,
                editable: false,
                rendering: ""
              };
              this.calendar.addEvent(event);
              if (this.storage.vetInfo.use_appointment_selection) {
                this.dialog.open(AppointmentWithTypeComponent, {
                  width: "95vw",
                  maxWidth: "1035px",
                  maxHeight: "95vh",
                  panelClass: "small-padding-dialog-container",
                  disableClose: true,
                  autoFocus: false,
                  data: {
                    start: info.event.start,
                    end: info.event.end,
                    vetId: vetId
                  }
                }).afterClosed().subscribe(data => {
                  this.loadEvents();
                });
              } else {
                this.dialog.open(AppointmentComponent, {
                  width: "95vw",
                  maxWidth: "1035px",
                  maxHeight: "95vh",
                  panelClass: "small-padding-dialog-container",
                  disableClose: true,
                  autoFocus: false,
                  data: {
                    start: info.event.start,
                    end: info.event.end,
                    vetId: vetId
                  }
                }).afterClosed().subscribe(data => {
                  this.loadEvents();
                });
              }
            }
          }
        }
      },
    });

    if (this.calendar) {
      this.calendar.render();
    }

    if (!this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      $(".fc-scroller").scroll(this.handleScroll);
    }
  }

  private handleScroll(event) {
    if (event.currentTarget.scrollHeight === event.currentTarget.clientHeight + event.currentTarget.scrollTop) {
      $("#overlay").css("display", "none");
    } else {
      $("#overlay").css("display", "block");
    }
  }

  private getFirstDayOfWeek(): Date {
    let d: Date = new Date();
    let dayOfWeek = d.getDay();
    let firstDayOfWeek = new Date(d);
    let diff = dayOfWeek >= 1 ? dayOfWeek - 1 : 6 - dayOfWeek;
    firstDayOfWeek.setDate(d.getDate() - diff);
    firstDayOfWeek.setHours(0, 0, 0, 0);

    return firstDayOfWeek;
  }

  private getPlannableFrom(): string {
    return formatDate(this.getFirstDayOfWeek(), "yyyy-MM-dd", "nl-BE");
  }

  private getPlannableUntil(): string {
    let weeks: number = this.storage.vetInfo.plannable_weeks;
    if (this.storage.vetInfo.plannable_weeks) {
      weeks = this.storage.vetInfo.plannable_weeks + 1;
    } else {
      weeks = 20;
    }

    let d: Date = this.getFirstDayOfWeek();
    d.setDate(d.getDate() + (weeks * 7));
    return formatDate(d, "yyyy-MM-dd", "nl-BE");
  }

  private renderEvents(data: Array<ReservationResponse>): Array<any> {
    let events = [];

    /*let rendering:EventRenderingChoice;
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      rendering = "";
    } else {
      rendering = "background";
    }*/

    data.forEach(item => {
      let eventId: string;
      let eventTitle: string;
      let eventRendering: EventRenderingChoice;
      let eventColor: string;
      let className: string;

      switch (item.type) {
        case "AVAILABLE":
          let startTime: string = formatDate(item.start, "HH:mm", "nl-BE");
          let endTime: string = formatDate(item.end, "HH:mm", "nl-BE");

          eventId = "available_hours";
          if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
            eventTitle = this.translate.instant("calendar.type.available");
            eventRendering = "";
          } else {
            eventTitle = startTime + " - " + endTime;
            eventRendering = "background";
          }
          eventColor = "#8fdf82";
          className = "fc-color-black";
          break;
        case "OCCUPIED":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.occupied");
          eventRendering = "background";
          eventColor = "#262361";
          break;
        case "CONSULTATION":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.consultation");
          eventRendering = "background";
          eventColor = "#29aae1";
          break;
        case "MEDICINES":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.medicines");
          eventRendering = "background";
          eventColor = "#0d71b9";
          break;
        case "SURGERY":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.surgery");
          eventRendering = "background";
          eventColor = "#e66610";
          break;
        case "TRAINING":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.training");
          eventRendering = "background";
          eventColor = "#e2d20a";
          break;
        case "URGENCY":
          eventId = "";
          eventTitle = this.translate.instant("calendar.type.urgency");
          eventRendering = "background";
          eventColor = "#0d71b9";
          break;
        case "PASSED":
          eventId = "";
          eventTitle = "";
          eventRendering = "background";
          eventColor = "#d7d7d7";
          break;
        case "EDIT":
          eventId = "edit";
          eventTitle = "";
          eventRendering = "";
          break;
        case "CLOSING_DAY":
          eventTitle = item.title;
          break;
      }

      //  EDIT

      let event: EventInput = {
        id: eventId,
        title: eventTitle,
        start: item.start,
        end: item.end,
        editable: item.editable,
        backgroundColor: eventColor,
        className: className,
        rendering: eventRendering,
        extendedProps: item
      };
      events.push(event);
    });

    return events;
  }
}
