export class AppointmentEdit {
  id: number;
  patientName: string;
  date: Date;
  hour: string;
  endHour: string;
  location: string;
  appointmentTypeGroup: string;
  appointmentType: string;
  vetName: string;
  classification: string;
}
