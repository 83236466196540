import {Injectable} from '@angular/core';
import {Language} from "@app/model/language";
import {VetInfo} from "@app/model/vetinfo";


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _language: Language;
  private _vetInfo: VetInfo;
  private _version: string = "v2.1.0";

  private _customerId: number;
  private _customerName: string;
  private _temporary: boolean;

  private _showOnceInPopup: string;

  constructor() {
  }

  get language(): Language {
    return this._language;
  }

  set language(value: Language) {
    this._language = value;
  }

  get locale(): string {
    switch (this._language) {
      case Language.FR:
        return "fr";
      case Language.EN:
        return "en";
      case Language.DE:
        return "de";
      default:
        return "nl-BE";
    }
  }

  get vetInfo(): VetInfo {
    return this._vetInfo;
  }

  set vetInfo(value: VetInfo) {
    this._vetInfo = value;
  }

  get version(): string {
    return this._version;
  }

  set version(value: string) {
    this._version = value;
  }

  get customerId(): number {
    return this._customerId;
  }

  set customerId(value: number) {
    this._customerId = value;
  }

  get customerName(): string {
    return this._customerName;
  }

  set customerName(value: string) {
    this._customerName = value;
  }

  get temporary(): boolean {
    return this._temporary;
  }

  set temporary(value: boolean) {
    this._temporary = value;
  }


  get showOnceInPopup(): string {
    return this._showOnceInPopup;
  }

  set showOnceInPopup(value: string) {
    this._showOnceInPopup = value;
  }

  get info_title(): string {
    let info: string;
    switch (this._language) {
      case Language.FR:
        info = this._vetInfo.info_title_fr;
        break;
      case Language.EN:
        info = this._vetInfo.info_title_en;
        break;
      case Language.DE:
        info = this._vetInfo.info_title_de;
        break;
      default:
        return this._vetInfo.info_title_nl;
    }
    if (info) {
      return info;
    } else {
      return this._vetInfo.info_title_nl;
    }
  }

  get info_text(): string {
    let info: string;
    switch (this._language) {
      case Language.FR:
        info = this._vetInfo.info_text_fr;
        break;
      case Language.EN:
        info = this._vetInfo.info_text_en;
        break;
      case Language.DE:
        info = this._vetInfo.info_text_de;
        break;
      default:
        return this._vetInfo.info_text_nl;
    }
    if (info) {
      return info;
    } else {
      return this._vetInfo.info_text_nl;
    }
  }

  get popup_text(): string {
    let info: string;
    switch (this._language) {
      case Language.FR:
        info = this._vetInfo.popup_text_fr;
        break;
      case Language.EN:
        info = this._vetInfo.popup_text_en;
        break;
      case Language.DE:
        info = this._vetInfo.popup_text_de;
        break;
      default:
        return this._vetInfo.popup_text_nl;
    }
    if (info) {
      return info;
    } else {
      return this._vetInfo.popup_text_nl;
    }
  }

  get confirmation_text(): string {
    let info: string;
    switch (this._language) {
      case Language.FR:
        info = this._vetInfo.confirmation_text_fr;
        break;
      case Language.EN:
        info = this._vetInfo.confirmation_text_en;
        break;
      case Language.DE:
        info = this._vetInfo.confirmation_text_de;
        break;
      default:
        return this._vetInfo.confirmation_text_nl;
    }
    if (info) {
      return info;
    } else {
      return this._vetInfo.confirmation_text_nl;
    }
  }
}
