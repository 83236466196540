export class Register {
  last_name: string;
  first_name: string;
  phone: string;
  email: string;
  street: string;
  number: string;
  city: string;
  postal_code: string;
  characters: string;
  country: string;
  password: string;
  language: string;
}
