<mat-toolbar color="accent" class="titlebar" *ngIf="isNotHandset$ | async">
  <div class="left">
    <img src="{{logo}}" alt="" class="logo"/>
    <div class="title">
      <span class="company-name">{{companyName}}</span>
      <span class="company-title">{{companyTitle}}</span>
    </div>
  </div>

  <div class="center">
    <div *ngIf="!isLoggedIn">
      <button mat-flat-button color="accent" class="logon-btn" (click)="handleLogin()">{{'navigation.titlebar.login' | translate}}
        <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
      </button>
    </div>
    <div *ngIf="isLoggedIn">
      <button mat-flat-button color="accent" class="logon-btn" [matMenuTriggerFor]="menu">
        <span class="customer-name">{{'navigation.titlebar.welcome' | translate}} {{customerName}}</span>
        <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="handleLogout()">{{'navigation.titlebar.logout' | translate}}</button>
      </mat-menu>
    </div>
  </div>

  <div class="right">
    <mat-button-toggle-group name="languageSelection" aria-label="Language Selection" (change)="handleSelectLanguage($event.value)" [(ngModel)]="selectedLanguage">
      <mat-button-toggle value="NL" checked>NL</mat-button-toggle>
      <mat-button-toggle value="FR">FR</mat-button-toggle>
      <mat-button-toggle value="EN">EN</mat-button-toggle>
      <mat-button-toggle value="DE">DE</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</mat-toolbar>


<mat-toolbar color="accent" class="titlebar-mobile" *ngIf="isHandset$ | async">
  <button mat-icon-button type="button" aria-label="Toggle sidenav" (click)="toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <div class="content">
    <div class="upper">
      <span class="company-name">{{companyName}}</span>
    </div>
    <div class="lower">
      <div *ngIf="!isLoggedIn">
        <button mat-flat-button color="accent" class="logon-btn" (click)="handleLogin()">
          <span>{{'navigation.titlebar.login' | translate}}</span>
          <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
        </button>
      </div>
      <div *ngIf="isLoggedIn">
        <button mat-flat-button color="accent" class="logon-btn" [matMenuTriggerFor]="menu">
          <span>{{'navigation.titlebar.welcome' | translate}} {{customerName}}</span>
          <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="handleLogout()">{{'navigation.titlebar.logout' | translate}}</button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div>
    <button mat-flat-button color="accent" class="language-btn" [matMenuTriggerFor]="langmenu">
      <span>{{selectedLanguage}}</span>
      <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="language-menu-item" #langmenu="matMenu" >
      <button mat-menu-item (click)="handleSelectLanguage('nl')">NL</button>
      <button mat-menu-item (click)="handleSelectLanguage('fr')">FR</button>
      <button mat-menu-item (click)="handleSelectLanguage('en')">EN</button>
      <button mat-menu-item (click)="handleSelectLanguage('de')">DE</button>
    </mat-menu>
  </div>
</mat-toolbar>
