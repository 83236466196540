import {Injectable} from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private sidenav: MatSidenav;

  private titleBarVisibilitySource = new Subject<boolean>();
  public titleBarVisibility$ = this.titleBarVisibilitySource.asObservable();

  private menuItemClickedSource = new Subject<boolean>();
  public menuItemClicked$ = this.menuItemClickedSource.asObservable();

  constructor() {
  }

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  public showTitleBar(): void {
    this.titleBarVisibilitySource.next(true);
  }

  public hideTitleBar(): void {
    this.titleBarVisibilitySource.next(false);
  }

  public handleClicked(): void {
    this.menuItemClickedSource.next(true);
  }
}
