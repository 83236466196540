import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Vet} from "@app/public/appointment/model/vet";

@Component({
  selector: 'app-selectvet',
  templateUrl: './selectvet.component.html',
  styleUrls: ['./selectvet.component.scss']
})
export class SelectvetComponent implements OnInit {

  public vets: Array<Vet>;

  constructor(
    public dialogRef: MatDialogRef<SelectvetComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SelectVetData) {

    this.vets = data.vets;
  }

  ngOnInit() {
  }

  public onClick(): void {
    this.dialogRef.close(true);
  }

  public selectVet(vet: Vet) {
    this.dialogRef.close(vet);
  }
}

export interface SelectVetData {
  vets: Array<Vet>;
}
