import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginSource = new Subject<boolean>();
  public login$ = this.loginSource.asObservable();

  public path: string;
  private _isConnected: boolean;
  private _temporary: boolean;

  constructor() {
  }

  get isConnected(): boolean {
    return this._isConnected;
  }

  set isConnected(value: boolean) {
    this._isConnected = value;
  }

  get temporary(): boolean {
    return this._temporary;
  }

  set temporary(value: boolean) {
    this._temporary = value;
  }

  public handleLogin(): void {
    this.loginSource.next(true);
  }
}
