import {NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {SharedModule} from "@app/shared/shared.module";
import {MapComponent} from './map/map.component';
import {GoogleMapsModule} from "@angular/google-maps";

@NgModule({
    declarations: [
        HomeComponent,
        MapComponent
    ],
  imports: [
    SharedModule,
    GoogleMapsModule
  ],
    exports: [
        HomeComponent
    ]
})
export class HomeModule {
}
