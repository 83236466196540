import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export interface SnackbarData {
  key: string;
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  public message: string;

  constructor(private translate: TranslateService,
              @Inject(MAT_SNACK_BAR_DATA) private data: SnackbarData) {
  }

  ngOnInit() {
    this.message = this.translate.instant(this.data.key);
  }
}
