import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {StorageService} from "@app/core/storage/storage.service";

@Injectable({
  providedIn: 'root'
})
export class NoWizardService implements CanActivate {

  constructor(private storage: StorageService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.storage.vetInfo.use_alternate_agenda) {
      this.router.navigate(["home"]);
      return false;
    } else {
      return true;
    }
  }
}
