<div class="appointment">

  <div class="title">
    <h2 mat-dialog-title>{{'calendar.appointment.title' | translate}}</h2>
    <button mat-stroked-button type="button" *ngIf="isEditable" (click)="handleClose()">{{'common.action-close' | translate}}</button>
  </div>

  <mat-horizontal-stepper [linear]="true" #stepper>

    <mat-step [editable]="isEditable" [completed]="step0completed">
      <ng-template matStepLabel *ngIf="isNotHandset$ | async">{{'calendar.appointment.step0' | translate}}</ng-template>

      <div class="step0">
        <mat-dialog-content>
          <mat-grid-list cols="12" rowHeight="62" gutterSize="10">
            <mat-grid-tile [colspan]="cs_6_12$ | async" *ngFor="let type of appointmentTypes">
              <button mat-raised-button (click)="handleSelectAppointmentType(type)">{{type.translate}}</button>
            </mat-grid-tile>
          </mat-grid-list>

          <!--<mat-list>
            <mat-list-item *ngFor="let type of appointmentTypes">
              <button mat-raised-button (click)="handleSelectAppointmentType(type)">{{type.translate}}</button>
            </mat-list-item>
          </mat-list>-->
        </mat-dialog-content>
      </div>
    </mat-step>

    <mat-step [editable]="isEditable" [completed]="stepAccountcompleted">
      <ng-template matStepLabel *ngIf="isNotHandset$ | async">{{'calendar.appointment.step1' | translate}}</ng-template>

      <div class="step1">
        <form *ngIf="isLoggedIn || isTempLoggedIn" [formGroup]="accountForm">
          <mat-dialog-content>
            <mat-grid-list cols="12" rowHeight="62" gutterSize="10">
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.firstName' | translate}}" formControlName="first_name">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.lastName' | translate}}" formControlName="last_name">
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="email" placeholder="{{'common.email' | translate}}" formControlName="email">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.phone' | translate}}" formControlName="phone">
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="cs_9_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.address' | translate}}" formControlName="street">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_3_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.number' | translate}}" formControlName="number">
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="showCharacters ? (cs_2_10$ | async) : (cs_3_12$ | async)">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.postalcode' | translate}}" formControlName="postal_code">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile *ngIf="showCharacters" [colspan]="cs_1_2$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.characters' | translate}}" formControlName="characters">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.city' | translate}}" formControlName="city">
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_3_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'common.country' | translate}}" formControlName="country">
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-dialog-content>

          <mat-dialog-actions class="button-center">
            <button mat-stroked-button matStepperNext type="button" color="primary">{{'calendar.appointment.action-next' | translate}}</button>
          </mat-dialog-actions>
        </form>

        <div *ngIf="!isLoggedIn && !isTempLoggedIn" class="logon-selection">
          <mat-accordion>
            <mat-expansion-panel expanded="true" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title class="login-title">{{'calendar.appointment.login.step1' | translate}}</mat-panel-title>
              </mat-expansion-panel-header>
              <app-login (onLoginComplete)="handleLoginComplete()"></app-login>
            </mat-expansion-panel>

            <mat-expansion-panel expanded="false" hideToggle [disabled]="isLockedRegistration" matTooltip="{{'message.registration-locked' | translate}}" [matTooltipDisabled]="!isLockedRegistration">
              <mat-expansion-panel-header>
                <mat-panel-title class="login-title">{{'calendar.appointment.login.step2' | translate}}</mat-panel-title>
              </mat-expansion-panel-header>
              <app-register (onRegisterComplete)="handleRegisterComplete()"></app-register>
            </mat-expansion-panel>

            <mat-expansion-panel expanded="false" hideToggle [disabled]="isLockedRegistration" matTooltip="{{'message.registration-locked' | translate}}" [matTooltipDisabled]="!isLockedRegistration">
              <mat-expansion-panel-header>
                <mat-panel-title class="login-title">{{'calendar.appointment.login.step3' | translate}}</mat-panel-title>
              </mat-expansion-panel-header>

              <form class="guest-account-form" [formGroup]="guestAccountForm" (ngSubmit)="handleSubmitGuestAccount()">
                <mat-grid-list cols="12" rowHeight="63" gutterSize="10" class="guest-account-grid">
                  <mat-grid-tile [colspan]="cs_6_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="text" placeholder="{{'common.firstName' | translate}}" formControlName="firstName" required>
                      <mat-error *ngIf="guestAccountForm.get('firstName').errors && guestAccountForm.get('firstName').errors.required">
                        <span>{{'error.required.firstName' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="cs_6_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="text" placeholder="{{'common.lastName' | translate}}" formControlName="lastName" required>
                      <mat-error *ngIf="guestAccountForm.get('lastName').errors && guestAccountForm.get('lastName').errors.required">
                        <span>{{'error.required.lastName' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="cs_6_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="email" placeholder="{{'common.email' | translate}}" formControlName="email" required>
                      <mat-error *ngIf="guestAccountForm.get('email').errors">
                        <span *ngIf="guestAccountForm.get('email').errors.required">{{'error.required.email' | translate}}</span>
                        <span *ngIf="guestAccountForm.get('email').errors.email">{{'error.format.email' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="cs_6_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="text" placeholder="{{'common.phone' | translate}}" formControlName="phone" required>
                      <mat-error *ngIf="guestAccountForm.get('phone').errors">
                        <span *ngIf="guestAccountForm.get('phone').errors.required">{{'error.required.phone' | translate}}</span>
                        <span *ngIf="guestAccountForm.get('phone').errors.phone">{{'error.format.nonStrictPhone' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="cs_9_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput tab-directive type="text" placeholder="{{'common.address' | translate}}" formControlName="street"
                             [matAutocomplete]="autoAddress" required>
                      <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="addressSelected($event)" autoActiveFirstOption="true">
                        <mat-option *ngFor="let address of addresses$ | async" [value]="address">
                          {{address.street}} - {{address.postal_code}} {{address.city}} - {{address.country}}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="guestAccountForm.get('street').errors && guestAccountForm.get('street').errors.required">
                        <span>{{'error.required.street' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="cs_3_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="text" placeholder="{{'common.number' | translate}}" formControlName="number" required>
                      <mat-error *ngIf="guestAccountForm.get('number').errors && guestAccountForm.get('number').errors.required">
                        <span>{{'error.required.number' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="showCharacters ? (cs_2_10$ | async) : (cs_3_12$ | async)">
                    <mat-form-field class="form-field">
                      <input matInput tab-directive type="text" placeholder="{{'common.postalcode' | translate}}" formControlName="postalCode"
                             [matAutocomplete]="autoPostalCode" required>
                      <mat-autocomplete #autoPostalCode="matAutocomplete" (optionSelected)="citySelected($event)" panelWidth="300px" autoActiveFirstOption="true">
                        <mat-option *ngFor="let city of postalCodes$ | async" [value]="city">
                          {{city.postal_code}} {{city.city}}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="guestAccountForm.get('postalCode').errors && guestAccountForm.get('postalCode').errors.required">
                        <span>{{'error.required.postalCode' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile *ngIf="showCharacters" [colspan]="cs_1_2$ | async">
                    <mat-form-field class="form-field">
                      <input matInput type="text" placeholder="{{'common.characters' | translate}}" formControlName="characters">
                      <mat-error *ngIf="guestAccountForm.get('characters').errors && guestAccountForm.get('characters').errors.required">
                        <span>{{'error.required.characters' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="cs_6_12$ | async">
                    <mat-form-field class="form-field">
                      <input matInput tab-directive type="text" placeholder="{{'common.city' | translate}}" formControlName="city"
                             [matAutocomplete]="autoCity" required>
                      <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="citySelected($event)" autoActiveFirstOption="true">
                        <mat-option *ngFor="let city of cities$ | async" [value]="city">
                          {{city.city}} {{city.postal_code}}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="guestAccountForm.get('city').errors && guestAccountForm.get('city').errors.required">
                        <span>{{'error.required.city' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="cs_3_12$ | async">
                    <mat-form-field class="form-field">
                      <mat-label>{{'common.country' | translate}}</mat-label>
                      <mat-select formControlName="country" required (selectionChange)="countrySelected($event)">
                        <mat-option value="België">{{'common.code.country.be' | translate}}</mat-option>
                        <mat-option value="Nederland">{{'common.code.country.nl' | translate}}</mat-option>
                        <mat-option value="Duitsland">{{'common.code.country.de' | translate}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="guestAccountForm.get('country').errors && guestAccountForm.get('country').errors.required">
                        <span>{{'error.required.country' | translate}}</span>
                      </mat-error>
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>

                <div class="button-center">
                  <button mat-raised-button type="submit" color="primary" class="submit" [disabled]="!guestAccountForm.valid">{{'calendar.appointment.guest.action-submit' | translate}}</button>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-step>

    <mat-step [editable]="isEditable" [completed]="stepPatientcompleted">
      <ng-template matStepLabel *ngIf="isNotHandset$ | async">{{'calendar.appointment.step2' | translate}}</ng-template>

      <div class="step2">
        <div *ngIf="isLoggedIn && !isTempLoggedIn">
          <mat-dialog-content>
            <div class="card-list">
              <mat-card class="card" *ngFor="let patient of patients">
                <mat-card-header>
                  <img mat-card-avatar class="header-image" src="{{getPicture(patient.picture)}}"/>
                  <mat-card-title>{{patient.name}}</mat-card-title>
                  <mat-card-subtitle>{{patient.breed}}</mat-card-subtitle>
                  <mat-card-subtitle>{{getGender(patient.gender)}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-actions class="buttons">
                  <mat-checkbox (change)="handleSelectPet($event, patient.id, patient.name)">{{'calendar.appointment.action-select-patient' | translate}}</mat-checkbox>
                </mat-card-actions>
              </mat-card>

              <mat-card class="card">
                <mat-card-content class="card-content-new">
                  <button mat-button (click)="handleCreatePet()">
                    <div class="add-button">{{'calendar.appointment.action-add-patient' | translate}}</div>
                    <!--<mat-icon class="add-button">add</mat-icon>-->
                  </button>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions class="button-center">
            <button mat-stroked-button matStepperPrevious type="button" color="primary">{{'calendar.appointment.action-previous' | translate}}</button>
            <button mat-stroked-button matStepperNext type="button" color="primary"
                    [disabled]="!stepPatientcompleted">{{'calendar.appointment.action-next' | translate}}</button>
          </mat-dialog-actions>
        </div>

        <form *ngIf="!isLoggedIn" [formGroup]="guestPatientForm" (ngSubmit)="handleSubmitGuestPatient()">
          <mat-dialog-content>
            <mat-grid-list cols="12" rowHeight="63" gutterSize="10" class="list guest-patient-grid">
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <input matInput type="text" placeholder="{{'pet.name' | translate}}" formControlName="patientName" required>
                  <mat-error *ngIf="guestPatientForm.get('patientName').errors && guestPatientForm.get('patientName').errors.required">
                    <span>{{'error.required.patientName' | translate}}</span>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <mat-label>{{'pet.dateOfBirth' | translate}}</mat-label>
                  <input matInput placeholder="dd/mm/jjjj" formControlName="dateOfBirth" [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <mat-form-field class="form-field">
                  <mat-label>{{'pet.gender' | translate}}</mat-label>
                  <mat-select formControlName="gender" required>
                    <mat-option value="M">{{'pet.genderType.M' | translate}}</mat-option>
                    <mat-option value="MN">{{'pet.genderType.MN' | translate}}</mat-option>
                    <mat-option value="V">{{'pet.genderType.V' | translate}}</mat-option>
                    <mat-option value="VN">{{'pet.genderType.VN' | translate}}</mat-option>
                    <mat-option value="X">{{'pet.genderType.X' | translate}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="guestPatientForm.get('gender').errors && guestPatientForm.get('gender').errors.required">
                    <span>{{'error.required.gender' | translate}}</span>
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async">
                <div class="form-field">
                  <mat-radio-group aria-label="Kies een ras" class="guest-patient-breed-radio-group" (change)="breedOptionSelected($event)" required formControlName="breed_type">
                    <mat-radio-button value="Dog" class="guest-patient-breed-radio">{{"pet.defaultbreed.dog" | translate}}</mat-radio-button>
                    <mat-radio-button value="Cat" class="guest-patient-breed-radio">{{"pet.defaultbreed.cat" | translate}}</mat-radio-button>
                    <mat-radio-button value="Other" class="guest-patient-breed-radio">{{"pet.defaultbreed.other" | translate}}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </mat-grid-tile>
              <mat-grid-tile colspan="6" *ngIf="isNotHandset$ | async">
              </mat-grid-tile>
              <mat-grid-tile [colspan]="cs_6_12$ | async" *ngIf="showBreedInput">
                <mat-form-field class="form-field">
                  <input matInput tab-directive type="text" placeholder="{{'pet.breedSelect' | translate}}" formControlName="breed" [required]="showOtherBreed" [matAutocomplete]="autoBreed">
                  <mat-autocomplete #autoBreed="matAutocomplete" [displayWith]="displayFnBreed" (optionSelected)="breedSelected($event)" autoActiveFirstOption="true">
                    <mat-option *ngFor="let breed of breeds$ | async" [value]="breed">
                      {{breed.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-dialog-content>

          <mat-dialog-actions class="button-center">
            <button mat-stroked-button matStepperPrevious type="button" color="primary">{{'calendar.appointment.action-previous' | translate}}</button>
            <button mat-stroked-button type="submit" color="primary" [disabled]="!guestPatientForm.valid">{{'calendar.appointment.action-next' | translate}}</button>
          </mat-dialog-actions>
        </form>
      </div>
    </mat-step>

    <mat-step [editable]="isEditable" [stepControl]="confirmForm">
      <ng-template matStepLabel *ngIf="isNotHandset$ | async">{{'calendar.appointment.step3' | translate}}</ng-template>

      <div class="step3">
        <form [formGroup]="confirmForm" (ngSubmit)="handleSubmit()">
          <mat-dialog-content>
            <div class="confirm-overview">
              <div class="line"><mat-icon>face</mat-icon><span class="text">{{vet}}</span></div>
              <div class="line"><mat-icon>place</mat-icon><span class="text">{{location}}</span></div>
              <div class="line"><mat-icon>insert_invitation</mat-icon><span class="text">{{day}}</span></div>
              <div class="line"><mat-icon>query_builder</mat-icon><span class="text">{{time}}</span></div>
              <div class="line"><mat-icon>category</mat-icon><span class="text">{{appointmentType}}</span></div>
              <div class="line"><mat-icon>pets</mat-icon><span class="text">{{pets}}</span></div></div>

            <mat-form-field class="confirm-form-field">
              <textarea matInput placeholder="{{'calendar.appointment.more-info' | translate}}" [formControl]="reasonInput" rows="4"></textarea>
              <mat-error *ngIf="reasonInput.hasError('required')">
                <span>{{'error.required.reason' | translate}}</span>
              </mat-error>
              <mat-error *ngIf="reasonInput.hasError('minlength')">
                <span>{{'error.minlength.reason' | translate}}</span>
              </mat-error>
            </mat-form-field>
          </mat-dialog-content>

          <mat-dialog-actions class="button-center">
            <button mat-stroked-button matStepperPrevious type="button" color="primary">{{'calendar.appointment.action-previous' | translate}}</button>
            <button mat-raised-button type="submit" color="primary" [disabled]="!confirmForm.valid"
                    class="submit">{{'calendar.appointment.action-confirm' | translate}}</button>
          </mat-dialog-actions>
        </form>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel *ngIf="isNotHandset$ | async">{{'calendar.appointment.step4' | translate}}</ng-template>

      <div class="step4">
        <mat-dialog-content>
          {{'calendar.appointment.confirmation1' | translate}}<br>
          {{'calendar.appointment.confirmation2' | translate}}<br><br>
          <div *ngIf="hasConfirmationMessage">
            {{confirmationMessage}}<br><br>
          </div>
          <a href="{{companyWebsite}}" target="_self">{{'calendar.appointment.confirmation-action-return' | translate}}</a> {{'calendar.appointment.confirmation3' | translate}}
        </mat-dialog-content>
        <mat-dialog-actions class="button-center">
          <button mat-stroked-button mat-dialog-close type="button">{{'common.action-close' | translate}}</button>
        </mat-dialog-actions>
      </div>
    </mat-step>

  </mat-horizontal-stepper>
</div>
