import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from "@app/core/dialog/dialog.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface MapData {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  public center: google.maps.LatLngLiteral;
  public zoom: number = 16;

  constructor(private dialog: DialogService,
              @Inject(MAT_DIALOG_DATA) private data: MapData) {
  }

  ngOnInit() {
    this.center = {lat: this.data.lat, lng: this.data.lng}
  }

}
