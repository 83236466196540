<form [formGroup]="petForm" (ngSubmit)="handleSubmit()" class="pet">

  <h1 mat-dialog-title>{{title}}</h1>

  <mat-dialog-content>
    <mat-grid-list cols="12" rowHeight="63" gutterSize="10">
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <input matInput type="text" placeholder="{{'pet.name' | translate}}" formControlName="name" required>
          <mat-error *ngIf="petForm.get('name').errors && petForm.get('name').errors.required">
            <span>{{'error.required.patientName' | translate}}</span>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <mat-label>{{'pet.dateOfBirth' | translate}}</mat-label>
          <input matInput placeholder="dd/mm/jjjj" formControlName="date_of_birth" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <mat-label>{{'pet.gender' | translate}}</mat-label>
          <mat-select formControlName="gender" required>
            <mat-option value="M">{{'pet.genderType.M' | translate}}</mat-option>
            <mat-option value="MN">{{'pet.genderType.MN' | translate}}</mat-option>
            <mat-option value="V">{{'pet.genderType.V' | translate}}</mat-option>
            <mat-option value="VN">{{'pet.genderType.VN' | translate}}</mat-option>
            <mat-option value="X">{{'pet.genderType.X' | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="petForm.get('gender').errors && petForm.get('gender').errors.required">
            <span>{{'error.required.gender' | translate}}</span>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <div class="form-field">
          <mat-radio-group aria-label="Kies een ras" class="breed-radio-group" (change)="breedOptionSelected($event)" required #radio
                           formControlName="breed_type">
            <mat-radio-button value="Dog" class="breed-radio">{{'pet.defaultbreed.dog' | translate}}</mat-radio-button>
            <mat-radio-button value="Cat" class="breed-radio">{{'pet.defaultbreed.cat' | translate}}</mat-radio-button>
            <mat-radio-button value="Other" class="breed-radio">{{'pet.defaultbreed.other' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="6" *ngIf="isNotHandset$ | async">
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field" *ngIf="showBreedInput">
          <input matInput tab-directive type="text" placeholder="{{'pet.breedSelect' | translate}}" formControlName="breed" [required]="showOtherBreed"
                 [matAutocomplete]="autoBreed">
          <mat-autocomplete #autoBreed="matAutocomplete" [displayWith]="displayFnBreed" (optionSelected)="breedSelected($event)" autoActiveFirstOption="true">
            <mat-option *ngFor="let breed of breeds$ | async" [value]="breed">
              {{breed.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <input matInput tab-directive type="text" placeholder="{{'pet.hairColour' | translate}}" formControlName="hair_colour"
                 [matAutocomplete]="autoHairColour">
          <mat-autocomplete #autoHairColour="matAutocomplete" autoActiveFirstOption="true">
            <mat-option *ngFor="let hair of hairColours" [value]="hair">
              {{hair}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <mat-label>{{'pet.hair' | translate}}</mat-label>
          <mat-select formControlName="hair">
            <mat-option value="kortharig">{{'pet.hairType.short' | translate}}</mat-option>
            <mat-option value="langharig">{{'pet.hairType.long' | translate}}</mat-option>
            <mat-option value="ruwharig">{{'pet.hairType.raw' | translate}}</mat-option>
            <mat-option value="stockharig">{{'pet.hairType.stock' | translate}}</mat-option>
            <mat-option value="wolharig">{{'pet.hairType.wol' | translate}}</mat-option>
            <mat-option value="zijdeharig">{{'pet.hairType.silk' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <input matInput type="text" placeholder="{{'pet.identification' | translate}}" formControlName="identification">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="cs_6_12$ | async">
        <mat-form-field class="form-field">
          <input matInput type="text" placeholder="{{'pet.passport' | translate}}" formControlName="passport">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-dialog-content>

  <mat-dialog-actions class="button-center">
    <button mat-raised-button color="primary" type="submit" [disabled]="!petForm.valid" class="appointment-button">{{'pet.action-submit' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>

</form>

