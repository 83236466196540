<mat-sidenav-container [ngClass]="isVisibleTitlebar ? 'sidenav-container' : 'sidenav-container-full' ">
  <mat-sidenav #sidenav class="sidenav" fixedInViewport="true" fixedTopGap="60"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list class="sidenav-list">
      <mat-list-item *ngFor="let route of nav">
        <a matLine routerLink="{{route.path}}" routerLinkActive="active" (click)="handleClick()">{{route.title}}</a>
      </mat-list-item>
    </mat-nav-list>

    <div class="message" *ngIf="hasMessage && isNotHandset$ | async">
      <div class="title">{{messageTitle}}</div>
      <div class="content">{{messageContent}}</div>
    </div>

    <!--<div class="version">{{version}}</div>-->
  </mat-sidenav>
  <mat-sidenav-content>

    <ng-content select="[app-body]"></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>
