import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {CodedResponse} from "@app/model/coded.response";
import {AppointmentResponse} from "@app/secured/my-appointments/model/appointment.response";

@Injectable({
  providedIn: 'root'
})
export class MyAppointmentsService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findFutureAppointmentsForRegisteredCustomer(customerId: number): Observable<Array<AppointmentResponse>> {
    return this.httpClient.get<AppointmentResponseObject>(`${environment.baseUrl}/customer/${customerId}/appointment/list`, this.security.getHttpOptions())
      .pipe(
        map((response: AppointmentResponseObject) => {
          return response.reservations;
        }));
  }

  public findFutureAppointmentsForTempCustomer(customerId: number): Observable<Array<AppointmentResponse>> {
    return this.httpClient.get<AppointmentResponseObject>(`${environment.baseUrl}/customer/temp/${customerId}/appointment/list`, this.security.getHttpOptions())
      .pipe(
        map((response: AppointmentResponseObject) => {
          return response.reservations;
        }));
  }

  public deleteAppointment(id: number): Observable<CodedResponse> {
    return this.httpClient.delete<CodedResponse>(`${environment.baseUrl}/appointment/${id}`, this.security.getHttpOptions());
  }
}

export interface AppointmentResponseObject {
  reservations: Array<AppointmentResponse>;
}
