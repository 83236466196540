import {NgModule} from '@angular/core';
import {SharedModule} from "@app/shared/shared.module";
import {ChangePasswordComponent} from "@app/secured/my-data/change-password/change-password.component";
import {MyDataComponent} from "@app/secured/my-data/my-data/my-data.component";
import { AccountsComponent } from './accounts/accounts.component';
import { AccountComponent } from './account/account.component';

@NgModule({
    declarations: [
        MyDataComponent,
        ChangePasswordComponent,
        AccountsComponent,
        AccountComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        MyDataComponent
    ]
})
export class MyDataModule {
}
