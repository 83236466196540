import {NgModule} from '@angular/core';
import {AppointmentComponent} from './appointment/appointment.component';
import {SharedModule} from "@app/shared/shared.module";
import {CalendarComponent} from './calendar/calendar.component';
import {WidgetModule} from "@app/widget/widget.module";
import {SelectvetComponent} from './selectvet/selectvet.component';
import {AppointmentWithTypeComponent} from "@app/public/appointment/appointmentwithtype/appointmentwithtype.component";
import {WizardComponent} from './wizard/wizard.component';
import {AppointmentWizard} from "@app/public/appointment/appointment.wizard";
import {SelectpracticeComponent} from './selectpractice/selectpractice.component';

@NgModule({
  declarations: [
    AppointmentComponent,
    AppointmentWithTypeComponent,
    CalendarComponent,
    SelectvetComponent,
    WizardComponent,
    AppointmentWizard,
    SelectpracticeComponent
  ],
  imports: [
    SharedModule,
    WidgetModule
  ],
  exports: [
    CalendarComponent,
    WizardComponent
  ]
})
export class AppointmentModule {
}
