import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MyDataService} from "@app/secured/my-data/my-data.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSelectChange } from "@angular/material/select";
import {CustomValidators} from "@app/shared/validators/custom.validator";
import {debounceTime, distinctUntilChanged, startWith, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {AddressService} from "@app/shared/services/address.service";
import {AddressResponse} from "@app/shared/model/address.response";
import {CityResponse} from "@app/shared/model/city.response";
import {Customer} from "@app/secured/my-data/model/customer.model";
import {AccountsComponent} from "@app/secured/my-data/accounts/accounts.component";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent extends ResponsiveComponent implements OnInit {

  public myDataForm: UntypedFormGroup;
  public showCharacters: boolean;
  public showSmsControl: boolean;

  public addresses$: Observable<Array<AddressResponse>>;
  public postalCodes$: Observable<Array<CityResponse>>;
  public cities$: Observable<Array<CityResponse>>;

  private charactersInput: UntypedFormControl;
  private cityInput: UntypedFormControl;
  private countryInput: UntypedFormControl;
  private numberInput: UntypedFormControl;
  private postalCodeInput: UntypedFormControl;
  private streetInput: UntypedFormControl;

  constructor(breakpointObserver: BreakpointObserver,
              private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private service: MyDataService,
              private addressService: AddressService) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.showSmsControl = this.storage.vetInfo.show_notify_by_sms;

    this.charactersInput = this.formBuilder.control("", []);
    this.cityInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.countryInput = this.formBuilder.control("België", [Validators.required, Validators.minLength(3)]);
    this.numberInput = this.formBuilder.control("", [Validators.required, Validators.minLength(1)]);
    this.postalCodeInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);
    this.streetInput = this.formBuilder.control("", [Validators.required, Validators.minLength(2)]);

    this.myDataForm = this.formBuilder.group({
      characters: this.charactersInput,
      city: this.cityInput,
      country: this.countryInput,
      email: this.formBuilder.control("", [Validators.required, CustomValidators.email]),
      first_name: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      language: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      last_name: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      mail: this.formBuilder.control("", []),
      mobile1: this.formBuilder.control("", [CustomValidators.mobile]),
      mobile2: this.formBuilder.control("", [CustomValidators.mobile]),
      nat_reg: this.formBuilder.control("", []),
      newsletter: this.formBuilder.control("", []),
      number: this.numberInput,
      phone: this.formBuilder.control("", [Validators.required, CustomValidators.phone]),
      postal_code: this.postalCodeInput,
      street: this.streetInput,
      sms: this.formBuilder.control("", [])
    });

    this.addresses$ = this.streetInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findAddressess(value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.postalCodes$ = this.postalCodeInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByPostalCode(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.cities$ = this.cityInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.addressService.findCityByName(value, this.countryInput.value);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    this.load();
  }

  public handleSubmit(): void {
    if (this.myDataForm.valid) {
      this.service.updateCustomer(this.storage.customerId, this.myDataForm.value).subscribe(data => {
        this.dialog.showDialog("message.customer-update-success");
      }, error => {
        this.dialog.showError("error.server");
      });
    }
  }

  public handleOpenAccounts(): void {
    this.dialog.open(AccountsComponent, {
      disableClose: true,
      minWidth: 300,
      data: {
        email: this.myDataForm.value.email
      }
    });
  }

  public addressSelected(event: MatAutocompleteSelectedEvent): void {
    this.streetInput.patchValue(event.option.value.street);
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
    this.countryInput.patchValue(event.option.value.country);

    this.showCharacters = this.isNL(event.option.value.country);
  }

  public citySelected(event: MatAutocompleteSelectedEvent): void {
    this.postalCodeInput.patchValue(event.option.value.postal_code);
    this.charactersInput.patchValue(event.option.value.characters);
    this.cityInput.patchValue(event.option.value.city);
  }

  public countrySelected($event: MatSelectChange): void {
    this.showCharacters = $event && this.isNL($event.value);

    this.postalCodeInput.patchValue("");
    this.charactersInput.patchValue("");
    this.cityInput.patchValue("");
  }

  public languageSelected($event: MatSelectChange): void {

  }

  private load(): void {
    this.service.findCustomer(this.storage.customerId).subscribe(data => {
      this.myDataForm.setValue(new Customer(data));
    }, error => {
      this.dialog.showError("error.server");
    });
  }

  private isNL(country: string): boolean {
    return country === "Nederland";
  }

}
