import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyDataService} from "@app/secured/my-data/my-data.service";
import {ConfirmPasswordValidator} from "@app/shared/validators/confirm-password.validator";
import {CustomValidators} from "@app/shared/validators/custom.validator";
import {Account} from "@app/secured/my-data/model/account.model";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public form: UntypedFormGroup;

  private emailInput: UntypedFormControl;
  private passwordInput: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<AccountComponent>,
              private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private service: MyDataService) {
  }

  ngOnInit() {
    this.emailInput = this.formBuilder.control("", [Validators.required, CustomValidators.email]);
    this.passwordInput = this.formBuilder.control("", [Validators.required, Validators.minLength(3)]);

    this.form = this.formBuilder.group({
      email: this.emailInput,
      password: this.passwordInput,
      confirm_password: this.formBuilder.control("", [Validators.required, Validators.minLength(3)])
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  public handleSubmit(): void {
    if (this.form.valid) {
      let request: Account = new Account();
      request.email = this.emailInput.value;
      request.wachtwoord = this.passwordInput.value;

      this.service.createAccount(this.storage.customerId, request).subscribe(data => {
        this.dialogRef.close();
        this.dialog.showDialog("message.create-account-success");
      }, error => {
        this.dialogRef.close();
        this.dialog.showError("error.server");
      });
    }
  }

}
