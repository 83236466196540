<form [formGroup]="form" (ngSubmit)="handleSubmit()">

  <h1 mat-dialog-title>{{'mydata.change-password.title' | translate}}</h1>

  <mat-dialog-content class="change-password">
    <mat-form-field>
      <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="{{'mydata.change-password.password' | translate}}" formControlName="password" required>
      <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
        <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="form.get('password').errors && form.get('password').errors.required">
        <span>{{'error.required.password' | translate}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput [type]="hide2 ? 'password' : 'text'" placeholder="{{'mydata.change-password.confirmPassword' | translate}}" formControlName="confirm_password" required>
      <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
        <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="form.get('confirm_password').errors">
        <span *ngIf="form.get('confirm_password').errors.required">{{'error.required.confirmPassword' | translate}}</span>
        <span *ngIf="form.get('confirm_password').errors.ConfirmPassword">{{'error.passwordMatch' | translate}}</span>
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit"
            [disabled]="!form.valid">{{'mydata.change-password.action-submit' | translate}}</button>
    <button mat-button type="button" mat-dialog-close>{{'common.action-close' | translate}}</button>
  </mat-dialog-actions>

</form>
