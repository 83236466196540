<div class="center">
  <div class="my-data">
    <h1>{{'mydata.title' | translate}}</h1>
    <form [formGroup]="myDataForm" (ngSubmit)="handleSubmit()">

      <mat-grid-list cols="12" rowHeight="63" gutterSize="10" class="list">
        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'common.lastName' | translate}}" formControlName="last_name" required>
            <mat-error *ngIf="myDataForm.get('last_name').errors && myDataForm.get('last_name').errors.required">
              <span>{{'error.required.lastName' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'common.firstName' | translate}}" formControlName="first_name" required>
            <mat-error *ngIf="myDataForm.get('first_name').errors && myDataForm.get('first_name').errors.required">
              <span>{{'error.required.firstName' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_9_12$ | async">
          <mat-form-field class="form-field">
            <input matInput tab-directive type="text" placeholder="{{'common.address' | translate}}" formControlName="street" [matAutocomplete]="autoAddress" required>
            <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="addressSelected($event)" autoActiveFirstOption="true">
              <mat-option *ngFor="let address of addresses$ | async" [value]="address">
                {{address.street}} - {{address.postal_code}} {{address.city}} - {{address.country}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myDataForm.get('street').errors && myDataForm.get('street').errors.required">
              <span>{{'error.required.street' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_3_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'common.number' | translate}}" formControlName="number" required>
            <mat-error *ngIf="myDataForm.get('number').errors && myDataForm.get('number').errors.required">
              <span>{{'error.required.number' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="showCharacters ? (cs_2_10$ | async) : (cs_3_12$ | async)">
          <mat-form-field class="form-field">
            <input matInput tab-directive type="text" placeholder="{{'common.postalcode' | translate}}" formControlName="postal_code" [matAutocomplete]="autoPostalCode" required>
            <mat-autocomplete #autoPostalCode="matAutocomplete" (optionSelected)="citySelected($event)" panelWidth="300px" autoActiveFirstOption="true">
              <mat-option *ngFor="let city of postalCodes$ | async" [value]="city">
                {{city.postal_code}} {{city.city}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myDataForm.get('postal_code').errors && myDataForm.get('postal_code').errors.required">
              <span>{{'error.required.postalCode' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="showCharacters" [colspan]="cs_1_2$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'common.characters' | translate}}" formControlName="characters" required>
            <mat-error *ngIf="myDataForm.get('characters').errors && myDataForm.get('characters').errors.required">
              <span>{{'error.required.characters' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput tab-directive type="text" placeholder="{{'common.city' | translate}}" formControlName="city" [matAutocomplete]="autoCity" required>
            <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="citySelected($event)" autoActiveFirstOption="true">
              <mat-option *ngFor="let city of cities$ | async" [value]="city">
                {{city.city}} {{city.postal_code}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myDataForm.get('city').errors && myDataForm.get('city').errors.required">
              <span>{{'error.required.city' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="cs_3_12$ | async">
          <mat-form-field class="form-field">
            <mat-label>{{'common.country' | translate}}</mat-label>
            <mat-select formControlName="country" required (selectionChange)="countrySelected($event)">
              <mat-option value="België">{{'common.code.country.be' | translate}}</mat-option>
              <mat-option value="Nederland">{{'common.code.country.nl' | translate}}</mat-option>
              <mat-option value="Duitsland">{{'common.code.country.de' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="myDataForm.get('country').errors && myDataForm.get('country').errors.required">
              <span>{{'error.required.country' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="email" placeholder="{{'common.email' | translate}}" formControlName="email" required>
            <mat-error *ngIf="myDataForm.get('email').errors">
              <span *ngIf="myDataForm.get('email').errors.required">{{'error.required.email' | translate}}</span>
              <span *ngIf="myDataForm.get('email').errors.email">{{'error.format.email' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'common.phone' | translate}}" formControlName="phone" required>
            <mat-error *ngIf="myDataForm.get('phone').errors">
              <span *ngIf="myDataForm.get('phone').errors.required">{{'error.required.phone' | translate}}</span>
              <span *ngIf="myDataForm.get('phone').errors.phone">{{'error.format.phone' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'mydata.mobile1' | translate}}" formControlName="mobile1">
            <mat-error *ngIf="myDataForm.get('mobile1').errors">
              <span *ngIf="myDataForm.get('mobile1').errors.mobile">{{'error.format.mobile' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'mydata.mobile2' | translate}}" formControlName="mobile2">
            <mat-error *ngIf="myDataForm.get('mobile2').errors">
              <span *ngIf="myDataForm.get('mobile2').errors.mobile">{{'error.format.mobile' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <mat-label>{{'mydata.language' | translate}}</mat-label>
            <mat-select formControlName="language" required (selectionChange)="languageSelected($event)">
              <mat-option value="nederlands">{{'common.code.language.nl' | translate}}</mat-option>
              <mat-option value="frans">{{'common.code.language.fr' | translate}}</mat-option>
              <mat-option value="engels">{{'common.code.language.en' | translate}}</mat-option>
              <mat-option value="duits">{{'common.code.language.de' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="myDataForm.get('language').errors && myDataForm.get('language').errors.required">
              <span>{{'error.required.language' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_6_12$ | async">
          <mat-form-field class="form-field">
            <input matInput type="text" placeholder="{{'mydata.nationalRegister' | translate}}" formControlName="nat_reg">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_4_12$ | async">
          <mat-checkbox formControlName="newsletter">{{'mydata.newsletter' | translate}}</mat-checkbox>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_4_12$ | async">
          <mat-checkbox formControlName="mail">{{'mydata.mail' | translate}}</mat-checkbox>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="cs_4_12$ | async">
          <mat-checkbox formControlName="sms" *ngIf="showSmsControl">{{'mydata.sms' | translate}}</mat-checkbox>
        </mat-grid-tile>

      </mat-grid-list>

      <div class="button-center">
        <button mat-raised-button color="primary" type="submit" [disabled]="!myDataForm.valid">{{'mydata.action-submit' | translate}}</button>
        <button mat-button color="accent" type="button" (click)="handleOpenAccounts()">{{'mydata.action-accounts' | translate}}</button>
      </div>
    </form>
  </div>
</div>
