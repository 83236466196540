import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {PracticeInfoResponse} from "@app/public/home/model/practiceinfo.response";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public getPracticeInfo(): Observable<PracticeInfoResponse> {
    return this.httpClient.get<PracticeInfoResponse>(`${environment.baseUrl}/info/`, this.security.getHttpOptions());
  }
}
