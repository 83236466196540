<div class="center">
  <div class="send-message">
    <h1>{{'sendMessage.title' | translate}}</h1>
    <form [formGroup]="sendMessageForm" (ngSubmit)="handleSubmit($event)">

      <mat-form-field class="form-field">
        <input matInput type="text" placeholder="{{'sendMessage.subject' | translate}}" formControlName="subject" required>
        <mat-error *ngIf="sendMessageForm.get('subject').errors && sendMessageForm.get('subject').errors.required">
          <span>{{'error.required.subject' | translate}}</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field body-form-field">
        <textarea matInput placeholder="{{'sendMessage.body' | translate}}" formControlName="body" rows="10" required></textarea>
        <mat-error *ngIf="sendMessageForm.get('body').errors && sendMessageForm.get('body').errors.required">
          <span>{{'error.required.body' | translate}}</span>
        </mat-error>
      </mat-form-field>

      <div class="button-center">
        <button mat-raised-button color="primary" type="submit" [disabled]="!sendMessageForm.valid">
          {{'sendMessage.action-submit' | translate}}</button>
      </div>
    </form>
  </div>
</div>
