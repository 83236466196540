import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyPetsService} from "@app/secured/my-pets/my-pets.service";
import {BreedService} from "@app/shared/services/breed.service";
import {PetData} from "@app/secured/my-pets/my-pets/my-pets.component";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-delete-pet',
  templateUrl: './delete-pet.component.html',
  styleUrls: ['./delete-pet.component.scss']
})
export class DeletePetComponent implements OnInit {

  public petForm: UntypedFormGroup;

  public isPetDead: boolean;

  constructor(private dialogRef: MatDialogRef<DeletePetComponent>,
              private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private service: MyPetsService,
              private breedService: BreedService,
              @Inject(MAT_DIALOG_DATA) private data: PetData) {
  }

  ngOnInit() {
    this.petForm = this.formBuilder.group({
      //date_of_death: this.formBuilder.control({disabled: true, value: ""}, [Validators.required])
    });
    this.isPetDead = false;
  }

  public handleSubmit(): void {
    if (this.petForm.valid) {
      let date: string = null;
      if(this.petForm.value.date_of_death) {
        date = formatDate(this.petForm.value.date_of_death, "dd/MM/yyyy", "nl-BE");
      }

      this.service.deletePet(this.data.pet.patient_id, date).subscribe(data => {
        this.dialogRef.close();
        this.dialog.showDialog("message.delete-pet-success")
      }, error => {
        this.dialogRef.close();
        this.dialog.showError("error.server");
      });
    }
  }

  public optionSelected($event: MatRadioChange): void {
    if ($event && $event.value === "death") {
      this.isPetDead = true;
      this.petForm.addControl("date_of_death", this.formBuilder.control("", [Validators.required]))
    } else {
      this.isPetDead = false;
      this.petForm.removeControl("date_of_death");
    }
  }
}
