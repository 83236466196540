import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {DataResponse} from "@app/shared/model/data.response";
import {BreedResponse} from "@app/shared/model/breed.response";
import {ListOfStringResponse} from "@app/shared/model/listofstring.response";

@Injectable({
  providedIn: 'root'
})
export class BreedService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findBreeds(param: string, type:string): Observable<Array<BreedResponse>> {
    return this.httpClient.get<DataResponse>(`${environment.baseUrl}/breed/selection?param=${param}&type=${type}`, this.security.getHttpOptions())
      .pipe(
        map((response: DataResponse) => {
          return response.data;
        }));
  }

  public findDefaultBreeds(): Observable<Array<BreedResponse>> {
    return this.httpClient.get<DataResponse>(`${environment.baseUrl}/breed/default`, this.security.getHttpOptions())
      .pipe(
        map((response: DataResponse) => {
          return response.data;
        }));
  }

  public findHairColoursByBreed(breedId: number): Observable<Array<string>> {
    return this.httpClient.get<ListOfStringResponse>(`${environment.baseUrl}/colour/all?breed_id=${breedId}`, this.security.getHttpOptions())
      .pipe(
        map((response: ListOfStringResponse) => {
          return response.list;
        }));
  }

}
