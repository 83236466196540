import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CoreModule} from "@app/core/core.module";
import {PublicModule} from "@app/public/public.module";
import {SecuredModule} from "@app/secured/secured.module";
import {NgHttpLoaderModule} from "ng-http-loader";
import {WidgetModule} from "@app/widget/widget.module";
import {MultiTranslateHttpLoader} from "@app/core/translate/multi-translate-loader";
import {HttpClient} from "@angular/common/http";
import localeNl from '@angular/common/locales/nl-BE';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {GoogleMapsModule} from "@angular/google-maps";

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: "./assets/i18n/auth/", suffix: ".json"},
    {prefix: "./assets/i18n/calendar/", suffix: ".json"},
    {prefix: "./assets/i18n/common/", suffix: ".json"},
    {prefix: "./assets/i18n/error/", suffix: ".json"},
    {prefix: "./assets/i18n/home/", suffix: ".json"},
    {prefix: "./assets/i18n/message/", suffix: ".json"},
    {prefix: "./assets/i18n/my-appointments/", suffix: ".json"},
    {prefix: "./assets/i18n/my-data/", suffix: ".json"},
    {prefix: "./assets/i18n/my-orders/", suffix: ".json"},
    {prefix: "./assets/i18n/my-pets/", suffix: ".json"},
    {prefix: "./assets/i18n/navigation/", suffix: ".json"},
    {prefix: "./assets/i18n/register/", suffix: ".json"},
    {prefix: "./assets/i18n/send-message/", suffix: ".json"}
  ]);
}

registerLocaleData(localeNl, 'nl-BE');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    PublicModule,
    SecuredModule,
    WidgetModule,
    AppRoutingModule,
    GoogleMapsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgHttpLoaderModule.forRoot()
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyB5BoTJkKg6DMkHPuRW8W4a-rrpi892eAk'
    // })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "nl-BE"},
    {provide: MAT_DATE_LOCALE, useValue: 'nl-BE'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
