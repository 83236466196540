import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyAppointmentsService} from "@app/secured/my-appointments/my-appointments.service";
import {NavigationExtras, Router} from "@angular/router";
import {AppointmentResponse} from "@app/secured/my-appointments/model/appointment.response";
import {AppointmentEdit} from "@app/public/appointment/model/appointmentedit";

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss']
})
export class MyAppointmentsComponent implements OnInit {

  public appointments: Array<AppointmentResponse>;

  private appointmentIdToDelete: number;

  constructor(private storage: StorageService,
              private dialog: DialogService,
              private service: MyAppointmentsService,
              private router: Router) {
  }

  ngOnInit() {
    this.load();
  }

  public handleEditAppointment(appointment: AppointmentResponse): void {
    if (this.storage.vetInfo.use_alternate_agenda) {
      let edit: AppointmentEdit = new AppointmentEdit();
      edit.id = appointment.id;
      edit.patientName = appointment.patientName;
      edit.date = appointment.date;
      edit.hour = appointment.hour;
      edit.endHour = appointment.endHour;
      edit.location = appointment.location;
      edit.appointmentTypeGroup = appointment.appointmentTypeGroup;
      edit.appointmentType = appointment.appointmentType;
      edit.vetName = appointment.vetName;
      edit.classification = appointment.classification;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "edit": edit
        }
      };
      this.router.navigate(["wizard"], navigationExtras);
    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "edit_id": appointment.id,
          "edit_date": appointment.date
        }
      };
      this.router.navigate(["calendar"], navigationExtras);
    }
  }

  public handleDeleteAppointment(appointment: AppointmentResponse): void {
    //check appointment may delete : min 24h before appointment
    let split:string[] = appointment.hour.split(":");
    let hour: number = parseInt(split[0]);
    let minutes: number = parseInt(split[1]);
    let appointmentDate: Date = new Date(appointment.date);
    appointmentDate.setHours(hour, minutes, 0, 0);
    appointmentDate.setDate(appointmentDate.getDate() - 1);

    let now: Date = new Date();

    if(now.getTime() > appointmentDate.getTime()) {
      this.dialog.showError("error.delete-appointment");
    } else {
      this.appointmentIdToDelete = appointment.id;
      this.dialog.showDialogWithAction("message.delete-appointment", "myAppointments.action-delete").afterClosed().subscribe(data => {
        if (data) {
          this.handleDelete();
        }
      });
    }
  }

  public handleNewAppointment(): void {
    if (this.storage.vetInfo.use_alternate_agenda) {
      this.router.navigate(["wizard"]);
    } else {
      this.router.navigate(["calendar"]);
    }
  }

  private handleDelete(): void {
    this.service.deleteAppointment(this.appointmentIdToDelete).subscribe(data => {
      this.dialog.showDialog("message.delete-appointment-success");
      this.load();
    }, error => {
      this.dialog.showError("error.server");
    });
  }

  private load(): void {
    if (this.storage.temporary) {
      this.service.findFutureAppointmentsForTempCustomer(this.storage.customerId).subscribe(data => {
        this.appointments = data;
      }, error => {
        this.dialog.showError("error.server");
      });
    } else {
      this.service.findFutureAppointmentsForRegisteredCustomer(this.storage.customerId).subscribe(data => {
        this.appointments = data;
      }, error => {
        this.dialog.showError("error.server");
      });
    }
  }
}
