import {Injectable} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private _authKey: string;

  constructor() {
  }

  get authKey(): string {
    return this._authKey;
  }

  set authKey(value: string) {
    this._authKey = value;
  }

  public getHttpOptions(): {} {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authKey
      }),
      withCredentials: true
    };
  }

  public getHttpFormOptions(): {} {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.authKey
      }),
      withCredentials: true
    };
  }

  public getHttpDocumentUploadOptions(): {} {
    return {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.authKey
      }),
      withCredentials: true,
      reportProgress: true,
      observe: 'events'
    };
  }
}
