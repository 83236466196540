<div class="calendar">

  <div id="calendar-container">
    <!--<div class="fc fc-ltr fc-unthemed">
      <div class="fc-toolbar fc-header-toolbar">
        <div class="fc-left">
          <h2>30 december 2019 – 5 januari 2020</h2>
        </div>
        <div class="fc-center">
        </div>
        <div class="fc-right">
          <button mat-flat-button color="accent" class="language-btn" [matMenuTriggerFor]="langmenu">
            <span>{{selectedVetName}}</span>
            <mat-icon class="logon-btn-icon">arrow_drop_down</mat-icon>
          </button>
          <mat-menu class="language-menu-item" #langmenu="matMenu">
            <button mat-menu-item (click)="handleSelectVet('nl')">NL</button>
            <button mat-menu-item (click)="handleSelectVet('fr')">FR</button>
            <button mat-menu-item (click)="handleSelectVet('en')">EN</button>
          </mat-menu>

          <div class="fc-button-group">
            <button type="button" class="fc-prev-button fc-button fc-button-primary" (click)="previousWeek()">{{'calendar.previous-week' | translate}}</button>
            <button type="button" class="fc-next-button fc-button fc-button-primary" (click)="nextWeek()">{{'calendar.next-week' | translate}}</button>
          </div>
        </div>
      </div>
    </div>-->
    <div id="calendar"></div>
    <div id="overlay" class="l-calendar__overlay">
      <div class="l-calendar__overlay-text">{{'calendar.overlay' | translate}}</div>
    </div>
  </div>
</div>
