import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {SendMessageService} from "@app/secured/send-message/send-message.service";

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

  public sendMessageForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private service: SendMessageService) {
  }

  ngOnInit() {
    this.sendMessageForm = this.formBuilder.group({
      subject: this.formBuilder.control("", [Validators.required, Validators.minLength(3)]),
      body: this.formBuilder.control("", [Validators.required, Validators.minLength(3)])
    });
  }

  public handleSubmit(event): void {
    if (this.sendMessageForm.valid) {
      this.service.sendMessage(this.storage.customerId, this.sendMessageForm.value).subscribe(data => {
        this.dialog.showDialog("message.send-message-success");
        this.sendMessageForm.reset();
      }, error => {
        this.dialog.showError("error.server");
      });
      //FIXME - dit in de success krijgen
      event.currentTarget.reset();
    }
  }
}
