import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

export interface DialogData {
  key: string;
  action: string;
  text: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public message: string;
  public action: string;

  constructor(private dialogRef: MatDialogRef<DialogComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) private data: DialogData) {
  }

  ngOnInit() {
    if (this.data.key) {
      this.message = this.translate.instant(this.data.key);
    } else if(this.data.text) {
      this.message = this.data.text;
    }

    if (this.data.action) {
      this.action = this.translate.instant(this.data.action);
    }
  }

  public onClick(): void {
    this.dialogRef.close(true);
  }
}
