import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import {StorageService} from "@app/core/storage/storage.service";
import {DialogService} from "@app/core/dialog/dialog.service";
import {MyPetsService} from "@app/secured/my-pets/my-pets.service";
import {Observable, of} from "rxjs";
import {BreedResponse} from "@app/shared/model/breed.response";
import {BreedService} from "@app/shared/services/breed.service";
import {debounceTime, distinctUntilChanged, startWith, switchMap} from "rxjs/operators";
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {PetData} from "@app/secured/my-pets/my-pets/my-pets.component";
import {Pet} from "@app/secured/my-pets/model/pet.model";
import {ResponsiveComponent} from "@app/shared/component/ResponsiveComponent";
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'app-pet',
  templateUrl: './pet.component.html',
  styleUrls: ['./pet.component.scss']
})
export class PetComponent extends ResponsiveComponent implements OnInit {
  public title: string;
  public petForm: UntypedFormGroup;

  private breedInput: UntypedFormControl;

  public showBreedInput: boolean;
  public showOtherBreed: boolean;
  public breeds$: Observable<Array<BreedResponse>>;
  private defaultBreeds: Array<BreedResponse>;

  public hairColours: Array<string>;

  constructor(breakpointObserver: BreakpointObserver,
              private dialogRef: MatDialogRef<PetComponent>,
              private formBuilder: UntypedFormBuilder,
              private storage: StorageService,
              private dialog: DialogService,
              private translate: TranslateService,
              private service: MyPetsService,
              private breedService: BreedService,
              @Inject(MAT_DIALOG_DATA) private data: PetData) {
    super(breakpointObserver);
  }

  ngOnInit() {
    this.showBreedInput = false;
    this.showOtherBreed = false;

    this.loadDefaultBreeds();

    this.breedInput = this.formBuilder.control("", [Validators.minLength(2)]);

    this.petForm = this.formBuilder.group({
      name: this.formBuilder.control("", [Validators.required, Validators.minLength(2)]),
      breed: this.breedInput,
      breed_id: new UntypedFormControl(""),
      breed_type: this.formBuilder.control(""),
      date_of_birth: this.formBuilder.control(""),
      gender: this.formBuilder.control("", [Validators.required, Validators.minLength(1)]),
      hair: this.formBuilder.control("", [Validators.minLength(3)]),
      hair_colour: this.formBuilder.control("", [Validators.minLength(3)]),
      identification: this.formBuilder.control("", [Validators.minLength(3)]),
      passport: this.formBuilder.control("", [Validators.minLength(3)])
    });

    this.breeds$ = this.breedInput.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            if (value.length > 1) {
              return this.breedService.findBreeds(value, this.getBreedType());
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );

    if (this.data.pet != null) {
      this.title = this.translate.instant("pet.title-update");
      this.petForm.patchValue(this.data.pet);
      this.selectBreed(this.data.pet.breed_id, this.data.pet.breed, this.data.pet.species);
    } else {
      this.title = this.translate.instant("pet.title-new");
    }
  }

  public handleSubmit(): void {
    if (this.petForm.valid) {
      let pet: Pet = this.petForm.value;
      if(this.petForm.value.date_of_birth && this.petForm.value.date_of_birth !== "") {
        pet.date_of_birth = formatDate(this.petForm.value.date_of_birth, "dd/MM/yyyy", "nl-BE");
      }

      if (this.data.pet == null) {
        this.service.createPet(this.storage.customerId, pet).subscribe(data => {
          this.dialogRef.close();
          this.dialog.showDialog("message.create-pet-success")
        }, error => {
          this.dialogRef.close();
          this.dialog.showError("error.server");
        });
      } else {
        this.service.updatePet(this.data.pet.patient_id, pet).subscribe(data => {
          this.dialogRef.close();
          this.dialog.showDialog("message.update-pet-success")
        }, error => {
          this.dialogRef.close();
          this.dialog.showError("error.server");
        });
      }
    }
  }

  public breedOptionSelected($event: MatRadioChange): void {
    if ($event) {
      this.showBreedInput = true;
      if ($event.value === "Dog") {
        this.showOtherBreed = false;
        this.breedInput.patchValue("");
        this.petForm.patchValue({breed_id: this.defaultBreeds.find(element => element.name === "Cv").breed_id});
        this.breedInput.setValidators([Validators.minLength(2)]);
      } else if ($event.value === "Cat") {
        this.showOtherBreed = false;
        this.breedInput.patchValue("");
        this.petForm.patchValue({breed_id: this.defaultBreeds.find(element => element.name === "Fv").breed_id});
        this.breedInput.setValidators([Validators.minLength(2)]);
      } else {
        this.showOtherBreed = true;
        this.breedInput.patchValue("");
        this.petForm.patchValue({breed_id: null});
        this.breedInput.setValidators([Validators.required, Validators.minLength(2)]);
      }
      this.breedInput.updateValueAndValidity();
    }
  }

  public displayFnBreed(breed?: BreedResponse): string | undefined {
    if (breed) {
      return breed.name;
    } else {
      return undefined;
    }
  }

  public breedSelected(event: MatAutocompleteSelectedEvent): void {
    this.petForm.patchValue({breed_id: event.option.value.breed_id});

    this.breedService.findHairColoursByBreed(event.option.value.breed_id).subscribe(data => {
      this.hairColours = data;
    });
  }

  private selectBreed(id: number, breed: string, species: string): void {
    this.showBreedInput = true;

    if (species === "Ca") {
      this.showOtherBreed = false;
      this.petForm.patchValue({breed_type: "Dog"});
      if (breed !== "Cv") {
        this.petForm.patchValue({
          breed: {
            breed_id: id,
            name: breed
          }
        })
      }
    } else if (species === "Fe") {
      this.showOtherBreed = false;
      this.petForm.patchValue({breed_type: "Cat"});
      if (breed !== "Fv") {
        this.petForm.patchValue({
          breed: {
            breed_id: id,
            name: breed
          }
        })
      }
    } else {
      this.showOtherBreed = true;
      this.petForm.patchValue({breed_type: "Other"});
      this.petForm.patchValue({
        breed: {
          breed_id: id,
          name: breed
        }
      })
    }
  }

  private loadDefaultBreeds(): void {
    this.breedService.findDefaultBreeds().subscribe(data => {
      this.defaultBreeds = data;
    });
  }

  private getBreedType(): string {
    return this.petForm.value.breed_type;
  }
}
