import {NgModule} from '@angular/core';
import {LoginFormComponent} from './login-form/login-form.component';
import {SharedModule} from "@app/shared/shared.module";
import {WidgetModule} from "@app/widget/widget.module";

@NgModule({
  declarations: [
    LoginFormComponent
  ],
  imports: [
    SharedModule,
    WidgetModule
  ],
  exports: [
    LoginFormComponent
  ]
})
export class AuthModule {
}
