import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecurityService} from "@app/core/security/security.service";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {PetResponse} from "@app/shared/model/pet.response";

@Injectable({
  providedIn: 'root'
})
export class PetService {

  constructor(private httpClient: HttpClient,
              private security: SecurityService) {
  }

  public findPets(customerId: number): Observable<Array<PetResponse>> {
    return this.httpClient.get<PetResponseObject>(`${environment.baseUrl}/customer/${customerId}/patient/list`, this.security.getHttpOptions())
      .pipe(
        map((response: PetResponseObject) => {
          return response.patients;
        }));
  }
}

interface PetResponseObject {
  patients: Array<PetResponse>;
}
